import { useForm as useFormParent } from '@refinedev/antd';
import { App } from 'antd';

const useForm = ({
  successNotification,
  errorNotification,
  onMutationError,
  onMutationSuccess,
  ...props
}) => {
  const { message } = App.useApp();
  return { ...useFormParent({
    ...props,
    successNotification: false,
    errorNotification: false,
    onMutationError: (...args) => {
      console.log('Doing error', args);
      if (errorNotification) {
        message.open({
          type: 'error',
          content: errorNotification,
        });
      } else if (errorNotification !== false) {
        message.open({
          type: 'error',
          content: args[0].message,
        });
      }
      if (onMutationError) {
        onMutationError(...args);
      }
    },
    onMutationSuccess: (...args) => {
      console.log('Doing succcess', args);
      if (successNotification) {
        message.open({
          type: 'success',
          content: successNotification,
        });
      }
      if (onMutationSuccess) {
        onMutationSuccess(...args);
      }
    }
  }) }
};
export default useForm;

import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Spin } from "antd";
import styled from "styled-components";
import {
  Logo,
  Page,
  YumyLayout,
  YumyOverlay,
  YumySider,
  YumySiderToggle,
  colors,
} from "../../components/Layout/template";
import RestaurantController from "./RestaurantController";
import MenuDropdown from "./MenuDropdown";
// import MenuPoint from './MenuPoint';
import Suspense from "../../lib/refine/Suspense";
import YumyLogoWhite from "../images/yumyWhite.png";
import User from "../../components/Layout/user";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import RestaurantDropdown from "./RestaurantDropdown";
import KDSLink from "./KDSLink";
import QrLink from "./QrLink";

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.yumyBlack};
  color: ${colors.yumyGreen600};
  font-weight: 800;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
`;
const Restaurant = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // Function to toggle menuOpen state
  const toggleMenu = () => {
    console.log("Is it toggling?");
    setMenuOpen(!menuOpen);
  };
  return (
    <Suspense
      fallback={
        <LoaderContainer>
          <Spin size="large" tip="Loading Yumy" />
          Loading your Yumy experience...
        </LoaderContainer>
      }
    >
      <RestaurantController>
        {() => (
          <Page>
            <YumyLayout>
              <YumySider menuOpen={menuOpen}>
                <div>
                  <Logo>
                    <img src={YumyLogoWhite} alt="Yumy Logo" />
                  </Logo>
                  <RestaurantDropdown />
                  <MenuDropdown />
                  <QrLink />
                  <KDSLink />
                </div>
                <User />
              </YumySider>
              <div className="text-yumyBlack bg-white-100 min-h-32 overflow-x-hidden px-4 pb-4 sm:px-8 sm:pb-8 xl:px-16 pt-4 xl:pb-16">
                <Outlet />
              </div>
              <YumySiderToggle onClick={toggleMenu} menuOpen={menuOpen}>
                {menuOpen ? (
                  <MenuFoldOutlined style={{ fontSize: "16px" }} />
                ) : (
                  <MenuUnfoldOutlined style={{ fontSize: "16px" }} />
                )}
              </YumySiderToggle>
              <YumyOverlay onClick={toggleMenu} menuOpen={menuOpen} />
            </YumyLayout>
          </Page>
        )}
      </RestaurantController>
    </Suspense>
  );
};

export default Restaurant;

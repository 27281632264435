import { forwardRef, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import OrderStateController from "./OrderStateController";
import ProductDoneToggleController from "./ProductDoneToggleController";
import {
  YumyIconWalk,
  YumyIconMenu,
  YumyIconRightArrowWhite,
  YumyIconQRHeader,
  YumyIconCommentGreen,
  YumyIconComment,
} from "../../components/YumyIcons";
import { Button } from "antd";

const formatTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const options = {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/Copenhagen",
    hour12: false,
  };
  const formatter = new Intl.DateTimeFormat("en-GB", options);
  return formatter.format(date);
};

const TimeDisplay = ({ dateTime }) => {
  const formattedTime = formatTime(dateTime);

  return <div>{formattedTime}</div>;
};

const KDSOrderButton = ({
  children,
  accept,
  ready,
  complete,
  completed,
  ...props
}) => (
  <Button
    className={`text-sm font-medium leading-6 h-9 cursor-pointer border-0 transition-all duration-300 ease-in-out hover:opacity-70 ${
      accept
        ? "bg-yumyBlack text-white-100 hover:!bg-yumyBlack hover:!text-white-100"
        : ready
        ? "bg-yumyGreen text-white-100 hover:!bg-yumyGreen hover:!text-white-100"
        : complete
        ? "bg-grayScale900-4 text-yumyBlack hover:!bg-grayScale900-4 hover:!text-yumyBlack"
        : completed
        ? "bg-transparent text-grayScale900-32 hover:!bg-transparent hover:!text-grayScale900-32 hover:opacity-100 cursor-default"
        : ""
    }`}
    {...props}
  >
    {children}
  </Button>
);

const KDSOrderTextBig = ({ children }) => (
  <p className="flex items-center gap-1 text-sm font-semibold leading-180">
    {children}
  </p>
);

const PrintableKDSItem = forwardRef((props, ref) => {
  const { itemData, printOrders } = props;

  const {
    id,
    uid,
    order_id,
    created_at,
    bag_fee,
    total,
    name,
    qr_code_name: qrCodeName,
    session_products,
    state,
    where_choice,
  } = itemData;

  const isCooking = state === "ACCEPTED";
  const isNew = state === "ORDERED";
  const isReady = state === "READY";
  const isCompleted = state === "COMPLETED";

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <div className="printable-kds-item" ref={printRef}>
      <div
        key={id}
        className={`kds-item border-2 rounded-lg bg-white-100 ${
          isCooking
            ? "border-yumyGreen"
            : isReady
            ? "border-grayScale900-4"
            : isCompleted
            ? "border-grayScale900-4 text-grayScale900-56"
            : "border-yumyBlack"
        }`}
        isCooking={isCooking}
        isReady={isReady}
        isNew={isNew}
        isCompleted={isCompleted}
      >
        <div className="kds-item-header flex flex-col py-2 px-3 bg-grayScale900-2 gap-3">
          <div className="flex justify-between items-center gap-2">
            <div className="flex flex-col">
              <h3 className="text-xl font-extrabold leading-140">
                #{order_id} {name}
              </h3>
              {where_choice === "TAKEAWAY" ? (
                <div className="flex items-center gap-1 text-xs font-semibold leading-180 text-grayScale900-56">
                  <span>Takeaway</span>
                  <YumyIconWalk />
                </div>
              ) : (
                <div className="flex items-center gap-1 text-xs font-semibold leading-180 text-grayScale900-56">
                  <span>Eat-in</span>
                  <YumyIconMenu />
                </div>
              )}
            </div>
            <OrderStateController id={uid} state={state}>
              {({ onClick, isLoading }) => {
                let stateButton = null;
                switch (state) {
                  case "ORDERED":
                    stateButton = (
                      <KDSOrderButton accept onClick={onClick}>
                        Accept
                      </KDSOrderButton>
                    );
                    break;
                  case "ACCEPTED":
                    stateButton = (
                      <KDSOrderButton ready onClick={onClick}>
                        Ready <YumyIconRightArrowWhite />
                      </KDSOrderButton>
                    );
                    break;
                  case "READY":
                    stateButton = (
                      <KDSOrderButton complete onClick={onClick}>
                        Complete
                      </KDSOrderButton>
                    );
                    break;
                  case "COMPLETED":
                    stateButton = (
                      <KDSOrderButton completed>Completed</KDSOrderButton>
                    );
                    break;
                  default:
                }
                return stateButton;
              }}
            </OrderStateController>
          </div>
          <hr className="w-full border-0 border-t border-grayScale900-8 mb-0" />
          <div className="flex justify-between items-center gap-2 pb-2">
            <KDSOrderTextBig>
              <YumyIconQRHeader className="relative top-[1px]" />
              {qrCodeName}
            </KDSOrderTextBig>
            <KDSOrderTextBig>
              <TimeDisplay dateTime={created_at} />
            </KDSOrderTextBig>
          </div>
        </div>
        <div>
          {session_products.map(
            ({ id, uid, product, sections, quantity, note, price, done }) => {
              const optionsArray = sections.reduce((acc, { name, options }) => {
                const optionsArray = options.map(({ name }) => name);
                return [
                  ...acc,
                  <>
                    <span className="text-grayScale900-56">{name}: </span>
                    <span className="text-yumyBlack">
                      {optionsArray.join(", ")}
                    </span>
                  </>,
                ];
              }, []);
              return (
                <ProductDoneToggleController id={uid} done={done}>
                  {({ onToggle, isToggling }) => (
                    <div
                      done={done}
                      onClick={onToggle}
                      isCompleted={isCompleted}
                      className={`flex flex-col py-4 px-3 cursor-pointer transition-colors duration-200 ease-out border-b border-b-grayScale900-8 hover:border-b-yumyGreens-200 text-sm font-semibold leading-180 ${
                        done && isCompleted
                          ? "bg-grayScale900-2"
                          : done
                          ? "bg-yumyGreens100"
                          : ""
                      }`}
                    >
                      <div
                        className={`${
                          done && isCompleted
                            ? "line-through text-grayScale900-56 opacity-50"
                            : done
                            ? "line-through text-yumyGreen opacity-50"
                            : ""
                        }`}
                      >
                        <div className="flex justify-between gap-1">
                          <div className="w-full">
                            {quantity} x {product.name}
                          </div>
                          <div className="shrink-0">{price} kr</div>
                        </div>
                        {note && (
                          <div className="flex items-center gap-1">
                            {done ? (
                              <YumyIconCommentGreen />
                            ) : (
                              <YumyIconComment />
                            )}
                            &quot;{note}&quot;
                          </div>
                        )}
                        <div className="flex flex-col pl-6">
                          {optionsArray.map((option, index) => (
                            <div key={index}>{option}</div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </ProductDoneToggleController>
              );
            }
          )}
          <div className="kds-item-footer flex flex-col py-2 px-3 bg-grayScale900-2 gap-3">
            {bag_fee > 0 && (
              <div className="flex justify-between items-center gap-2">
                <div className="text-sm font-semibold text-grayScale900-56">
                  Pose
                </div>
                <div className="text-sm font-semibold text-grayScale900-56">
                  {bag_fee} kr
                </div>
              </div>
            )}
            <div className="flex justify-between items-center gap-2 py-2">
              <KDSOrderTextBig>Total</KDSOrderTextBig>
              <KDSOrderTextBig>{total} kr</KDSOrderTextBig>
            </div>
            {printOrders && (
              <Button onClick={handlePrint}>Print</Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default PrintableKDSItem;

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import PageController from "./PageController";
import FilterOrderStateController from "./FilterOrderStateController";
import { isMobile } from "react-device-detect";
import YumyLogoBlack from "../../layouts/images/yumyBlack.png";
import {
  YumyIconFullScreen,
  YumyIconFullScreenExit,
  YumyIconSettings,
} from "../../components/YumyIcons";
import Modal from "../../lib/Modal";
import KDSSettings from "./settings";
import PrintableKDSItem from "./PrintableKDSItem";

const KDSButton = ({ children, extraClass, inactive, ...props }) => (
  <div
    className={`flex py-2 px-4 justify-center items-center gap-2 rounded-full select-none cursor-pointer ${extraClass} ${
      inactive ? "opacity-50" : ""
    }`}
    {...props}
  >
    {children}
  </div>
);

const KDSButtonAmount = ({ children }) => (
  <span className="text-lg font-bold leading-none relative -top-[1px]">
    {children}
  </span>
);

const KDSButtonText = ({ children }) => (
  <span className="text-xs font-semibold leading-none">{children}</span>
);

const KDS = () => {
  return (
    <PageController>
      {({
        props,
        fullyLoaded,
        fullScreenActive,
        onToggleFullScreen,
        restaurant,
      }) => {
        if (!fullyLoaded)
          return (
            <div>
              <h1>KDS</h1>
              <p>Loading...</p>
            </div>
          );
        return (
          <div className="the-kds">
            <Modal {...props.settingsModal} width={940}>
              <KDSSettings {...props.settings} />
            </Modal>
            <FilterOrderStateController {...props.filterOrderState}>
              {({
                sessionPurchases,
                filterButtonChoices: { cooking, neww, completed, ready },
              }) => (
                <div>
                  <div
                    className={`flex flex-col-reverse sm:flex-row justify-between sm:items-center mb-4 pb-4 relative gap-4 ${
                      fullScreenActive && isMobile
                        ? "pt-7 pb-4 pl-[72px]"
                        : "lg:py-4"
                    }`}
                  >
                    <div className="flex items-center gap-1 xl:gap-2">
                      <KDSButton
                        extraClass="bg-yumyGreen600 text-white-100"
                        inactive={!cooking.selected}
                        onClick={cooking.onClick}
                      >
                        <KDSButtonAmount>{cooking.amount}</KDSButtonAmount>
                        <KDSButtonText>Cooking</KDSButtonText>
                      </KDSButton>
                      {!restaurant.auto_accept && (
                        <KDSButton
                          extraClass="bg-yumyBlack text-white-100"
                          inactive={!neww.selected}
                          onClick={neww.onClick}
                        >
                          <KDSButtonAmount>{neww.amount}</KDSButtonAmount>
                          <KDSButtonText>New</KDSButtonText>
                        </KDSButton>
                      )}
                      <KDSButton
                        extraClass="bg-grayScale900-8 text-yumyBlack"
                        inactive={!completed.selected}
                        onClick={completed.onClick}
                      >
                        <KDSButtonAmount>{completed.amount}</KDSButtonAmount>
                        <KDSButtonText>Completed</KDSButtonText>
                      </KDSButton>
                    </div>
                    <img
                      className="absolute left-1/2 -translate-x-1/2 hidden lg:block"
                      src={YumyLogoBlack}
                      alt="Yumy Logo"
                    />
                    <div className="flex gap-2">
                      <div
                        className="flex justify-center items-center border-0 w-16 h-9 cursor-pointer bg-grayScale900-8 hover:fill-yumyGreen transition-colors duration-300 ease-in-out rounded-full"
                        onClick={onToggleFullScreen}
                      >
                        {fullScreenActive ? (
                          <YumyIconFullScreenExit />
                        ) : (
                          <YumyIconFullScreen />
                        )}
                      </div>
                      <div
                        className="flex justify-center items-center border-0 w-16 h-9 cursor-pointer bg-grayScale900-8 hover:fill-yumyGreen transition-colors duration-300 ease-in-out rounded-full"
                        {...props.settingsButton}
                      >
                        <YumyIconSettings />
                      </div>
                    </div>
                  </div>
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{
                      350: 1,
                      750: 2,
                      900: 3,
                      1180: 4,
                    }}
                  >
                    <Masonry gutter="16px">
                      {sessionPurchases.map((purchase) => (
                        <PrintableKDSItem
                          key={purchase.id}
                          itemData={purchase}
                          printOrders={restaurant.print_orders}
                        />
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>
                </div>
              )}
            </FilterOrderStateController>
          </div>
        );
      }}
    </PageController>
  );
};

export default KDS;

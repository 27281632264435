import { useMemo } from 'react';
import i18next from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import { I18nProvider } from "@refinedev/core";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'da'],
    fallbackLng: 'da',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    defaultNS: 'common',
  });


const useI18nProvider = () : I18nProvider => {
  const { t, i18n } = useTranslation();
  const i18nProvider = useMemo(() => ({
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  }), [i18n, t]);
  return i18nProvider;
};

export default useI18nProvider;

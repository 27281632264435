import { useContext } from 'react';
import { Form, Dropdown } from 'antd';
import { Input } from '../../../../components/Inputs';
import CategoryNameInput from './CategoryNameInput';
import { useHandleClickOutside } from '../../../../lib/hooks';
import CategoriesContext from './CategoriesContext';
import SubCategoriesList from './SubCategoriesList';
import DragHandler from '../../../../components/Functionality/DragHandler';
import { ModalAdditionSectionBottom, ModalAdditionSectionMore, ModalAdditionalSection } from '../../../../components/Layout/modal';

const Category = ({
    specialId,
    name,
    onRemove,
    form,
    uncategorizedId,
    showAllSubCategories,
    onStopEditing,
    onStartEditing,
    editing,
    editingAny,
    innerRef,
    draggableProps,
    isDragging,
    dragHandleProps,
}) => {
    const categoryId = form.getFieldValue(['categories', name, 'id']);
    const categoryName = Form.useWatch(['categories', name, 'name']);
    const isUncategorized = categoryId == uncategorizedId;
    const wrapperRef = useHandleClickOutside(onStopEditing, editing);
    const { orphanProducts, productsInCategory, orphanedProducts } = useContext(CategoriesContext);
    const products = (productsInCategory[categoryId] || 0) + (isUncategorized ? orphanedProducts : 0);
    const dropdownItems = [{
      label: categoryName,
      key: '1',
      type: 'group',
      children: [
        {
          key: 'DELETE',
          label: 'Delete and keep products',
          onClick: () => { onRemove(); if (products) { orphanProducts(products); } },
          danger: true,
        }
      ],
    }];
    return (
      <div ref={n => { wrapperRef.current = n; innerRef(n); }} vertical {...draggableProps}>
        <Input type="hidden" name={[name, 'id']} />
        <ModalAdditionalSection>
          <ModalAdditionSectionBottom>
            <DragHandler disabled={editingAny || showAllSubCategories} {...dragHandleProps} />
          </ModalAdditionSectionBottom>
          <CategoryNameInput
            editing={editing}
            onEdit={onStartEditing}
            onStopEdit={onStopEditing}
            name={[name, 'name']}
            disabled={isUncategorized}
            products={products}
          />
          <Dropdown menu={{ items: dropdownItems }} disabled={isUncategorized} trigger={['click']}>
            {!isUncategorized ? (
              <ModalAdditionSectionBottom>
                <ModalAdditionSectionMore onClick={(e) => e.preventDefault()}>
                  More
                </ModalAdditionSectionMore>
              </ModalAdditionSectionBottom>
            ) : <ModalAdditionSectionMore />}
          </Dropdown>
        </ModalAdditionalSection>
        <Form.List name={[name, 'sub_categories']}>
          {(fields, { add, remove, move }) => !isUncategorized && (
            <SubCategoriesList
              fields={fields}
              add={add}
              remove={remove}
              editingCategory={editing}
              showAllSubCategories={showAllSubCategories}
              specialId={specialId}
              categoryId={categoryId}
              categoryFormName={name}
              move={move}
            />
          )}
        </Form.List>
      </div>
    );
};

export default Category;

import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import CheckContainer from "./CheckContainer";
import {
  Logo,
  Page,
  YumyLayout,
  YumyOverlay,
  YumySider,
  YumySiderOnboarding,
  YumySiderToggle,
} from "../../components/Layout/template";
import User from "../../components/Layout/user";
import YumyLogoWhite from "../images/yumyWhite.png";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

const Onboarding = ({ step }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  // Function to toggle menuOpen state
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Page>
      <YumyLayout>
        <YumySider menuOpen={menuOpen}>
          <div>
            <Logo>
              <img src={YumyLogoWhite} alt="Yumy Logo" />
            </Logo>
            <YumySiderOnboarding>
              <CheckContainer checked>Account</CheckContainer>
              <CheckContainer
                checked={step === "MENU" || step === "RESTAURANT"}
                active={step === "PLAN"}
              >
                Plan
              </CheckContainer>
              <CheckContainer
                checked={step === "MENU"}
                active={step === "RESTAURANT"}
              >
                Restaurant
              </CheckContainer>
              <CheckContainer active={step === "MENU"}>Menu</CheckContainer>
            </YumySiderOnboarding>
          </div>
          <User hideAccountAndPlan />
        </YumySider>
        <div className="text-yumyBlack bg-white-100 min-h-32 overflow-x-hidden px-4 pb-4 sm:px-8 sm:pb-8 xl:px-16 pt-4 xl:pb-16">
          <Outlet />
        </div>
        <YumySiderToggle onClick={toggleMenu} menuOpen={menuOpen}>
          {menuOpen ? (
            <MenuFoldOutlined style={{ fontSize: "16px" }} />
          ) : (
            <MenuUnfoldOutlined style={{ fontSize: "16px" }} />
          )}
        </YumySiderToggle>
        <YumyOverlay onClick={toggleMenu} menuOpen={menuOpen} />
      </YumyLayout>
    </Page>
  );
};

export default Onboarding;

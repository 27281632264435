import dayjs from "dayjs";
import { useLayout } from '../../../../layouts/Restaurant/LayoutContext';

const QRCodeRowController = ({
  onToggleQRCode,
  selectedQRCodes,
  qrCodes,
  children,
}) => {
  const { menus } = useLayout();
  return qrCodes.map(
    (qrCode) =>
      children({
        key: qrCode.id,
        qrCode: {
          ...qrCode,
          created: dayjs(qrCode.created_at).format('DD/MM/YY'),
          menu: menus.find((menu) => menu.id === qrCode.menu_id)?.name,
        },
        props: {
          checkbox: {
            checked: !!selectedQRCodes[qrCode.id],
            onChange: () => onToggleQRCode(qrCode.id),
          },
          dropdown: {
            qrCode: qrCode,
          },
        },
      })
  );
};

export default QRCodeRowController;

// Collapsible component 
import styles from './Collapser.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface Animation {
  duration?: number;
  ease?: string;
}

interface CollapsibleProps {
  children: React.ReactNode;
  open: boolean;
  animation?: Animation;
}

const defaultAnimationValues = {
  duration: 0.2,
  ease: "ease-in-out"
};

// animation={animation}
const Collapser: React.FC<CollapsibleProps> = ({
  children,
  open,
  animation = defaultAnimationValues
}) => {
  return (
    <div className={cx('wrapper', { open })} style={{ transition: `all ${animation.duration}s ${animation.ease}` }}>
      <div className={cx('inner')}>{children}</div>
    </div>
  );
};

export default Collapser;

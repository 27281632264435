import { useEffect, useRef } from 'react';

const useHandleClickOutside = (f, active = true) => {
    const wrapperRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
          if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            f();
          }
        }
        if (active) {
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }
    }, [wrapperRef, f, active]);
    return wrapperRef;
};

export default useHandleClickOutside;


import styled from "styled-components";
import { colors } from "./template";

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  border-left: 8px solid ${colors.yumyGreen};
`;

export const FormContent = styled.div`
  /* ------------------------------------------------------
    Form Content
  ------------------------------------------------------ */

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  transition: margin-top 300ms ease-in-out;

  ${({ onboarding, modal }) => `
    margin-top: ${onboarding ? "15px" : modal ? "0" : "15px"};
    ${!modal && `max-width: 455px;`}
    ${!modal && `gap: 24px;`}
  `}

  @media (max-width: 767px) {
    ${({ onboarding, modal }) => `
    margin-top: ${onboarding ? "15px" : modal ? "0" : "15px"};
    `}
  }

  /* ------------------------------------------------------
    Remove styling from antd components
  ------------------------------------------------------ */

  input,
  span,
  input.ant-input-status-error,
  .ant-input-number-status-error,
  .ant-select-status-error .ant-select-selector,
  textarea.ant-input-status-error,
  input#password,
  .ant-picker,
  .ant-picker-input {
    border: 0 !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000000s ease-in-out 0s;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .ant-input-affix-wrapper input,
  .ant-select .ant-select-selector input,
  .ant-select .ant-select-selector input:hover,
  .ant-select .ant-select-selector input:focus,
  .ant-select .ant-select-selector input:active,
  .ant-select .ant-select-selector input:focus-within,
  input#password,
  .ant-picker input,
  .ant-picker-input {
    height: auto;
    box-shadow: none !important;
  }

  /* ------------------------------------------------------
    Text and color
  ------------------------------------------------------ */

  input {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
    color: ${colors.yumyBlack};
    transition: color 300ms ease-in-out;
  }

  input {
    font-weight: 400;
  }

  .ant-select-item-option-selected {
    color: ${colors.yumyGreen} !important;
  }

  /* ------------------------------------------------------
    Flex, height, padding, margin
  ------------------------------------------------------ */

  & form {
    flex-direction: column;
    gap: 32px;

    @media (max-width: 767px) {
      gap: 16px;
    }
  }

  & form,
  & input,
  & textarea,
  .ant-select,
  .ant-select .ant-select-selector,
  .ant-select-selector input,
  .ant-picker {
    display: flex;
  }

  .ant-select-selector input:focus-within,
  .ant-select-selector input:focus,
  .ant-select-selector input:hover,
  .ant-select-selector input:active,
  .ant-select-single,
  .ant-picker {
    height: 100% !important;
  }

  & input,
  & textarea,
  .ant-select,
  .ant-select .ant-select-selector,
  .ant-select-selector input {
    align-items: center;
  }

  & input,
  & textarea,
  .ant-select .ant-select-selector,
  .ant-picker {
    padding: 6px 16px 5px 16px;
    border-radius: 8px;
  }
  .ant-select-selector input {
    padding: 6px 16px 5px 5px !important;
  }

  & input,
  & .ant-input-affix-wrapper,
  .ant-select .ant-select-selector,
  .ant-picker {
    ${({ modal }) => `
      height: ${modal ? "40px" : "52px"};
    `}
  }

  .ant-input-number {
    width: 100%;
  }

  /* ------------------------------------------------------
    Box Shadow
  ------------------------------------------------------ */

  & input,
  & .ant-input-affix-wrapper,
  & .ant-select .ant-select-selector,
  & textarea,
  .ant-picker {
    transition: box-shadow 300ms ease-in-out;
    box-shadow: 0 0 0 1px ${colors.grayScale900_8};
    /* WebKit */
    -webkit-appearance: none !important;

    /* Mozilla */
    -moz-appearance: none;

    /* Opera */
    -o-appearance: none;

    /* Internet Explorer */
    -ms-appearance: none;

    /* CSS3 */
    appearance: none;
  }

  & input:hover,
  & input:focus,
  & input:active,
  & input:focus-within,
  & .ant-input-affix-wrapper:hover,
  & .ant-input-affix-wrapper:focus-within,
  & .ant-select .ant-select-selector:hover,
  & .ant-select .ant-select-selector:focus,
  & .ant-select .ant-select-selector:active,
  & .ant-select .ant-select-selector:focus-within,
  & textarea:hover,
  & textarea:focus,
  & textarea:active,
  & textarea:focus-within,
  .ant-picker:hover,
  .ant-picker:focus,
  .ant-picker:active,
  .ant-picker:focus-within {
    /* WebKit */
    -webkit-appearance: none !important;

    /* Mozilla */
    -moz-appearance: none;

    /* Opera */
    -o-appearance: none;

    /* Internet Explorer */
    -ms-appearance: none;

    /* CSS3 */
    appearance: none;
    box-shadow: 0 0 0 2px ${colors.yumyGreen} !important;
  }

  .ant-picker-suffix {
    color: ${colors.yumyGreen} !important;
  }

  .ant-picker input {
    box-shadow: none !important;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;
export const InputWrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 180%;
  font-weight: 600;
  color: ${colors.grayScale900_56};
  flex-shrink: 0;

  & > a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${({ noLabelHeight }) => `height: ${noLabelHeight ? "auto" : "32px"};`}
`;

export const ShowHide = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.yumyGreen};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const SwitchText = styled.span`
  display: flex;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  color: ${colors.yumyBlack};

  & > a {
    color: ${colors.yumyGreen};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

import { useMemo } from 'react';
import {
  AuthBindings,
  DataBindings,
} from "@refinedev/core";
import { I18nProvider } from "@refinedev/core";
import storage from '../lib/storage';
import {
  axiosInstance,
} from "../rest-data-provider/utils";
import env from '../env';

type LoginProps = {
    email: string;
    password: string;
};

type User = {
  roles: string[];
  email: string;
  name: string;
  id: string;
};

type Token = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
};

type UserOrNull = User | null;
type TokenOrNull = Token | null;

type GetUserType = () => UserOrNull;
type SetUserType = (user: User) => void;

type GetTokenType = () => TokenOrNull;
type SetTokenType = (token: Token) => void;

const getUser: GetUserType = () => {
  const user = storage.getItem('user');
  return user ? JSON.parse(user) as User : null;
};

export const setUser: SetUserType = (user: User) => {
  storage.setItem('user', JSON.stringify(user));
}

export const getToken: GetTokenType = () => {
  const token = storage.getItem('token');
  return token ? JSON.parse(token) as Token : null;
}

const setToken: SetTokenType = (token: Token) => {
  storage.setItem('token', JSON.stringify(token));
};
const removeToken = () => {
  storage.removeItem('token');
};

const removeUser = () => {
  storage.removeItem('user');
};

const useAuthProvider = (
    i18nProvider: I18nProvider,
    dataProvider: DataBindings,
): AuthBindings => {
    return useMemo<AuthBindings>(() => ({
        login: async ({ email: username, password } : LoginProps) => {
            try {
                const response = await dataProvider.custom({
                    url: `${env.apiUrl}/oauth/token`,
                    method: 'post',
                    payload: {
                        username,
                        password,
                        grant_type: 'password',
                        client_id: env.clientId,
                        client_secret: env.clientSecret,
                        scope: '',
                    },
                });
                try {
                  setToken(response.data);
                  const userIdentity = await dataProvider.custom({
                      url: `${env.apiUrl}/api/user`,
                      method: 'get',
                  });
                  setUser(userIdentity.data.data);
                  return {
                    success: true,
                    redirectTo: "/",
                  };
                } catch (e) {
                  console.error(e);
                  removeToken();
                  return {
                    success: false,
                    error: {
                      message: i18nProvider.translate('login.login_valid_but_user_identity_failed_name'),
                      name: i18nProvider.translate('login.login_valid_but_user_identity_failed_message'),
                    },
                  };
                }
            } catch (e) {
              return {
                success: false,
                error: {
                  message: i18nProvider.translate('login.login_invalid_name'),
                  name: i18nProvider.translate('login.login_invalid_message'),
                },
              };
            }
          },
          logout: async () => {
            removeToken();
            removeUser();
            return {
              success: true,
              redirectTo: "/login",
            };
          },
          check: async () => {
            const token = getToken();
            const user = getUser();
            if (token && user) {
              return {
                authenticated: true,
              };
            }
            return {
              authenticated: false,
              logout: true,
              redirectTo: "/login",
            };
          },
          getPermissions: async () => {
            const user = getUser();
            if (user) {
              return user.roles;
            }
            return null;
          },
          getIdentity: async () => {
            const user = getUser();
            if (user) {
              const response = await axiosInstance.get(`${env.apiUrl}/api/user`);
              return response.data.data;
            }
            return null;
          },
          onError: async (error: any) => {
            console.error(error);
            return { error };
          },
    }), [i18nProvider, dataProvider]);
  };

export default useAuthProvider;


import { useState, useMemo, useEffect } from 'react';
import { useGo, useInvalidate } from '@refinedev/core';
import { useDelete } from '../../../../lib/refine';
import { useParams } from 'react-router-dom';
import deepEqual from 'deep-equal';
import { useLayout } from '../../../../layouts/Restaurant/LayoutContext';
import hash from 'object-hash';

const createInitialValuesFromMenu = ({
  enabled,
  name,
 // order_extra_minutes,
  settings,
  where,
  bag_price,
}) => ({
    enabled,
    name,
   // order_extra_minutes,
    settings,
    where,
    bag_price,
});
const initialCreateValues = {
  enabled: false,
  name: '',
//  order_extra_minutes: 15,
  settings: {
    type: "EVERYDAY",
    from: "10:00",
    to: "22:00"
  },
  where: 'BOTH',
  bag_price: 0,
};


const ModalController = ({ children, create, closeModal }) => {
  const { menuId, restaurantId } = useParams();
  const invalidate = useInvalidate();
  const { menus, fullyLoaded } = useLayout();
  const [temporaryMenu, setTemporaryMenu] = useState(null);
  const [formKeyIndex, setFormKeyIndex] = useState(0);
  const menu = useMemo(
    () => create ? null : (temporaryMenu || menus.find((m) => m.id == menuId)),
    [menus, menuId, temporaryMenu, create],
  );
  const initialValues = useMemo(
    () => menu ? createInitialValuesFromMenu(menu) : initialCreateValues,
    [menu],
  );
  console.log('What is the menu?', menu);
  const [activeView, setActiveView] = useState('INFO');
  const go = useGo();
  useEffect(() => {
    if (temporaryMenu) {
      const corrospondingProduct = menus.find((p) => p.id == temporaryMenu.id);
      if (deepEqual(corrospondingProduct, temporaryMenu)) {
        setTemporaryMenu(null);
      }
    }
  }, [temporaryMenu, menus, create, go]);
  const { mutate: onDelete } = useDelete();
  const onDeleteMenu = () => {
    onDelete({
      resource: 'restaurants/menus',
      id: menuId,
      successNotification: 'Menu deleted successfully',
      onMutationSuccess: () => {
        const firstMenuNotDeleted = menus.find((m) => m.id != menuId);
        if (firstMenuNotDeleted) {
          go({ to: `../../menu/${firstMenuNotDeleted.id}` });
        } else {
          go({ to: `../../../onboarding/restaurant/${restaurantId}/menu/create` });
        }
        const resources = ['restaurants/menus/categories', 'restaurants/menus/products', 'restaurants/menus/subCategories', 'restaurant/qrCodes'];
        resources.forEach((resource) => invalidate({ resource, invalidates: ['resourceAll'] }));
      },
    });
  };
  if (!fullyLoaded) {
    return null;
  }
  // 
  const onActualCloseModal = () => {
    closeModal();
    setFormKeyIndex((prev) => prev + 1);
    setActiveView('INFO');
  };
  return children({
    formKey: `${hash(initialValues)}_${formKeyIndex}`,
    props: {
      info: {
        button: {
          onClick: (e) => { e.stopPropagation(); e.preventDefault(); setActiveView('INFO'); },
          active: activeView === 'INFO',
        },
        div: {
          style: { display: activeView === 'INFO' ? 'block' : 'none' },
        }
      },
      ordering: {
        button: {
          onClick: (e) => { e.stopPropagation(); e.preventDefault(); setActiveView('ORDERING'); },
          active: activeView === 'ORDERING',
        },
        div: {
          style: { display: activeView === 'ORDERING' ? 'block' : 'none' },
        },
      },
      buttonSection: {
        initialValues,
        create,
        closeModal: onActualCloseModal,
      },
      formController: {
        menuId,
        setTemporaryMenu,
        initialValues,
        create,
        closeModal: onActualCloseModal,
      },
      deleteMenu: {
        onClick: onDeleteMenu,
      }
    },
  })
};

export default ModalController;

import { useRef, useEffect } from 'react';
import { Input } from 'antd';
import { createInput } from '../../../../components/Inputs';
import { ModalCategoryTextInput } from '../../../../components/Layout/modal';
import { Body } from '../../../../components/Layout/typography';

const CategoryNameInput = ({
    editing,
    onEdit,
    onStopEdit,
    value,
    onChange,
    products,
    disabled,
}) => {
  const onActualStopEdit = (e) => {
    e.stopPropagation();
    onStopEdit();
  };
  const inputRef = useRef();
  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus({
        cursor: 'end',
      });
    }
  }, [editing]);
    return (
        <>
          <Input
            ref={inputRef}
            onChange={onChange}
            value={value}
            style={{ display: editing ? 'block' : 'none' }}
            onPressEnter={onActualStopEdit}
            placeholder="e.g. Snacks, Burgers, Dessert, Drinks..."
          />
          <ModalCategoryTextInput uncategorized={value==='Uncategorized'} style={{ display: editing ? 'none' : 'flex' }} onClick={disabled ? null : onEdit}>
              {value === 'Uncategorized' ? (
                <Body sm>{value}</Body>
              ) : (
                <>{value}</>
              )}
              {products > 0 && (
                <Body>
                  {products} Products
                </Body>
              )}
          </ModalCategoryTextInput>
        </>
    );
};

export default createInput(CategoryNameInput);

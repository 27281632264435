import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import { useList, useGo } from '@refinedev/core';
import { Outlet } from 'react-router-dom';


const LoggedInPageContainer = () => {
  const {
    restaurantId,
  } = useParams();
  const res = useList({
    resource: 'restaurants/menus',
    meta: {
      restaurantId,
    },
  });
  const go = useGo();
  const firstMenuId = res.data?.data[0]?.id;
  useEffect(() => {
    if (res.data?.data) {
      const resData = res.data.data;
      console.log('resData', resData);
      if (resData.length === 0) {
        go({ to: `/onboarding/restaurant/${restaurantId}/menu/create` });
      } else {
        const menu = resData[0];
        console.log('Going To a menu', menu);
        go({ to: `/restaurant/${restaurantId}/menu/${firstMenuId}` });
      }
    }
  }, [firstMenuId]);
  return <Outlet />;
};

export default LoggedInPageContainer;
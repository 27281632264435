

const Plan = () => {
  return (
    <div>
      Plan
    </div>
  );
};

export default Plan;

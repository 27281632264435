import { useState } from 'react';
import Profile from './Profile';
import Email from './Email';
import Password from './Password';

const UserSettings = ({ closeModal }) => {
  const [view, setView] = useState('PROFILE');
  const onGotoPassword = () => {
    setView('PASSWORD');
  };
  const onGotoEmail = () => {
    setView('EMAIL');
  };
  const onGotoProfile = () => {
    setView('PROFILE');
  };
  switch (view) {
    case 'PASSWORD':
      return (
        <Password setView={setView} onClose={onGotoProfile} />
      );
    case 'EMAIL':
      return (
        <Email setView={setView} onClose={onGotoProfile} />
      );
    default:
    case 'PROFILE':
      return (
        <Profile setView={setView} onGotoPassword={onGotoPassword} onGotoEmail={onGotoEmail} closeModal={closeModal} />
      );
  }
};

export default UserSettings;
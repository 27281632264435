
import { useState } from 'react';
import { useGetIdentity } from '@refinedev/core';
import hash from 'object-hash';

const initialValues = {
  password: '',
  email: '',
}


const ModalController = ({ children, onClose }) => {
  const identity = useGetIdentity().data;
  const [formKeyIndex, setFormKeyIndex] = useState(0);
  const onActualCloseModal = () => {
    onClose();
    setFormKeyIndex((prev) => prev + 1);
  };
  return children({
    formKey: `${hash(initialValues)}_${formKeyIndex}`,
    props: {
      buttonSection: {
        initialValues,
        closeModal: onActualCloseModal,
      },
      formController: {
        initialValues,
        userId: identity?.id,
        closeModal: onActualCloseModal,
      },
    },
  })
};

export default ModalController;

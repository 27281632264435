
import { useState, useMemo, useEffect } from 'react';
import { useGo } from '@refinedev/core';
import { useParams } from 'react-router-dom';
import deepEqual from 'deep-equal';
import { useSelect } from '../../../../lib/refine'; 
import { useLayout } from '../../../../layouts/Restaurant/LayoutContext';
import hash from 'object-hash';

const createInitialValuesFromRestaurant = ({
  name,
  country_id,
  address,
  zip,
  city,
}) => ({
  name,
  country_id,
  address,
  zip,
  city,
});


const ModalController = ({ children, create, closeModal }) => {
  const { restaurantId } = useParams();
  const { fullyLoaded, restaurant } = useLayout();
  const [formKeyIndex, setFormKeyIndex] = useState(0);
  const initialValues = useMemo(
    () => restaurant ? createInitialValuesFromRestaurant(restaurant) : {},
    [restaurant],
  );
  const countrySelectProps = useSelect({
    resource: 'countries',
    optionLabel: 'name',
    suspenseKey: 'COUNTRIES',
  });
  if (!fullyLoaded) {
    return null;
  }
  // 
  const onActualCloseModal = () => {
    closeModal();
    setFormKeyIndex((prev) => prev + 1);
  };
  return children({
    formKey: `${hash(initialValues)}_${formKeyIndex}`,
    props: {
      countrySelect: countrySelectProps,
      buttonSection: {
        initialValues,
        create,
        closeModal: onActualCloseModal,
      },
      formController: {
        restaurantId,
        initialValues,
        create,
        closeModal: onActualCloseModal,
      },
    },
  })
};

export default ModalController;

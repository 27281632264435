const restaurants = {
  name: 'restaurants',
  meta: {
    resourceType: 'top-level',
    hide: false,
    idParamName: 'restaurantId',
    dbName: 'RESTAURANT',
  },
  resources: [
    {
      name: 'session-purchases',
      meta: {
        resourceType: 'collection',
        idParamName: 'sessionPurchaseId',
      },
    },
    {
      name: 'qr-codes',
      meta: {
        resourceType: 'collection',
        idParamName: 'qrCodeId',
      },
      resources: [
        {
          name: 'sessions',
          meta: {
            resourceType: 'collection',
            idParamName: 'sessionId',
          },
          resources: [
            {
              name: 'products',
              meta: {
                resourceType: 'collection',
                idParamName: 'sessionProductId',
              },
            },
            {
              name: 'purchases',
              meta: {
                resourceType: 'collection',
                idParamName: 'sessionPurchaseId',
              },
            }
          ]
        }
      ]
    },
    {
      name: 'menus',
      meta: {
        resourceType: 'collection',
        idParamName: 'menuId',
      },
      resources: [
        {
          name: 'qr-codes',
          meta: {
            resourceType: 'collection',
            idParamName: 'qrCodeId',
          },
        },
        {
          name: 'products',
          meta: {
            resourceType: 'collection',
            idParamName: 'productId',
          },
        },
        {
          name: 'set-categories',
          meta: {
            resourceType: 'collection',
          },
        },
        {
          name: 'categories',
          meta: {
            resourceType: 'collection',
            idParamName: 'categoryId',
          },
          resources: [
            {
              name: 'sub-categories',
              meta: {
                resourceType: 'collection',
                idParamName: 'subCategoryId',
              },
            },
          ],
        },
        {
          name: 'sub-categories',
          meta: {
            resourceType: 'collection',
            idParamName: 'subCategoryId',
          },
        }
      ],
    },
    {
      name: 'users',
      meta: {
        resourceType: 'collection',
        idParamName: 'restaurantUserId',
      },
    },
  ],
};

export default restaurants;

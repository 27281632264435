import styled from "styled-components";
import { colors } from "./template";
import { Button } from "antd";
import { Caption } from "./typography";

export const YumyProductCategoriesWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const YumyProductCategories = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 16px 0;
  overflow-x: auto;
  cursor: default !important;

  // Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
`;

export const YumyProductCategoriesButton = styled(Button)`
  border: 0;
  font-weight: 600;
  transition: all 0.3s ease;
  cursor: pointer !important;

  ${({ active }) =>
    active
      ? `
    background-color: ${colors.yumyGreen};
    color: ${colors.yumyWhite};

    &:hover {
      background-color: ${colors.yumyGreenHover} !important;
      color: ${colors.yumyWhite} !important;
    }
  `
      : `
    background-color: ${colors.grayScale900_4};
    color: ${colors.yumyBlack};
    &:hover {
      background-color: ${colors.yumyGreens100} !important;
    }
  `}
`;

export const YumyProductsSearch = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${colors.grayScale900_4};
  border-radius: 100px;
  cursor: pointer !important;
  width: 35px;
  flex-shrink: 0;
  transition: all 0.3s ease;

  & > input {
    border: 0;
    outline: 0;
    padding: 0;
    width: 0px;
    background-color: transparent;
    caret-color: ${colors.yumyGreen};
    font-weight: 600;
    line-height: 180%;
    font-size: 12px;

    &::placeholder {
      color: ${colors.grayScale900_40};
    }
  }

  ${({ searchActive }) =>
    searchActive &&
    `
    background-color: ${colors.yumyGreens100};
    width: 232px;
    margin-right: 32px;
    & > input {
      display: block;
      width: 100%;
      padding-right: 16px;
    }
  `}

  &:hover {
    background-color: ${colors.yumyGreens100};
  }
`;

export const YumyProductsSearchIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
`;

export const YumyProductsSearchClose = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  padding: 6px;
  cursor: pointer;
  background-color: ${colors.grayScale900_4};
  border-radius: 100px;
  transition: all 0.3s ease;

  position: absolute;
  right: -32px;

  &:hover {
    background-color: ${colors.yumyGreens100};
  }

  ${({ searchActive }) =>
    searchActive &&
    `
    display: flex;
  `}
`;

export const YumyProducts = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
`;

export const YumyProductsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 16px 0;

  border-bottom: 1px solid ${colors.grayScale900_8};

  @media (max-width: 767px) {
    ${({ headers }) =>
      !headers &&
      `
      display: grid;
      grid-template-columns: 30px 1fr;
      row-gap: 16px;
    `}
  }
`;

export const YumyProductsColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ checkbox, product, category, created, price, status, selected, more }) =>
    checkbox
      ? `
    flex-basis: 72px;
    flex-shrink: 0;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 767px) {
      flex-basis: 30px;
    }
  `
      : product
      ? `
    flex-basis: 25%;
    flex-shrink: 0;

    @media (max-width: 1199px) {
      flex-basis: 40%;
    }

    @media (max-width: 767px) {
      flex-basis: calc(100% - 72px - 30px);
    }

    & .ant-image {
      width: 30px;
      height: 50px;
      border-radius: 4px;
      flex-shrink: 0;
      overflow: hidden;

      & img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  `
      : category
      ? `
    flex-basis: 15%;
    flex-shrink: 0;

    @media (max-width: 767px) {
      display: none;
    }
  `
      : created
      ? `
    flex-basis: 15%;
    flex-shrink: 0;

    @media (max-width: 1199px) {
      display: none;
    }
  `
      : price
      ? `
    flex-basis: 15%;
    flex-shrink: 0;

    @media (max-width: 991px) {
      display: none;
    }
  `
      : status
      ? `
    flex-basis: 100%;

    @media (max-width: 767px) {
      display: none;
    }
  `
      : selected
      ? `
    flex-basis: 100%;
  `
      : more
      ? `
    flex-basis: 72px;
    padding-right: 16px;
    flex-shrink: 0;
    justify-content: flex-end;

    &:not(.header) {
      @media (max-width: 767px) {
        grid-column: span 2;
        justify-content: space-between;
        padding-left: 36px;
      }
    }
  `
      : `
    flex-basis: 100%;
  `}
  }
`;

export const YumyProductsColumnResponsive = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const YumyProductsName = styled.p`
  cursor: pointer;
  padding: 12px 0;
  transition: all 0.3s ease;

  &:hover {
    color: ${colors.yumyGreen};
  }
`;

export const YumyProductsMore = ({ children, disabled, ...props }) => {
  return (
    <Caption
      extraClass={`transition-colors duration-300 ease ${
        disabled
          ? "text-grayScale900-30 cursor-not-allowed"
          : "cursor-pointer hover:text-yumyGreen"
      }`}
      {...props}
    >
      {children}
    </Caption>
  );
};

export const YumyProductsMore2 = styled(Caption)`
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: ${colors.yumyGreen};
  }

  ${({ disabled }) =>
    disabled &&
    `
    color: ${colors.grayScale900_30};
    cursor: not-allowed;
  `}
`;

export const YumyProductsCategory = styled.div`
  display: flex;
  flex-direction: column;
`;

export const YumyProductsCategoryHeader = styled(YumyProductsRow)`
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${colors.grayScale900_4};
  }

  ${({ active }) =>
    active &&
    `
    background-color: ${colors.grayScale900_4};
  `}
`;

import { useList as useListParent } from '@refinedev/core';
import { useSuspense } from './Suspense';


export const useList = ({ suspenseKey, ...rest }) => {
  const ret = useListParent(rest);
  useSuspense(suspenseKey, ret.isLoading);
  return ret;
};

export default useList;
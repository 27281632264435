export const YumyIconReceipt = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M4.1665 3.3335H15.8332V17.0835C15.8332 17.3808 15.5307 17.5825 15.2562 17.4681L13.6 16.778C13.4272 16.706 13.2351 16.6943 13.0548 16.7448L10.6739 17.4114C10.233 17.5349 9.76667 17.5349 9.32576 17.4114L6.94487 16.7448C6.76456 16.6943 6.57251 16.706 6.39967 16.778L4.74343 17.4681C4.46897 17.5825 4.1665 17.3808 4.1665 17.0835V3.3335Z"
      fill="#23D7C2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.49984 7.5C7.0396 7.5 6.6665 7.8731 6.6665 8.33333C6.6665 8.79357 7.0396 9.16667 7.49984 9.16667H9.1665C9.62674 9.16667 9.99984 8.79357 9.99984 8.33333C9.99984 7.8731 9.62674 7.5 9.1665 7.5H7.49984ZM12.4998 7.5C12.0396 7.5 11.6665 7.8731 11.6665 8.33333C11.6665 8.79357 12.0396 9.16667 12.4998 9.16667C12.9601 9.16667 13.3332 8.79357 13.3332 8.33333C13.3332 7.8731 12.9601 7.5 12.4998 7.5ZM6.6665 11.6667C6.6665 11.2064 7.0396 10.8333 7.49984 10.8333H9.1665C9.62674 10.8333 9.99984 11.2064 9.99984 11.6667C9.99984 12.1269 9.62674 12.5 9.1665 12.5H7.49984C7.0396 12.5 6.6665 12.1269 6.6665 11.6667ZM12.4998 10.8333C12.0396 10.8333 11.6665 11.2064 11.6665 11.6667C11.6665 12.1269 12.0396 12.5 12.4998 12.5C12.9601 12.5 13.3332 12.1269 13.3332 11.6667C13.3332 11.2064 12.9601 10.8333 12.4998 10.8333Z"
      fill="#118376"
    />
    <path
      d="M2.5 3.33333C2.5 2.8731 2.8731 2.5 3.33333 2.5H16.6667C17.1269 2.5 17.5 2.8731 17.5 3.33333C17.5 3.79357 17.1269 4.16667 16.6667 4.16667H3.33333C2.8731 4.16667 2.5 3.79357 2.5 3.33333Z"
      fill="#118376"
    />
  </svg>
);

export const YumyIconQR = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <rect
      x="3.333"
      y="3.333"
      width="5"
      height="5"
      rx=".833"
      fill="#23D7C2"
      stroke="#23D7C2"
      strokeWidth="1.667"
      strokeLinecap="round"
    />
    <rect
      x="3.333"
      y="11.666"
      width="5"
      height="5"
      rx=".833"
      stroke="#118376"
      strokeWidth="1.667"
      strokeLinecap="round"
    />
    <rect
      x="11.666"
      y="3.333"
      width="5"
      height="5"
      rx=".833"
      stroke="#118376"
      strokeWidth="1.667"
      strokeLinecap="round"
    />
    <path
      stroke="#118376"
      strokeWidth=".833"
      strokeLinecap="round"
      d="M11.25 11.25h.833v.833h-.833zm0 3.333h.833v.833h-.833z"
    />
    <path
      stroke="#23D7C2"
      strokeWidth=".833"
      strokeLinecap="round"
      d="M12.917 12.917h.833v.833h-.833zm0 3.333h.833v.833h-.833z"
    />
    <path
      stroke="#118376"
      strokeWidth=".833"
      strokeLinecap="round"
      d="M14.583 11.25h.833v.833h-.833zm0 3.333h.833v.833h-.833z"
    />
    <path
      stroke="#23D7C2"
      strokeWidth=".833"
      strokeLinecap="round"
      d="M16.25 12.917h.833v.833h-.833zm0 3.333h.833v.833h-.833z"
    />
  </svg>
);

export const YumyIconQRHeader = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      d="M0 5.333h5.333V0H0zm1.333-4H4V4H1.333zM0 12h5.333V6.667H0zm1.333-4H4v2.667H1.333zm5.334-8v5.333H12V0zm4 4H8V1.333h2.666zM12 10.667h-1.334V12H12zm-4-4H6.667V8H8zM9.333 8H8v1.333h1.333zM8 9.333H6.667v1.333H8zm1.333 1.334H8V12h1.333zm1.334-1.334H9.334v1.333h1.333zm0-2.666H9.334V8h1.333zM12 8h-1.334v1.333H12z"
      fill="#121127"
    />
  </svg>
);

export const YumyIconKDS = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M12.974 2.5c-.21 0-.416 0-.589.014-.19.016-.415.052-.642.168-.313.16-.568.414-.728.728a1.7 1.7 0 0 0-.167.641c-.015.174-.014.379-.014.59V15.36c0 .21 0 .415.014.588.015.191.052.416.167.642.16.314.415.569.728.728.227.116.451.152.642.168.173.014.378.014.588.014h2.387c.21 0 .415 0 .589-.014a1.7 1.7 0 0 0 .641-.168c.314-.16.569-.414.729-.728.115-.226.152-.451.167-.642.014-.173.014-.378.014-.588V4.64c0-.21 0-.415-.014-.589a1.7 1.7 0 0 0-.168-.641 1.67 1.67 0 0 0-.728-.728 1.7 1.7 0 0 0-.641-.168c-.174-.014-.379-.014-.589-.014z"
      fill="#23D7C2"
    />
    <path
      d="M4.64 2.5c-.21 0-.415 0-.589.014a1.7 1.7 0 0 0-.641.168c-.314.16-.569.414-.728.728a1.7 1.7 0 0 0-.168.641c-.014.174-.014.379-.014.59V15.36c0 .21 0 .415.014.588.016.191.052.416.168.642.16.314.414.569.728.728.226.116.451.152.641.168.174.014.379.014.589.014h2.387c.21 0 .415 0 .588-.014.19-.016.415-.052.642-.168.313-.16.568-.414.728-.728.115-.226.152-.451.168-.642.014-.173.014-.378.014-.588V4.64c0-.21 0-.415-.014-.589a1.7 1.7 0 0 0-.168-.641 1.67 1.67 0 0 0-.728-.728 1.7 1.7 0 0 0-.642-.168C7.442 2.5 7.237 2.5 7.027 2.5z"
      fill="#118376"
    />
  </svg>
);

export const YumyIconSettings = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path d="M16.192 10.817c.033-.267.058-.533.058-.817s-.025-.55-.058-.816l1.758-1.375a.42.42 0 0 0 .1-.534l-1.667-2.883a.416.416 0 0 0-.508-.183l-2.075.833a6 6 0 0 0-1.408-.817l-.317-2.208a.406.406 0 0 0-.409-.35H8.333a.406.406 0 0 0-.408.35l-.317 2.208a6.4 6.4 0 0 0-1.408.817l-2.075-.833a.5.5 0 0 0-.15-.025.41.41 0 0 0-.358.208L1.95 7.275a.41.41 0 0 0 .1.534l1.758 1.375A7 7 0 0 0 3.75 10c0 .275.025.55.058.817L2.05 12.192a.42.42 0 0 0-.1.533l1.667 2.884a.416.416 0 0 0 .508.183l2.075-.833c.433.333.9.608 1.408.816l.317 2.209c.025.2.2.35.408.35h3.333c.209 0 .384-.15.409-.35l.317-2.209a6.4 6.4 0 0 0 1.408-.816l2.075.833q.075.025.15.025a.41.41 0 0 0 .358-.208l1.667-2.884a.42.42 0 0 0-.1-.533zm-1.65-1.425c.033.258.041.433.041.608s-.017.359-.042.609l-.116.941.741.584.9.7-.583 1.008-1.058-.425-.867-.35-.75.567c-.358.266-.7.466-1.041.608l-.884.358-.133.942-.167 1.125H9.417l-.159-1.125-.133-.942-.883-.358a4.7 4.7 0 0 1-1.025-.592l-.759-.583-.883.358-1.058.425-.584-1.008.9-.7.742-.583-.117-.942A7 7 0 0 1 5.417 10c0-.166.016-.358.041-.608l.117-.942-.742-.583-.9-.7.584-1.008 1.058.425.867.35.75-.567a4.8 4.8 0 0 1 1.041-.608l.884-.359.133-.941.167-1.125h1.158l.158 1.125.133.941.884.359c.358.15.691.341 1.025.591l.758.584.883-.359 1.059-.425.583 1.009-.892.708-.741.583zM10 6.667A3.332 3.332 0 1 0 13.333 10 3.33 3.33 0 0 0 10 6.667m0 5c-.917 0-1.667-.75-1.667-1.667 0-.916.75-1.666 1.667-1.666.916 0 1.666.75 1.666 1.666 0 .917-.75 1.667-1.666 1.667" />
  </svg>
);

export const YumyIconPlus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8.667 3.333a.667.667 0 1 0-1.333 0v4h-4a.667.667 0 0 0 0 1.334h4v4a.667.667 0 1 0 1.333 0v-4h4a.667.667 0 1 0 0-1.334h-4z"
      fill="#fff"
    />
  </svg>
);

export const YumyIconExpand = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" fill="#121127" />
  </svg>
);

export const YumyIconExpandFlip = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    transform="scale(1 -1)"
  >
    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" fill="#121127" />
  </svg>
);

export const YumyIconCheckGreen = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10 1.667a8.333 8.333 0 1 0 0 16.666 8.333 8.333 0 0 0 0-16.666"
      fill="#118376"
    />
    <path
      d="M13.09 7.744a.833.833 0 0 1 0 1.179l-3.334 3.333a.833.833 0 0 1-1.179 0l-1.666-1.667a.833.833 0 0 1 1.178-1.178l1.077 1.077 2.745-2.744a.833.833 0 0 1 1.178 0"
      fill="#fff"
    />
  </svg>
);

export const YumyIconSquareDot = () => (
  <svg
    width="4"
    height="6"
    viewBox="0 0 4 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="#118376" d="M0 .92h3.928v4.159H0z" />
  </svg>
);

export const YumyIconRadioChecked = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 1.333A6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.666 6.67 6.67 0 0 0 14.666 8 6.67 6.67 0 0 0 8 1.333m0 12A5.33 5.33 0 0 1 2.666 8 5.33 5.33 0 0 1 8 2.666 5.33 5.33 0 0 1 13.333 8 5.33 5.33 0 0 1 8 13.333"
      fill="#121127"
    />
    <path
      d="M8 11.334a3.333 3.333 0 1 0 0-6.667 3.333 3.333 0 0 0 0 6.667"
      fill="#121127"
    />
  </svg>
);

export const YumyIconRadioUnchecked = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 1.333A6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.666 6.67 6.67 0 0 0 14.666 8 6.67 6.67 0 0 0 8 1.333m0 12A5.33 5.33 0 0 1 2.666 8 5.33 5.33 0 0 1 8 2.666 5.33 5.33 0 0 1 13.333 8 5.33 5.33 0 0 1 8 13.333"
      fill="#121127"
    />
  </svg>
);

export const YumyIconSearch = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      d="m15.281 15.5-5.454-5.25q-.649.5-1.494.792a5.5 5.5 0 0 1-1.796.291q-2.36 0-3.994-1.573T.91 5.917t1.634-3.844T6.537.5q2.36 0 3.993 1.573 1.635 1.573 1.635 3.844 0 .916-.303 1.729a4.9 4.9 0 0 1-.823 1.437l5.455 5.25zM6.537 9.667q1.623 0 2.76-1.094t1.136-2.656T9.297 3.26Q8.16 2.167 6.537 2.167T3.777 3.26Q2.641 4.355 2.641 5.917t1.136 2.656 2.76 1.094"
      fill="#121127"
    />
  </svg>
);

export const YumyIconMenu = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="m5.4 8.893 1.887-1.886-4.68-4.674a2.67 2.67 0 0 0 0 3.774zm4.52-1.206c1.02.473 2.453.14 3.513-.92 1.274-1.274 1.52-3.1.54-4.08-.973-.974-2.8-.734-4.08.54-1.06 1.06-1.393 2.493-.92 3.513l-6.506 6.507.94.94L8 9.607l4.587 4.586.94-.94L8.94 8.667z"
      fill="#121127"
    />
  </svg>
);

export const YumyIconRestaurant = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M4.584 5.834c-.69 0-1.25.56-1.25 1.25v7.917a2.5 2.5 0 0 0 2.5 2.5h8.333a2.5 2.5 0 0 0 2.5-2.5V7.084c0-.69-.56-1.25-1.25-1.25z"
      fill="#23D7C2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.667 17.5H8.334v-3.333a1.667 1.667 0 1 1 3.333 0z"
      fill="#118376"
    />
    <path
      d="m5.833 2.5 8.334.001c1.666 0 3.333 1.25 3.333 3.333 0 1.25-1.25 2.5-2.5 2.5-1.667 0-2.5-1.667-2.5-1.667s-.417 1.667-2.5 1.667-2.5-1.667-2.5-1.667S6.667 8.333 5 8.333c-1.25 0-2.5-1.25-2.5-2.5 0-2.083 1.667-3.33 3.333-3.333"
      fill="#118376"
    />
  </svg>
);

export const YumyIconWalk = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M9 3.667c.733 0 1.333-.6 1.333-1.334C10.333 1.6 9.733 1 9 1s-1.333.6-1.333 1.333c0 .734.6 1.334 1.333 1.334M6.533 5.933l-1.866 9.4h1.4L7.267 10l1.4 1.333v4H10v-5L8.6 9 9 7c.867 1 2.2 1.667 3.667 1.667V7.333c-1.267 0-2.334-.666-2.867-1.6l-.667-1.066a1.43 1.43 0 0 0-1.766-.56L4 5.533v3.134h1.333V6.4z"
      fill="#121127"
    />
  </svg>
);

export const YumyIconRightArrowWhite = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M10.862 10.862a.667.667 0 0 0 .943.943l3.333-3.334a.667.667 0 0 0 0-.942l-3.333-3.334a.667.667 0 0 0-.943.943l2.195 2.195H1.333a.667.667 0 0 0 0 1.334h11.724z"
      fill="#fff"
    />
  </svg>
);

export const YumyIconRightArrowDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="m18 10-1.41-1.41L12 13.17 7.41 8.59 6 10l6 6z"
      fill="#fff"
      fillOpacity=".24"
    />
  </svg>
);

export const YumyIconComment = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="m13.333 11.447-.78-.78H2.667v-8h10.666zm0-10.114H2.667c-.734 0-1.334.6-1.334 1.334v8c0 .733.6 1.333 1.334 1.333H12l2.667 2.667v-12c0-.734-.6-1.334-1.334-1.334"
      fill="#121127"
    />
  </svg>
);

export const YumyIconCommentGreen = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="m13.333 11.447-.78-.78H2.667v-8h10.666zm0-10.114H2.667c-.734 0-1.334.6-1.334 1.334v8c0 .733.6 1.333 1.334 1.333H12l2.667 2.667v-12c0-.734-.6-1.334-1.334-1.334"
      fill="#118376"
    />
  </svg>
);

export const YumyIconFullScreen = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M7 14H5v5h5v-2H7zm-2-4h2V7h3V5H5zm12 7h-3v2h5v-5h-2zM14 5v2h3v3h2V5z" />
  </svg>
);

export const YumyIconFullScreenExit = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 16h3v3h2v-5H5zm3-8H5v2h5V5H8zm6 11h2v-3h3v-2h-5zm2-11V5h-2v5h5V8z" />
  </svg>
);

export const YumyIconClose = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path d="m12.667 4.273-.94-.94L8 7.06 4.273 3.333l-.94.94L7.06 8l-3.727 3.727.94.94L8 8.94l3.727 3.727.94-.94L8.94 8z" />
  </svg>
);

export const YumyIconMyPlan = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M13.334 2.667H2.667c-.74 0-1.327.593-1.327 1.333l-.007 8c0 .74.594 1.333 1.334 1.333h10.667c.74 0 1.333-.593 1.333-1.333V4c0-.74-.594-1.333-1.333-1.333m0 9.333H2.667V8h10.667zm0-6.667H2.667V4h10.667z"
      fill="#121127"
    />
  </svg>
);

export const YumyIconAccount = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 1.333A6.67 6.67 0 0 0 1.334 8 6.67 6.67 0 0 0 8 14.667 6.67 6.67 0 0 0 14.667 8 6.67 6.67 0 0 0 8 1.333M4.714 12.187C5 11.587 6.746 11 8 11s3.007.587 3.287 1.187A5.26 5.26 0 0 1 8 13.333c-1.24 0-2.38-.426-3.286-1.146m7.526-.967C11.287 10.06 8.973 9.667 8 9.667s-3.286.393-4.24 1.553A5.3 5.3 0 0 1 2.667 8 5.34 5.34 0 0 1 8 2.667 5.34 5.34 0 0 1 13.334 8a5.3 5.3 0 0 1-1.094 3.22M8 4a2.327 2.327 0 0 0-2.333 2.333A2.327 2.327 0 0 0 8 8.667a2.327 2.327 0 0 0 2.334-2.334A2.327 2.327 0 0 0 8 4m0 3.333a1 1 0 1 1 1-1c0 .554-.447 1-1 1"
      fill="#121127"
    />
  </svg>
);

export const YumyIconLogout = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
  >
    <path
      d="M6.334 2.667 5.4 3.6l1.733 1.733h-6.8v1.334h6.8L5.4 8.4l.933.933L9.668 6zm6 8H7V12h5.334c.733 0 1.333-.6 1.333-1.333V1.333C13.667.6 13.067 0 12.334 0H7v1.333h5.334z"
      fill="#121127"
    />
  </svg>
);

import QRFormController from "./Controllers/QRFormController";
import ButtonSectionController from "./Controllers/ButtonSectionController";
import ModalController from "./Controllers/ModalController";
import Form from "../../../../lib/Form";
import { Input } from "../../../../components/Inputs";
import { FormContent } from "../../../../components/Layout/forms";
import {
  ModalMain,
  ModalHeader,
  YumyModal,
  ModalInnerWrap,
  ModalDouble,
  ModalContent,
} from "../../../../components/Layout/modal";
import { Flex } from "antd";
import { Switch, Select } from "../../../../components/Inputs";
import { Button, Popconfirm } from "antd";
import { Page } from "../../../../components/Layout/template";

export default function ProductForm() {
  return (
    <>
      <Page modal>
        <YumyModal>
          <ModalController>
            {({
              key,
              menuSelectProps,
              initialValues,
              qrCode,
              qrCodeId,
              setTemporaryQRCode,
            }) => (
              <QRFormController
                createNotification="QR Code created"
                updateNotification="QR Code updated"
                qrCodeId={qrCodeId}
                initialValues={initialValues}
                setTemporaryQRCode={setTemporaryQRCode}
                key={key}
              >
                {({ key, props, edit }) => (
                  <Form {...props.form} key={key}>
                    <ModalInnerWrap>
                      <ModalHeader>
                        <h4>{edit ? "Edit QR Code" : "Add QR Code"}</h4>
                        <ButtonSectionController {...props.buttonSection}>
                          {({ props, hasChanges, edit }) => (
                            <Flex gap={8}>
                              <Switch
                                name="active"
                                checkedChildren="Active"
                                unCheckedChildren="Draft"
                                labelLeft
                                noLabelHeight
                              />
                              <Button
                                className="action"
                                type="primary"
                                htmlType="submit"
                                {...props.save}
                              >
                                {!edit && "Create QR"}
                                {edit && (hasChanges ? "Save" : "Saved")}
                              </Button>
                              {hasChanges && (
                                <Popconfirm
                                  title="Cancel edit"
                                  description={
                                    <>
                                      <p>
                                        Possible edits will be lost.
                                        <br />
                                        Are you sure you want to cancel?
                                      </p>
                                    </>
                                  }
                                  okText="Yes"
                                  cancelText="No"
                                  {...props.popConfirm}
                                >
                                  <Button className="action">Cancel</Button>
                                </Popconfirm>
                              )}
                              {!hasChanges && (
                                <Button {...props.close} className="action">
                                  Close
                                </Button>
                              )}
                            </Flex>
                          )}
                        </ButtonSectionController>
                      </ModalHeader>
                      <FormContent modal>
                        <ModalMain>
                          <p>Select menu</p>
                          <ModalContent>
                            <ModalDouble>
                              <Select
                                label="Menu name"
                                name="menu_id"
                                {...menuSelectProps}
                              />
                              <Input type="hidden" name="order" />
                            </ModalDouble>
                            <ModalDouble>
                              <Input
                                label="QR Code name"
                                name="name"
                                placeholder="e.g. Table 01, QR Outside"
                              />
                              <Input
                                label="Note"
                                name="note"
                                placeholder="Add note for self"
                              />
                            </ModalDouble>
                          </ModalContent>
                        </ModalMain>
                      </FormContent>
                    </ModalInnerWrap>
                  </Form>
                )}
              </QRFormController>
            )}
          </ModalController>
        </YumyModal>
      </Page>
    </>
  );
}

import "./App.css";
import { RouterProvider, createBrowserRouter, Outlet } from "react-router-dom";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { dataProvider } from "./rest-data-provider";
import useI18nProvider from "./providers/useI18nProvider";
import { Refine } from "@refinedev/core";
import useAuthProvider from "./providers/useAuthProvider";
import { notificationProvider } from "@refinedev/antd";
import { ConfigProvider, App as AntdApp } from "antd";
import routerProvider from "@refinedev/react-router-v6";
import resources from "./resources";
import routes from "./routes";
import env from "./env";
// import "@refinedev/antd/dist/reset.css";

const dP = dataProvider(`${env.apiUrl}/api`);
// const queryClient = new QueryClient();
const InsideApp = () => {
  const i18nProvider = useI18nProvider();
  const authProvider = useAuthProvider(i18nProvider, dP);
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#118376",
          fontFamily: "Noto Sans, sans-serif",
          borderRadius: 8,

          // Map Token
          colorText: "#121127",

          // Alias Token
          fontWeightStrong: 800,
        },
      }}
    >
      <AntdApp>
        <RefineKbarProvider>
          <Refine
            i18nProvider={i18nProvider}
            dataProvider={dP}
            authProvider={authProvider}
            routerProvider={routerProvider}
            notificationProvider={notificationProvider}
            resources={resources}
          >
            <Outlet />
            <RefineKbar />
          </Refine>
        </RefineKbarProvider>
      </AntdApp>
    </ConfigProvider>
  );
};

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <InsideApp />,
      children: routes,
    },
  ],
  {
    future: {
      v7_normalizeFormMethod: true,
    },
  }
);
function App() {
  return <RouterProvider router={router} />;
}

export default App;

import { useForm } from '../../../../lib/refine';

const FormController = ({ children, create, closeModal, restaurantId, initialValues }) => {
  const { formProps } = useForm({
    resource: 'restaurants',
    action: 'edit',
    id: restaurantId,
    successNotification: 'Restaurant has been updated',
    onMutationSuccess: (restaurant) => {
      closeModal();
    },
  });
  return children({
    formProps: {
      ...formProps,
      initialValues,
   //   preserve: false,
    },
  });
};

export default FormController;


export default function useRequiredFieldsFilled(values) {
  if (!values) {
    return false;
  }
  if (!values.current_password || !values.new_password || !values.confirm_new_password) {
    return false;
  }
  return true;
};

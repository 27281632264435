import { createContext, useContext } from 'react';


const QRCodesContext = createContext(null);

export const useQRCodes = () => {
  const context = useContext(QRCodesContext);
  if (!context) {
    throw new Error('useQRCode must be used within a QRCodeProvider');
  }
  return context;
};

export default QRCodesContext;

import { useState, useEffect, useRef, useMemo } from "react";
import { useMenu } from "../Controllers/MenuContext";
import { useCreate, useInvalidate } from "@refinedev/core";
import createInput from "../../../../components/Inputs/createInput";
import { Divider, Space, Button, Select, Empty, Form, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Body } from "../../../../components/Layout/typography";

const AddBar = ({ addItem, name }) => {
  if (!name) {
    return (
      <>
        <Divider style={{ margin: "8px 0" }} />
        <Body extraClass="pt-0 px-3 pb-1">
          Begin typing to create a new subcategory
        </Body>
      </>
    );
  }
  return (
    <>
      <Divider style={{ margin: "8px 0" }} />
      <Space style={{ padding: "0 8px 4px" }}>
        <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
          Add {name}
        </Button>
      </Space>
    </>
  );
};

const SubCategorySelect = ({ onChange, value, form, ...props }) => {
  const { subCategories, categories } = useMenu();
  const uncategorizedId = useMemo(
    () => (categories.find(({ uncategorized }) => uncategorized) || {}).id,
    [categories]
  );
  const categoryId = Form.useWatch("category_id", form);
  console.log(form.getFieldsValue("category_id"));
  const [previousName, setPreviousName] = useState("");
  const [name, setName] = useState("");
  const onActualSetName = (v) => {
    setName(v);
    setPreviousName(name);
  };
  const { mutate: onCreate } = useCreate();
  const invalidate = useInvalidate();
  const [isAdding, setIsAdding] = useState(false);
  const [newlyCreatedSubCategory, setNewlyCreatedSubCategory] = useState(null);
  const optionCategories = subCategories.filter(
    (o) => o.category_id === categoryId
  );
  const options = optionCategories.map((o) => ({ label: o.name, value: o.id }));
  const actualOnChange = (v) => {
    onActualSetName("");
    onChange?.(v);
  };
  const [categoryIsCreated] = [
    ...subCategories.filter((o) => o.id == value),
    undefined,
  ];
  const addItem = () => {
    setIsAdding(true);
    onCreate(
      {
        resource: "restaurants/menus/categories/sub-categories",
        values: { name },
        meta: { id: categoryId },
      },
      {
        onSuccess: (r) => {
          invalidate({
            resource: "restaurants/menus/sub-categories",
            invalidates: ["list"],
          });
          setNewlyCreatedSubCategory(r.data.id);
        },
        onError: () => {
          setIsAdding(false);
        },
      }
    );
  };
  useEffect(() => {
    if (
      newlyCreatedSubCategory &&
      options.some((o) => o.value === newlyCreatedSubCategory)
    ) {
      setNewlyCreatedSubCategory(null);
      actualOnChange(newlyCreatedSubCategory);
      setIsAdding(false);
    }
  }, [newlyCreatedSubCategory, options]);
  const initialRun = useRef(true);
  useEffect(() => {
    if (initialRun.current) {
      initialRun.current = false;
      return;
    }
    if (
      value &&
      (!categoryId ||
        (categoryIsCreated && categoryIsCreated.category_id != categoryId))
    ) {
      actualOnChange(null);
    }
  }, [categoryId, value, categoryIsCreated]);
  const remainingOptions = options.filter((o) =>
    o.label.toLowerCase().includes(name.toLowerCase())
  );
  const sortedRemainingOptions = remainingOptions.sort((a, b) =>
    a.label.toLowerCase().localeCompare(b.label.toLowerCase())
  );
  const containsExactOption = options.some(
    (o) => o.label.toLowerCase() === name.toLowerCase()
  );
  return (
    <Tooltip
      title={
        categoryId === uncategorizedId
          ? "No sub categories under uncategorized"
          : null
      }
    >
      <Select
        {...props}
        options={options}
        onSearch={(v) => {
          onActualSetName(v);
        }}
        searchValue={name}
        onChange={actualOnChange}
        notFoundContent={<Empty description="No subcategories" />}
        onInputKeyDown={(e) => {
          /* If enter */
          if (e.keyCode === 13 && name) {
            if (sortedRemainingOptions.length === 0) {
              addItem();
            }
          }
        }}
        value={isAdding ? null : value}
        placeholder={
          categoryId
            ? isAdding
              ? previousName
              : "Choose subcategory"
            : "No category selected"
        }
        disabled={
          props.disabled ||
          isAdding ||
          !categoryId ||
          categoryId === uncategorizedId
        }
        showSearch
        loading={isAdding}
        allowClear
        onClear={() => {
          actualOnChange(null);
        }}
        filterOption={(input, option) => (option?.label ?? "").includes(input)}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        dropdownRender={(menu) => (
          <>
            {menu}
            {!containsExactOption && <AddBar name={name} addItem={addItem} />}
          </>
        )}
      />
    </Tooltip>
  );
};
export default createInput(SubCategorySelect);

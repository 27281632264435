import { Form } from 'antd';
import { useGo } from '@refinedev/core';
import useHasChanges from "./useHasChanges";
import useRequiredFieldsFilled from "./useRequiredFieldsFilled";


export default function ButtonSectionController({
  initialValues,
  edit,
  children,
}) {
  const go = useGo();
  const values = {
    name: Form.useWatch(['name']),
    note: Form.useWatch(['note']),
    active: Form.useWatch(['active']),
    menu_id: Form.useWatch(['menu_id']),
  };
  const hasChanges = useHasChanges({ initialValues, values });
  const requiredFieldsFilled = useRequiredFieldsFilled(values);
  return children({
    props: {
      save: {
        disabled: !hasChanges || !requiredFieldsFilled,
      },
      popConfirm: {
        onConfirm: () => go({ to: '..' }),
      },
      close: {
        onClick: !hasChanges ? () => go({ to: '..' }) : null,
      },
    },
    edit,
    hasChanges,
  });
}
import { useGo, useInvalidate } from '@refinedev/core';
import { useForm } from '../../../../lib/refine';

const FormController = ({ children, create, closeModal, menuId, setTemporaryMenu, initialValues }) => {
  const go = useGo();
  const invalidate = useInvalidate();
  const { formProps } = useForm({
    resource: 'restaurants/menus',
    action: create ? 'create' : 'edit',
    id: menuId,
    successNotification: create ? 'Menu has been added' : 'Menu has been updated',
    onMutationSuccess: (menu) => {
      if (create) {
        console.log('Clossssing the modal');
        closeModal();
        setTimeout(() => {
          go({ to: `/restaurant/${menu.data.restaurant_id}/menu/${menu.data.id}`});
        }, 1500);
      } else {
        setTemporaryMenu(menu.data);
      }
    },
  });
  return children({
    formProps: {
      ...formProps,
      initialValues,
   //   preserve: false,
    },
  });
};

export default FormController;

import { useState } from "react";
const FilterOrderStateController = ({ sessionPurchases, children }) => {

  // Filter logic
  const [enabledFilters, setEnabledFilters] = useState([
    "ORDERED",
    "ACCEPTED",
    "READY",
  ]);
  const onClickCookingOrNew = () =>
    setEnabledFilters(["ACCEPTED", "READY", "ORDERED"]);
  const onClickCompleted = () => setEnabledFilters(["COMPLETED"]);

  const filterButtonChoices = {
    cooking: {
      amount: sessionPurchases.filter(({ state }) => state === "ACCEPTED")
        .length,
      selected: enabledFilters.includes("ACCEPTED"),
      onClick: onClickCookingOrNew,
    },
    neww: {
      amount: sessionPurchases.filter(({ state }) => state === "ORDERED")
        .length,
      selected: enabledFilters.includes("ORDERED"),
      onClick: onClickCookingOrNew,
    },
    ready: {
      amount: sessionPurchases.filter(({ state }) => state === "READY").length,
      selected: enabledFilters.includes("READY"),
      onClick: onClickCookingOrNew,
    },
    completed: {
      amount: sessionPurchases.filter(({ state }) => state === "COMPLETED")
        .length,
      selected: enabledFilters.includes("COMPLETED"),
      onClick: onClickCompleted,
    },
  };

  const filteredSessionPurchases = sessionPurchases.filter(({ state }) =>
    enabledFilters.includes(state)
  );

  // Debug output
  console.log("Filtered orders:", filteredSessionPurchases.length);

  return children({
    sessionPurchases: filteredSessionPurchases,
    filterButtonChoices,
  });
};

export default FilterOrderStateController;

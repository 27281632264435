import FormController from "./Controllers/FormController";
import ModalController from "./Controllers/ModalController";
import ButtonSectionController from "./Controllers/ButtonSectionController";
import AutoAcceptController from "./Controllers/AutoAcceptController";
import { Select, Switch } from "../../../components/Inputs";
import { Button, Popconfirm, Form } from "antd";
import {
  MenuSettingsButtonsWrapper,
  MenuSettingsHeader,
  MenuSettingsInfoChoices,
  MenuSettingsTitle,
} from "./menuSettingsStyles";
import { FormContent } from "../../../components/Layout/forms";

const MenuSettings = ({ closeModal }) => (
  <ModalController closeModal={closeModal}>
    {({ props, formKey }) => (
      <FormController key={formKey} {...props.formController}>
        {({ formProps }) => (
          <Form {...formProps}>
            <FormContent modal>
              <MenuSettingsHeader>
                <MenuSettingsTitle>KDS settings</MenuSettingsTitle>
                <ButtonSectionController {...props.buttonSection}>
                  {({ props, hasChanges }) => (
                    <MenuSettingsButtonsWrapper>
                      <Button
                        id="menuSettingsSave"
                        className="action"
                        type="primary"
                        htmlType="submit"
                        {...props.save}
                      >
                        {hasChanges ? "Save" : "Saved"}
                      </Button>
                      {hasChanges && (
                        <Popconfirm
                          title="Cancel edit"
                          description={
                            <>
                              <p>
                                Possible edits will be lost.
                                <br />
                                Are you sure you want to cancel?
                              </p>
                            </>
                          }
                          okText="Yes"
                          cancelText="No"
                          {...props.popConfirm}
                        >
                          <Button id="menuSettingsCancel" className="action">
                            Cancel
                          </Button>
                        </Popconfirm>
                      )}
                      {!hasChanges && (
                        <Button
                          id="menuSettingsClose"
                          {...props.close}
                          className="action"
                        >
                          Close
                        </Button>
                      )}
                    </MenuSettingsButtonsWrapper>
                  )}
                </ButtonSectionController>
              </MenuSettingsHeader>
              <div className="flex flex-col gap-4">
                <MenuSettingsInfoChoices>
                  <Select
                    {...props.timeSettings}
                    label="Time estimate"
                    name="time_settings"
                    getPopupContainer={() =>
                      document.getElementById("modalInFullScreen")
                    }
                  />
                </MenuSettingsInfoChoices>
                <hr className="w-full border-0 border-t border-grayScale900-8 mb-0" />
                <div className="flex flex-col gap-2">
                  <p className="text-xs font-semibold text-grayScale900-56 leading-180">
                    Auto-accept orders:
                  </p>
                  <Switch
                    name="auto_accept"
                    checkedChildren="Enabled"
                    unCheckedChildren="Disabled"
                    labelLeft
                    noLabelHeight
                  />
                  <AutoAcceptController>
                    {({ autoAccept }) => (
                      <>
                        {autoAccept && (
                          <p className="text-xs font-semibold text-grayScale900-56 leading-180">
                            All orders will be accepted automatically
                          </p>
                        )}
                        {!autoAccept && (
                          <p className="text-xs font-semibold text-grayScale900-56 leading-180">
                            Orders have to be accepted manually
                          </p>
                        )}
                      </>
                    )}
                  </AutoAcceptController>
                </div>
                <hr className="w-full border-0 border-t border-grayScale900-8 mb-0" />
                <div className="flex flex-col gap-2">
                  <p className="text-xs font-semibold text-grayScale900-56 leading-180">
                    Add print button:
                  </p>
                  <Switch
                    name="print_orders"
                    checkedChildren="Enabled"
                    unCheckedChildren="Disabled"
                    labelLeft
                    noLabelHeight
                  />
                </div>
              </div>
            </FormContent>
          </Form>
        )}
      </FormController>
    )}
  </ModalController>
);

export default MenuSettings;

import { useTable } from '@refinedev/antd';
import { useLogout } from '@refinedev/core';
import { Table, Button } from 'antd';

const Admin = () => {
  const {
    tableProps,
  } = useTable({ resource: 'restaurants' });
  const { mutate: onLogout } = useLogout();
  return (
    <div>
      Admin <Button onClick={onLogout}>Log ud</Button>
      <div>
        <h1>Restauranter</h1>
        <Table {...tableProps}>
          <Table.Column title="Name" dataIndex="name" key="name" />
          <Table.Column title="QR-Scans" dataIndex="sessions" key="sessions" />
          <Table.Column title="Orders" dataIndex="orders" key="orders" />
          <Table.Column title="Order total" dataIndex="order_total" key="order_total" />
          <Table.Column title="Created" dataIndex="created_at" key="created_at" />
        </Table>
      </div>
    </div>
  );
};

export default Admin;

import { Form } from 'antd';
import { useGo } from '@refinedev/core';
import useHasChanges from "./useHasChanges";
import useRequiredFieldsFilled from "./useRequiredFieldsFilled";


export default function ButtonSectionController({
  initialValues,
  edit,
  children,
}) {
  const go = useGo();
  const values = {
    active: Form.useWatch(['active']),
    name: Form.useWatch(['name']),
    price: Form.useWatch(['price']),
    description: Form.useWatch(['description']),
    sections: Form.useWatch(['sections']),
    category_id: Form.useWatch(['category_id']),
    sub_category_id: Form.useWatch(['sub_category_id']),
    image: Form.useWatch(['image']),
  };
  console.log('What is they?', initialValues, values);
  const hasChanges = useHasChanges({ initialValues, values });
  const requiredFieldsFilled = useRequiredFieldsFilled(values);
  return children({
    props: {
      save: {
        disabled: !hasChanges || !requiredFieldsFilled,
      },
      saveTooltip: {
        title: ((!edit || hasChanges) && !requiredFieldsFilled) ? 'Please fill out all required fields' : null,
      },
      popConfirm: {
        onConfirm: () => go({ to: '..' }),
      },
      close: {
        onClick: !hasChanges ? () => go({ to: '..' }) : null,
      },
    },
    edit,
    hasChanges,
  });
}
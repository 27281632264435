import styled from "styled-components";

export const colors = {
  yumyGreen: "#118376",
  yumyGreenHover: "#298f80",
  yumyGreen10: "#E7FFFC",
  yumyGreen600: "#095C53",
  yumyWhite: "#FFFFFF",
  yumyLight: "#F5F5F5",
  yumyBlack: "#121127",
  yumyRed: "#EF4444",

  gray: "#F5F5F5",
  grayScale900_72: "rgba(18, 17, 39, 0.72)",
  grayScale900_56: "rgba(18, 17, 39, 0.56)",
  grayScale900_48: "rgba(18, 17, 39, 0.48)",
  grayScale900_40: "rgba(18, 17, 39, 0.4)",
  grayScale900_32: "rgba(18, 17, 39, 0.32)",
  grayScale900_30: "rgba(18, 17, 39, 0.3)",
  grayScale900_12: "rgba(18, 17, 39, 0.12)",
  grayScale900_8: "rgba(18, 17, 39, 0.08)",
  grayScale900_4: "rgba(18, 17, 39, 0.04)",
  grayScale900_2: "rgba(18, 17, 39, 0.02)",

  white56: "rgba(255, 255, 255, 0.56)",
  white40: "rgba(255, 255, 255, 0.4)",
  white24: "rgba(255, 255, 255, 0.24)",
  white12: "rgba(255, 255, 255, 0.12)",
  white8: "rgba(255, 255, 255, 0.08)",
  white4: "rgba(255, 255, 255, 0.04)",

  green10: "rgba(17, 131, 118, 0.10)",

  yumyGreens100: "#E7F3F1",
  yumyGreens200: "#F3FDFC",
};

/*
------------------------------------------------------
  Global
------------------------------------------------------
*/
export const Page = styled.div`
  display: flex;
  flex-direction: column;

  ${({ modal }) =>
    modal
      ? `
  `
      : `
    min-height: 100dvh;
    justify-content: space-between;
  `}

  a {
    color: ${colors.yumyGreen};
    text-decoration: underline;

    transition: all 300ms ease-in-out;
    &:hover {
      color: ${colors.yumyGreen};
      text-decoration: none;
    }
  }

  p {
    margin: 0;
  }

  button:not(.ant-switch) {
    box-shadow: 0 0 0 0 transparent !important;
    border-radius: 999px;
    padding: 6px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: auto;

    &.large {
      height: 50px;
      font-weight: 600;
    }

    &.small {
      font-size: 14px;
      font-weight: 500;
    }

    &.action {
      width: 90px;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: ${colors.yumyGreen};
      color: ${colors.yumyWhite};
      opacity: 0.5;
    }
  }
`;

/*
------------------------------------------------------
  Layout
------------------------------------------------------
*/
export const YumyLayout = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  height: 100dvh;
  width: 100%;
  position: relative;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
export const YumySider = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 50px;
  color: ${colors.yumyWhite};
  background-color: ${colors.yumyBlack};
  width: 100%;

  /* Scrollbar */
  &::-webkit-scrollbar-thumb {
    background-color: var(--yumyGreen);

    &:hover {
      background-color: var(--yumyGreen600);
      cursor: default;
    }
  }
  /* width */
  &::-webkit-scrollbar {
    width: 10px;

    @media (max-width: 575px) {
      width: 5px;
    }
  }
  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  @media (max-width: 767px) {
    position: fixed;
    height: 100%;
    scrollbar-gutter: stable;
    overflow-y: auto;
    width: 200px;
    z-index: 800;
    transition: transform 300ms ease-in-out;
    transform: translateX(-100%);

    ${({ menuOpen }) =>
      menuOpen &&
      `
      transform: translateX(0);
    `}
  }
`;
export const YumySiderDropdown = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 16px;
`;
export const YumySiderToggle = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: flex;
    position: fixed;
    left: 0;
    top: 50%;
    padding: 24px 7px 24px 3px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    color: ${colors.yumyWhite};
    background-color: ${colors.yumyGreen};
    z-index: 799;
    cursor: pointer;
    transform: translateX(0) translateY(-50%);
    transition: all 300ms ease-in-out;

    &:hover {
      color: ${colors.white56};
    }

    ${({ menuOpen }) =>
      menuOpen &&
      `
      transform: translateX(200px) translateY(-50%);
    `}
  }
`;
export const YumyOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.yumyBlack};
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.2s, opacity 0.2s linear;
  z-index: 798;

  ${({ menuOpen }) =>
    menuOpen &&
    `
    @media (max-width: 767px) {
      opacity: 0.5;
      visibility: visible;
      transition: opacity 0.2s linear;
    }
  `}
`;

export const YumySiderOnboarding = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 16px;
`;
export const YumySiderOnboardingText = styled.p`
  font-size: 12px;
  margin: 0;
`;

export const YumyHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    & > div {
      width: 100%;
    }
  }
`;

/*
------------------------------------------------------
  Content
------------------------------------------------------
*/

export const Logo = styled.div`
  display: flex;
  margin: 30px 0 58px 16px;
`;

export const OnboardingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 80px;
  transition: margin-top 300ms ease-in-out;

  @media (max-height: 991px) {
    margin-top: 30px;
  }

  @media (max-width: 767px) {
    margin-top: 0px;
  }
`;

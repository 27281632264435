import { useGetIdentity } from "@refinedev/core";
import { useEffect } from 'react';
import { useList, useGo } from '@refinedev/core';


const IndexRedirecter = () => {
  const identity = useGetIdentity();
  console.log('What is the identity?', identity.data?.id);
  const res = useList({
    resource: 'users/restaurant-users',
    id: identity.data?.id,
    meta: {
      userId: identity.data?.id,
    },
    queryOptions: {
      cacheTime: 0,
      enabled: Boolean(identity.data?.id),
    },
  });
  const firstRestaurantId = res.data?.data[0]?.restaurant_id;
  const go = useGo();
  useEffect(() => {
    if (!identity.data) return;
    if (identity.data?.is_admin) {
      go({ to: '/admin' });
    }
    else if (identity.data?.activePlan === 'NONE') {
      go({ to: '/onboarding/create-plan' });
    } else if (res.data?.data) {
      const resData = res.data.data;
      if (resData.length === 0) {
        go({ to: '/onboarding/create-restaurant' });
      } else {
        const restaurant = resData[0];
        go({ to: `/onboarding/restaurant/${restaurant.restaurant_id}/menu` });
      }
    }
  }, [identity.data?.activeplan, firstRestaurantId, res.isLoading]);
  return null;
};

export default IndexRedirecter;
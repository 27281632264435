import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGo } from '@refinedev/core';
import { useOne } from '../../lib/refine';
import { OnboardingContent } from '../../components/Layout/template';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import env from '../../env';

const stripePromise = loadStripe(env.stripeKey);

export default function AddSubscription() {
  const { planId } = useParams();
  const { data: plan, isLoading } = useOne({ resource: 'users/plans', id: planId });
  const options = useMemo(
    () => {
      return {
        fetchClientSecret: () => new Promise((resolve) => {
          resolve(plan.data.first_checkout_session.client_secret);
        }),
        onComplete: () => {
          go({ to: `/onboarding/add-subscription-completed/${plan.data.id}` });
        },
      };
    },
    [plan],
  );
  const go = useGo();
  return (
    <OnboardingContent>
      {isLoading && <div>loading checkout...</div>}
      {!isLoading && (
        <div>
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={options}>
            <EmbeddedCheckout
            />
          </EmbeddedCheckoutProvider>
        </div>
      )}
    </OnboardingContent>
  );
}


import { DataProvider } from "@refinedev/core";
import {
  axiosInstance,
  generateSort,
  generateFilter,
  getResourceUrl,
} from "./utils";
import { AxiosInstance } from "axios";
import { stringify } from "query-string";


export const dataProvider = (
  apiUrl,
  httpClient = axiosInstance
) => ({
  getList: async ({ resource, pagination, filters, sorters, meta }) => {
    const url = getResourceUrl(apiUrl, resource, 'getList', meta);

    const { current = 1, pageSize = 10, mode = "server" } = pagination ?? {};

    const { headers: headersFromMeta, method } = meta ?? {};
    const requestMethod = method ?? "get";

    const queryFilters = generateFilter(filters);

    const query = {};

    if (mode === "server") {
      query["page[number]"] = current;
      query["page[size]"] = pageSize;
    }

    const generatedSort = generateSort(sorters);
    if (generatedSort) {
      query.sort = generatedSort.sort;
    }

    const { data } = await httpClient[requestMethod](
      `${url}?${stringify(query)}&${stringify(queryFilters)}`,
      {
        headers: headersFromMeta,
      }
    );
    console.log('What is the data?', data, data.meta);
    const total = data.meta ? data.meta.total : data.data.length;
    return {
      data: data.data,
      total,
    };
  },

  getMany: async ({ resource, ids, meta }) => {
    const { headers, method } = meta ?? {};
    const requestMethod = method ?? "get";
    const { data } = await httpClient[requestMethod](
      `${apiUrl}/${resource}?${stringify({ id: ids })}`,
      { headers }
    );

    return {
      data: data.data,
    };
  },

  create: async ({ resource, variables, meta }) => {
    const url = getResourceUrl(apiUrl, resource, 'create', meta, undefined);

    const {
      headers,
      method,
    } = meta ?? {};
    const requestMethod = method ?? "post";

    const { data } = await httpClient[requestMethod](url, variables, {
      headers,
    });

    return {
      data: data.data,
    };
  },

  update: async ({ resource, id, variables, meta }) => {
    const url = getResourceUrl(apiUrl, resource, 'update', meta, id);

    const { headers, method } = meta ?? {};

    const requestMethod = method ?? "patch";

    const { data } = await httpClient[requestMethod](url, variables, {
      headers,
    });

    return {
      data: data.data,
    };
  },

  getOne: async ({ resource, id, meta }) => {
    const url = getResourceUrl(apiUrl, resource, 'update', meta, id);
    const { headers, method } = meta ?? {};
    const requestMethod = method ?? "get";

    const { data } = await httpClient[requestMethod](url, { headers });
    return {
      data: data.data,
    };
  },

  deleteOne: async ({ resource, id, variables, meta }) => {
    const url = getResourceUrl(apiUrl, resource, 'deleteOne', meta, id);
    const { headers, method } = meta ?? {};
    const requestMethod = method ?? "delete";

    const { data } = await httpClient[requestMethod](url, {
      data: variables,
      headers,
    });

    return {
      data: data.data,
    };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({
    url,
    method,
    filters,
    sorters,
    payload,
    query,
    headers,
  }) => {
    let requestUrl = `${url}?`;

    if (sorters) {
      const generatedSort = generateSort(sorters);
      if (generatedSort) {
        requestUrl = `${requestUrl}&${stringify(generatedSort)}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers,
      };
    }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload);
        break;
      case "delete":
        axiosResponse = await httpClient.delete(url, {
          data: payload,
        });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});

import deepEqual from "deep-equal";


const sortOutUndefined = obj => {
  if (typeof obj !== 'object') {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(sortOutUndefined);
  }
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] === undefined) {
      return acc;
    }
    return {
      ...acc,
      [key]: sortOutUndefined(obj[key]),
    };
  }, {});

}
export default function useHasChanges({ initialValues, values }) {
  if (!values) {
    return false;
  }
  console.log('What are they', initialValues, values);
  return !deepEqual(initialValues, sortOutUndefined(values), { strict: false });
};

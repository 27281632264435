import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const format = 'HH:mm';
const AddTimeValueController = ({
  add,
  children,
}) => {
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const onChangeFrom = (time, timeString) => {
    setFrom(timeString);
  };
  const onChangeTo = (time, timeString) => {
    setTo(timeString);
  };
  useEffect(() => {
    if (from && to) {
      add({ from, to });
      setFrom(null);
      setTo(null);
    }
  }, [from, to, add]);
  return children({
    props: {
      from: {
        value: from && dayjs(from, format),
        onChange: onChangeFrom,
        format,
      },
      to: {
        value: to && dayjs(to, format),
        onChange: onChangeTo,
        format,
      },
    },
  });
};

export default AddTimeValueController;

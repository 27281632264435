import { Form } from 'antd';
import { useGo } from '@refinedev/core';
import useHasChanges from "./useHasChanges";
import useRequiredFieldsFilled from "./useRequiredFieldsFilled";


export default function ButtonSectionController({
  initialValues,
  children,
  create,
  closeModal,
}) {
  const go = useGo();
  const values = {
    name: Form.useWatch(['name']),
    where: Form.useWatch(['where']),
    settings: Form.useWatch(['settings']),
    enabled: Form.useWatch(['enabled']),
    bag_price: Form.useWatch(['bag_price']),
 //   order_extra_minutes: Form.useWatch(['order_extra_minutes']),
  };
  console.log('What is the stuff?', values);
  console.log('What is the stuffa', values, initialValues);
  const hasChanges = useHasChanges({ initialValues, values });
  const requiredFieldsFilled = useRequiredFieldsFilled(values);
  const onActualClose = () => {
    console.log('Actually close');
    if (create) {
      closeModal();
    } else {
      go({ to: '..' });
    }
  };
  return children({
    props: {
      save: {
        disabled: !hasChanges || !requiredFieldsFilled,
      },
      popConfirm: {
        onConfirm: onActualClose,
      },
      close: {
        onClick: onActualClose,
      },
    },
    hasChanges,
  });
}
import { useState } from "react";
import { useGo, useGetIdentity } from "@refinedev/core";
import { useCreate } from "../../lib/refine";
import { OnboardingContent } from "../../components/Layout/template";

export default function CreateRestaurant() {
  const [isLoading, setIsLoading] = useState(false);
  const identity = useGetIdentity().data;
  const go = useGo();
  const { mutate } = useCreate();
  /*
  const onClickFreePlan = () => {
    setIsLoading(true);
    mutate({
      resource: "users/plans",
      id: identity?.id,
      meta: {
        userId: identity?.id,
      },
      values: {
        type: "FREE",
      },
      onMutationSuccess: () => {
        go({ to: "/onboarding/create-restaurant" });
        setIsLoading(false);
      },
      onMutationError: () => {
        setIsLoading(false);
      },
      errorNotification: "Could not create plan",
    });
  };
  */
  const onClickPremiumPlan = () => {
    setIsLoading(true);
    mutate({
      resource: "users/plans",
      id: identity?.id,
      meta: {
        userId: identity?.id,
      },
      values: {
        type: "PREMIUM",
      },
      onMutationSuccess: (data) => {
        go({ to: `/onboarding/add-subscription/${data.data.id}` });
        setIsLoading(false);
      },
      onMutationError: () => {
        setIsLoading(false);
      },
      errorNotification: "Could not create plan",
    });
  };
  return (
    <OnboardingContent>
      <div className="flex max-w-xl flex-col gap-4 text-center">
        <p className="text-sm font-bold uppercase text-yumyGreen">Pricing</p>
        <h1 className="mb-2">Pricing Plans</h1>
        {/*
        <p className="text-base text-grayScale900-48">
          Sign up now and enjoy your free QR menu. Experience the benefits
          firsthand, and see why Yumy is the right choice for you.
        </p>
        */}
      </div>
      {isLoading && (
        <div className="fixed top-1/2 flex -translate-y-1/2 items-center justify-center rounded-xl bg-yumyGreen px-8 py-4 text-sm font-semibold text-white-100 shadow-md">
          Creating plan...
        </div>
      )}
      <div className="mt-4 flex w-full max-w-96 flex-col items-start justify-start gap-4 rounded-xl bg-yumyBlack px-6 py-10 text-left text-white-100">
        <p className="text-xl font-extrabold text-yumyGreen">Yumy Premium</p>
        <p className="text-base leading-180 text-white-48">
          Unlock your restaurant's full potential with Yumy Premium - your
          all-in-one solution for success.
        </p>
        <p>
          <span className="text-4xl font-extrabold leading-140">
            kr. 399,00
          </span>
          <span className="ml-4 text-base font-extrabold leading-140">/mo</span>
        </p>
        <button
          className="flex min-h-10 w-full items-center justify-center rounded-full bg-yumyGreen400 px-6 py-2 text-sm font-medium transition-all duration-200 hover:bg-yumyGreenHover active:scale-[.98] active:opacity-90"
          onClick={onClickPremiumPlan}
        >
          Let's go!
        </button>
        <hr />
      </div>
      {/* <button onClick={onClickFreePlan}>Free plan</button> */}
    </OnboardingContent>
  );
}

import { useState, useMemo } from 'react';
import { useQRCodes } from './QRCodesContext';


const deleteFalseValues = (obj) => {
  if (!obj){ 
    return {};
  }
  Object.keys(obj).forEach((key) => {
    if (obj[key] === false) {
      delete obj[key];
    }
  });
  return obj;
};
const QRCodesController = ({
  activeMenuId,
  children,
}) => {
  const {
    qrCodes,
  } = useQRCodes();
  const menuQRCodes = useMemo(
    () => qrCodes.filter(({ menu_id }) => activeMenuId === menu_id || !activeMenuId),
    [activeMenuId, qrCodes],
  );
  const [selectedQRCodes, setSelectedQRCodes] = useState({});
  const onToggleQRCode = (qrCodeId) => {
    setSelectedQRCodes(deleteFalseValues({
      ...selectedQRCodes,
      [qrCodeId]: !selectedQRCodes[qrCodeId],
    }));
  };
  const allChecked = menuQRCodes.length === Object.keys(selectedQRCodes).length;
  const someChecked = Object.keys(selectedQRCodes).length > 0;
  const onToggleAll = () => {
    if (allChecked) {
      setSelectedQRCodes({});
    } else {
      setSelectedQRCodes(
        menuQRCodes.reduce(
          (acc, qrCode) => ({
            ...acc,
            [qrCode.id]: true,
          }),
          {},
        ),
      );
    }
  };
  const selectedQRCodesArray = useMemo(() => Object.keys(selectedQRCodes), [selectedQRCodes]);
  const showMenu = !activeMenuId;
  return children({
    props: {
      allCheckbox: {
        checked: allChecked,
        indeterminate: someChecked && !allChecked,
        onChange: onToggleAll,
      },
      dropdown: {
        selectedQRCodes: selectedQRCodesArray,
      },
      qrCodeRow: {
        onToggleQRCode,
        selectedQRCodes,
        qrCodes: menuQRCodes,
      },
    },
    showMenu,
    selectedQRCodes: selectedQRCodesArray,
  });
};

export default QRCodesController;

import { useForm } from "@refinedev/antd";
import { useGo } from "@refinedev/core";
import { Button } from "antd";
import Form from "../../lib/Form";
import { Input } from "../../components/Inputs";
import { Body, Overline } from "../../components/Layout/typography";
import { FormContent } from "../../components/Layout/forms";
import { OnboardingContent } from "../../components/Layout/template";

export default function CreateRestaurant() {
  const go = useGo();
  const { formProps } = useForm({
    resource: "restaurants",
    action: "create",
    onMutationSuccess: ({ data: { id } }) => {
      go({ to: `/onboarding/restaurant/${id}/menu/create` });
    },
  });
  return (
    <OnboardingContent>
      <div>
        <Overline lg>Welcome to yumy</Overline>
        <h1>Add your first restaurant</h1>
      </div>
      <Body md gray48>
        Have more restaurants? No problem you can always upgrade to Yumy Premium
        later
      </Body>
      <FormContent onboarding>
        <Form {...formProps} initialValues={{ name: "" }}>
          <Input label="Restaurant name*" name="name" />
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="large"
          >
            Add Restaurant
          </Button>
        </Form>
      </FormContent>
    </OnboardingContent>
  );
}

import { useEffect, useRef } from 'react';
import { Form } from 'antd';


const dayArray = [
  { dayName: 'MONDAY', text: 'Monday' },
  { dayName: 'TUESDAY', text: 'Tuesday' },
  { dayName: 'WEDNESDAY', text: 'Wednesday' },
  { dayName: 'THURSDAY', text: 'Thursday' },
  { dayName: 'FRIDAY', text: 'Friday' },
  { dayName: 'SATURDAY', text: 'Saturday' },
  { dayName: 'SUNDAY', text: 'Sunday' },
];

const OrderingController = ({ children, form }) => {
  const formInstance = Form.useFormInstance();
  const settingsType = Form.useWatch(['settings', 'type']);
  const enabled = Form.useWatch(['enabled']);
  const firstRender = useRef(true);
  const currentSettingsType = useRef(settingsType);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (!currentSettingsType.current) {
      currentSettingsType.current = settingsType;
    }
    if (settingsType === 'EVERYDAY' && currentSettingsType.current !== 'EVERYDAY') {
      console.log('EVERYDAY');
      formInstance.setFieldsValue({
        settings: {
          from: '10:00',
          to: '22:00',
        },
      });
      formInstance.resetFields([['settings', 'times']]);
    } else if (settingsType === 'DAILY' && currentSettingsType.current !== 'DAILY'){
      console.log('DAILY');
      formInstance.setFieldsValue({
        settings: {
          times: dayArray.reduce(
            (acc, { dayName }) => ({
              ...acc,
              [dayName]: [],
            }),
            {},
          ),
        },
      });
      formInstance.resetFields([['settings', 'from'], ['settings', 'to']]);
    }
  }, [settingsType, formInstance]);
  return (
    children({ settingsType, dayArray, enabled })
  );
};

export default OrderingController;

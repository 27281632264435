import { Tooltip } from 'antd';
import { useGo } from '@refinedev/core';
import { useUpdate, useDelete, useCreate } from '../../../../lib/refine';

const ProductDropdownController = ({
  children,
  product,
  disableLabel,
  duplicateLabel,
  enableLabel,
  deleteLabel,
  disableNotification,
  enableNotification,
  deleteNotification,
  duplicateNotification,
  editLabel,
  anySelected,
  disabledTooltip,
}) => {
  const go = useGo();
  const items = [{
    key: '1',
    type: 'group',
    label: 'More actions',
    children: [
        { key: 'EDIT', label: editLabel },
        { key: 'DUPLICATE', label: duplicateLabel },
        ...([product.active ? (
            { key: 'DISABLE', label: disableLabel }
        ) : (
            { key: 'ENABLE', label: enableLabel }
        )]),
        { type: 'divider' },
        { key: 'DELETE', label: deleteLabel, danger: true}
    ]
  }];
  const { mutate: onUpdate } = useUpdate();
  const { mutate: onDelete } = useDelete();
  const { mutate: onCreate } = useCreate();
  const onClick = ({ key }) => {
      switch (key) {
        case 'EDIT':
            go({ to: `edit-product/${product.id}` });
            break;
          case 'DUPLICATE':
              const { id, ...restProduct } = product;
              onCreate({
                  resource: 'restaurants/menus/products',
                  values: {
                      ...restProduct,
                      name: `${restProduct.name} (copy)`,
                      order: product.order + 1,
                  },
                  successNotification: duplicateNotification,
              });
              break;
          case 'ENABLE':
              onUpdate({
                  resource: 'restaurants/menus/products',
                  id: product.id,
                  values: {
                      active: true,
                  },
                  successNotification: enableNotification,
              });
              break;
          case 'DISABLE':
              onUpdate({
                  resource: 'restaurants/menus/products',
                  id: product.id,
                  values: {
                      active: false,
                  },
                  successNotification: disableNotification,
              });
              break;
          case 'DELETE':
              onDelete({
                  resource: 'restaurants/menus/products',
                  id: product.id,
                  successNotification: deleteNotification,
              });
              break;
          default:
      }
  };
  return (
    <Tooltip title={anySelected ? disabledTooltip : null}>
      {children({ dropdownProps: { menu: { items, onClick }, trigger: ['click'], disabled: anySelected } })}
    </Tooltip>
  );
};

export default ProductDropdownController;

import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useGo, useGetIdentity } from '@refinedev/core';
import MenuContext from './MenuContext';
import { useList, useSelect } from '../../../../lib/refine';
import { useLayout } from '../../../../layouts/Restaurant/LayoutContext';

const sorters = [{
  field: 'order',
  order: 'asc',
}];

const emptyArray = [];
const emptyObject = {};
const ProductsPageController = ({
  children,
}) => {
  const { restaurant, currentMenu } = useLayout();
  const { menuId } = useParams();
  const go = useGo();
  const addMenu = {
    items: [{
      key: '1',
      type: 'group',
      label: 'Add new',
      children: [
        {
          key: '1',
          label: 'Product',
          onClick: () => {
            go({ to: `add-product` });
          }
        },
        {
          key: '2',
          label: 'Category',
          onClick: () => {
            go({ to: `categories` });
          }
        },
      ]
    }],
  };
  const identity = useGetIdentity();
  const { data: restaurantUsersData, isLoading: resturantUsersIsLoading } = useList({
    resource: 'users/restaurant-users',
    queryOptions: {
      enabled: Boolean(identity.data?.id),
    },
    meta: {
      userId: identity.data?.id,
    },
    suspenseKey: 'RESTAURANT_USERS',
  });
  const { data, isLoading: productsIsLoading } = useList({ resource: 'restaurants/menus/products', id: menuId, hasPagination: false, suspenseKey: 'PRODUCTS', sorters });
  console.log('What is products?', data?.data);
  const { data: categoriesData, isLoading: categoriesIsLoading } = useList({ resource: 'restaurants/menus/categories', id: menuId, hasPagination: false, suspenseKey: 'PRODUCTS_CATEGORIES', sorters });
  const categorySelectProps = useSelect({
    resource: 'restaurants/menus/categories',
    id: menuId,
    optionLabel: 'name',
    suspenseKey: 'PRODUCTS_CATEGORIES',
  });
  const { data: subCategoriesData, isLoading: subCategoriesIsLoading } = useList({ resource: 'restaurants/menus/sub-categories', id: menuId, hasPagination: false, suspenseKey: 'PRODUCTS_SUB_CATEGORIES', sorters });
  
  const fullyLoaded = !(productsIsLoading || categoriesIsLoading || subCategoriesIsLoading || resturantUsersIsLoading);	
  const products = (fullyLoaded && data?.data) || emptyArray;
  const subCategories = (fullyLoaded && subCategoriesData?.data) || emptyArray;
  const categories = (fullyLoaded && categoriesData?.data) || emptyArray;
  const restaurantUser = restaurantUsersData?.data?.find((user) => user.restaurant_id === restaurant.id) || emptyObject;
  const subCategoriesByCategory = useMemo(
    () => {
      const subCategoriesByCategory = {};
      subCategories.forEach((subCategory) => {
        if (!subCategoriesByCategory[subCategory.category_id]) {
          subCategoriesByCategory[subCategory.category_id] = [];
        }
        subCategoriesByCategory[subCategory.category_id].push(subCategory);
      });
      return subCategoriesByCategory;
    },
    [subCategories],
  );
  const productsBySubCategory = useMemo(
    () => {
      const productsBySubCategory = {};
      categories.forEach(
        (category) => {
          productsBySubCategory[category.id] = { '-1': [] };
        },
      );
      subCategories.forEach((subCategory) => {
        if (productsBySubCategory[subCategory.category_id]) {
          productsBySubCategory[subCategory.category_id][subCategory.id] = [];
        }
      });
      products.forEach((product) => {
        if (productsBySubCategory[product.category_id]) {
          if (product.sub_category_id && productsBySubCategory[product.category_id][product.sub_category_id]) {
            productsBySubCategory[product.category_id][product.sub_category_id].push(product);
          } else if (productsBySubCategory[product.category_id][-1]) {
            productsBySubCategory[product.category_id][-1].push(product);
          }
        }
      });
      return productsBySubCategory;
    },
    [products, subCategories, categories],
  );
  return (
    <MenuContext.Provider
      value={{
        categorySelectProps,
        productsBySubCategory,
        subCategories,
        categories,
        fullyLoaded,
        subCategoriesByCategory,
        products,
        restaurantUser,
      }}
    >
      {children({
        restaurant,
        menu: currentMenu,
        addMenu,
        goToSettings: () => go({ to: `./settings` }),
      })}
    </MenuContext.Provider>
  );
};

export default ProductsPageController;

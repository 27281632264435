import Collapser from '../../../../components/Collapser';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Divider, Flex } from 'antd';
import SubCategory from './SubCategory';
import AddSubCategory from './AddSubCategory';
import { ModalOptions, ModalOptionsTitle } from '../../../../components/Layout/modal';

const SubCategoriesList = ({
  categoryId,
  fields,
  add,
  remove,
  move,
  editingCategory,
  showAllSubCategories,
  form,
  specialId,
  categoryFormName,
}) => {
  const onDragEnd = (result) => {
    if (!result) {
      return;
    }
    move(result.source.index, result.destination.index);
  };
  return (
    <Collapser open={editingCategory || showAllSubCategories}>
      <ModalOptions>
        <ModalOptionsTitle>Subcategories</ModalOptionsTitle>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`subCategories_${specialId}`}>
            {({ droppableProps, innerRef, placeholder }) => (
              <Flex vertical ref={innerRef} {...droppableProps}>
                {fields.map(
                  ({ key, name }) => (
                    <Draggable key={key} draggableId={`${key}`} index={name}>
                      {({ draggableProps, dragHandleProps, innerRef }, { isDragging }) => (
                        <SubCategory
                          key={key}
                          name={name}
                          add={add}
                          remove={remove}
                          form={form}
                          onRemove={() => remove(name)}
                          draggableProps={draggableProps}
                          dragHandleProps={dragHandleProps}
                          innerRef={innerRef}
                          isDragging={isDragging}
                          categoryFormName={categoryFormName}
                        />
                      )}
                    </Draggable>
                  )
                )}
                {placeholder}
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
        <AddSubCategory add={add} categoryId={categoryId} />
        <Divider />
      </ModalOptions>
    </Collapser>
  );
};

export default SubCategoriesList;

import { Tooltip } from 'antd';
import { useUpdate, useDelete, useCreate } from '../../../../lib/refine';
import env from '../../../../env';

const QRCodeDropdownController = ({
  children,
  qrCode,
  disableLabel,
  duplicateLabel,
  enableLabel,
  deleteLabel,
  disableNotification,
  enableNotification,
  deleteNotification,
  duplicateNotification,
  anySelected,
  disabledTooltip,
}) => {
  const items = [{
    key: '1',
    type: 'group',
    label: 'More actions',
    children: [
        { key: 'DUPLICATE', label: duplicateLabel },
        ...([qrCode.active ? (
            { key: 'DISABLE', label: disableLabel }
        ) : (
            { key: 'ENABLE', label: enableLabel }
        )]),
        { type: 'divider' },
        { key: 'DELETE', label: deleteLabel, danger: true},
        ...(env.appEnv === 'local' ? [{ type: 'divider' }, { key: 'COPY_URL', label: 'Copy URL(dev)' }] : [])
    ]
  }];
  const { mutate: onUpdate } = useUpdate();
  const { mutate: onDelete } = useDelete();
  const { mutate: onCreate } = useCreate();
  const onClick = ({ key }) => {
      switch (key) {
        case 'COPY_URL':
          window.navigator.clipboard.writeText(qrCode.url);
          break;
          case 'DUPLICATE':
              const { id, ...restQRCode } = qrCode;
              onCreate({
                  resource: 'restaurants/qr-codes',
                  values: {
                      ...restQRCode,
                      name: `${restQRCode.name} (copy)`,
                      order: qrCode.order + 1,
                  },
                  successNotification: duplicateNotification,
              });
              break;
          case 'ENABLE':
              onUpdate({
                  resource: 'restaurants/qr-codes',
                  id: qrCode.id,
                  values: {
                      active: true,
                  },
                  successNotification: enableNotification,
              });
              break;
          case 'DISABLE':
              onUpdate({
                  resource: 'restaurants/qr-codes',
                  id: qrCode.id,
                  values: {
                      active: false,
                  },
                  successNotification: disableNotification,
              });
              break;
          case 'DELETE':
              onDelete({
                  resource: 'restaurants/qr-codes',
                  id: qrCode.id,
                  successNotification: deleteNotification,
              });
              break;
          default:
      }
  };
  return (
    <Tooltip title={anySelected ? disabledTooltip : null}>
      {children({ dropdownProps: { menu: { items, onClick }, trigger: ['click'], disabled: anySelected } })}
    </Tooltip>
  );
};

export default QRCodeDropdownController;

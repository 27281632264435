import { useLayout } from "./LayoutContext";
import { useModal } from "@refinedev/antd";
import { YumyIconRestaurant } from "../../components/YumyIcons";
import {
  DropdownButton,
  DropdownButtonInner,
  DropdownButtonText,
  Separator,
} from "./DropDownStyles";
import Modal from "../../lib/Modal";
import RestaurantSettings from "../../pages/restaurant/settings";
const RestaurantDropdown = () => {
  const { restaurant } = useLayout();

  const { modalProps, show, close } = useModal();
  return (
    <div>
      <DropdownButton onClick={show}>
        <DropdownButtonInner>
          <YumyIconRestaurant />
          <DropdownButtonText>{restaurant.name}</DropdownButtonText>
        </DropdownButtonInner>
      </DropdownButton>
      <Separator />
      <Modal
        {...modalProps}
        maskClosable={false}
        closeIcon={false}
        footer={null}
        width={940}
      >
        <RestaurantSettings closeModal={close} />
      </Modal>
    </div>
  );
};

export default RestaurantDropdown;

import { useParams } from 'react-router-dom';
import LayoutContext from './LayoutContext';
import { useGetIdentity } from '@refinedev/core';
import { useList, useOne } from '../../lib/refine';

const emptyObject = {};
const emptyArray = [];
const RestaurantController = ({
  children,
}) => {
  const { menuId, restaurantId } = useParams();
  const { data: restaurantData, isLoading: isLoadingRestaurant } = useOne({ resource: 'restaurants', id: restaurantId, suspenseKey: 'PRODUCTS_RESTAURANT'});
  const { data: menus, isLoading: isLoadingMenus } = useList({
    resource: 'restaurants/menus', 
    id: restaurantId, 
    suspenseKey: 'MENU_DROPDOWN', 
    pagination: false,
    hasPagination: false,
  });
  
  const identity = useGetIdentity();
  const { data: restaurantUsers, isLoading: isLoadingRestaurantUsers } = useList({
    resource: 'users/restaurant-users',
    meta: {
      userId: identity.data?.id,
    },
    suspenseKey: 'RESTAURANT_USERS',
  });
  
  // eslint-disable-next-line eqeqeq
  const restaurant = restaurantData?.data || emptyObject;
  const dataMenus = menus?.data || emptyArray;
  const dataMenu = dataMenus.find((menu) => `${menu.id}` === `${menuId}`);
  const dataRestaurantUsers = restaurantUsers?.data || emptyArray;
  const menu = dataMenu;
  const fullyLoaded = !isLoadingRestaurant && !isLoadingMenus && !isLoadingRestaurantUsers;
  return (
    <LayoutContext.Provider
      value={{
        restaurant,
        menus: dataMenus,
        currentMenu: menu,
        restaurantUsers: dataRestaurantUsers,
        fullyLoaded,
      }}
    >
      {children()}
    </LayoutContext.Provider>
  );
};

export default RestaurantController;

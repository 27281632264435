import { useRef, useState } from "react";
import { Form } from "antd";
import createInput from "../createInput";
import InputPassword from ".";
import { useFormContext } from "../../../lib/Form";

const Rule = ({ success, message, closing }) => {
  const successRef = useRef(success);
  if (!closing) {
    successRef.current = success;
  }
  return (
    <div>
      {successRef.current ? (
        <div className="accepted flex items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_386_5456)">
              <path
                d="M9.00016 16.17L4.83016 12L3.41016 13.41L9.00016 19L21.0002 7L19.5902 5.59L9.00016 16.17Z"
                fill="#118376"
              />
            </g>
            <defs>
              <clipPath id="clip0_386_5456">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span className="text-yumyGreen">{message}</span>
        </div>
      ) : (
        <div className="flex items-center gap-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" />
          <span>{message}</span>
        </div>
      )}
    </div>
  );
};
const getRules = (firstName, lastName, email) => {
  const firstNameParts = firstName.split(" ");
  const lastNameParts = lastName.split(" ");
  let emailParts = email.split("@");
  if (emailParts.length > 1) {
    emailParts = [emailParts[0], emailParts[1].split(".")[0]];
  }
  const nameAndEmailFields = [
    ...(firstName !== "" ? firstNameParts : []),
    ...(lastName !== "" ? lastNameParts : []),
    ...(email !== "" ? emailParts : []),
  ];
  return [
    {
      name: "LOWERCASE",
      message: "One lowercase character",
      rule: {
        pattern: /[a-z]/g,
      },
    },
    {
      name: "UPPERCASE",
      message: "One uppercase character",
      rule: {
        pattern: /[A-Z]/g,
      },
    },
    {
      name: "NUMBER",
      message: "One number",
      rule: {
        pattern: /[0-9]/g,
      },
    },
    {
      name: "SPECIAL_CHARACTER",
      message: "One special character",
      rule: {
        pattern: /[^a-zA-Z\d]/g,
      },
    },
    {
      name: "MUST_NOT_CONTAIN_NAME_OR_EMAIL_PARTS",
      message: "Must not contain parts of name or email",
      rule: {
        validator: (_, value) => {
          if (
            nameAndEmailFields.every(
              (part) => !value.toLowerCase().includes(part.toLowerCase())
            )
          ) {
            return Promise.resolve();
          }
          return Promise.reject("MUST_NOT_CONTAIN_NAME_OR_EMAIL_PARTS");
        },
      },
    },
  ];
};
function Password({ value, mainRules, error, ...props }) {
  const form = useFormContext();
  const errors = form.getFieldError("password");
  return (
    <>
      <InputPassword.OriginalPassword {...props} value={value} />
      <div
        show={value.length > 0}
        className={`flex flex-col gap-1 mt-2 transition-transform duration-300 ease-in-out overflow-hidden ${
          value.length > 0 ? "max-h-[500px]" : "max-h-0"
        }`}
      >
        {mainRules.map(({ name, message }) => (
          <Rule
            success={errors.indexOf(name) === -1}
            message={message}
            closing={value === ""}
          />
        ))}
      </div>
    </>
  );
}

const PasswordInput = createInput(Password, {
  defaultProps: () => ({ noStyle: true }),
});
function PasswordWrapper({ topRight, ...props }) {
  const form = useFormContext();
  const [visible, onVisibleChange] = useState(false);
  // Determine whether to show the RuleWrapper
  const firstName = Form.useWatch("firstName", form) || "";
  const lastName = Form.useWatch("lastName", form) || "";
  const email = Form.useWatch("email", form) || "";
  const mainRules = getRules(firstName, lastName, email);
  return (
    <PasswordInput
      {...props}
      topRight={<>{topRight}</>}
      visibilityToggle={{ visible, onVisibleChange }}
      rules={mainRules.map(({ name, rule }) => ({ message: name, ...rule }))}
      mainRules={mainRules}
    />
  );
}

export default PasswordWrapper;

import { useState } from 'react';
import { useInvalidate } from '@refinedev/core';
import { useUpdate } from "../../lib/refine";


const getNextState = (state) => {
  switch (state) {
    case 'ORDERED':
      return 'ACCEPTED';
    case 'ACCEPTED':
      return 'READY';
    case 'READY':
      return 'COMPLETED';
    default:
      return null;
  }
}

const OrderStateController = ({
  id,
  state,
  children,
}) => {
  const { mutate: onUpdate } = useUpdate();
  const [isLoading, setIsLoading] = useState(false);
  const invalidate = useInvalidate();
  return children({
    onClick: () => {
      if (!isLoading) {
        setIsLoading(true);
        onUpdate({
          resource: 'restaurants/qr-codes/sessions/purchases' ,
          id: id,
          values: {
            state: getNextState(state),
          },
          onMutationSuccess: () => {
            setIsLoading(false);
            invalidate({
                resource: 'restaurants/session-purchases',
                invalidates: ['resourceAll'],
            });
          },
          onMutationError: () => {
            setIsLoading(false);
          },
        });
      }
    },
    isLoading,
  });
};

export default OrderStateController;

import { Tooltip } from "antd";
import { MdOutlineDragIndicator } from "react-icons/md";
import { colors } from "../Layout/template";

const DragHandler = ({ disabled, disabledTooltip, ...props }) => {
  const actualAttachProps = disabled ? {} : props;
  const content = (
    <div
      disabled={disabled}
      {...actualAttachProps}
      className={disabled ? "text-gray cursor-not-allowed" : "text-yumyBlack"}
    >
      <MdOutlineDragIndicator />
    </div>
  );
  if (disabled && disabledTooltip) {
    return (
      <Tooltip title={disabledTooltip} placement="top" color={colors.yumyGreen}>
        {content}
      </Tooltip>
    );
  }
  return content;
};

export default DragHandler;

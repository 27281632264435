import { useRef } from 'react';
import { QRCode } from 'antd';

function saveSvg(svgEl, name) {
  svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
  var svgData = svgEl.outerHTML;
  var preface = '<?xml version="1.0" standalone="no"?>\r\n';
  var svgBlob = new Blob([preface, svgData], {type:"image/svg+xml;charset=utf-8"});
  var svgUrl = URL.createObjectURL(svgBlob);
  var downloadLink = document.createElement("a");
  downloadLink.href = svgUrl;
  downloadLink.download = name;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

const DownloadQRCodeController = ({ qrCode, children }) => { 
  const downloadQRCode = () => {
    const svg = qrCodeRef.current.querySelector('svg');
    if (svg) {
      saveSvg(svg, `${qrCode.name}.svg`);
    }
  };
  const qrCodeRef = useRef();
  return (
    <>
      <div style={{ display: 'none' }} ref={qrCodeRef}>
        <QRCode ref={qrCodeRef} type="svg" value={qrCode.url} bgColor="white" />
      </div>
      {children({ downloadProps: { onClick: downloadQRCode }})}
    </>
  )
};

export default DownloadQRCodeController;

import { useContext } from 'react';
import { Dropdown, Form } from 'antd';
import CategoriesContext from './CategoriesContext';
import DragHandler from '../../../../components/Functionality/DragHandler';
import { Input } from '../../../../components/Inputs';
import SubCategoryNameInput from './SubCategoryNameInput';
import { ModalAdditionSectionBottom, ModalAdditionSectionMore, ModalAdditionalSection } from '../../../../components/Layout/modal';
const SubCategory = ({
  name,
  onRemove,
  draggableProps,
  dragHandleProps,
  innerRef,
  isDragging,
  categoryFormName,
}) => {
  const subName = Form.useWatch(['categories', categoryFormName, 'sub_categories', name, 'name']);
  const subId = Form.useWatch(['categories', categoryFormName, 'sub_categories', name, 'id']);
  console.log('What is the subId?', subId);
  const { deleteSubCategory } = useContext(CategoriesContext);
  const dropdownItems = [{
    label: subName,
    key: '1',
    type: 'group',
    children: [
      {
        key: 'DELETE',
        label: 'Delete and keep products',
        onClick: () => { onRemove(); if (subId) { deleteSubCategory(subId); } },
        danger: true,
      }
    ],
  }];
  return (
    <ModalAdditionalSection ref={innerRef} {...draggableProps}>
      <ModalAdditionSectionBottom>
        <Input type="hidden" name={[name, 'id']} />
        <DragHandler {...dragHandleProps} />
      </ModalAdditionSectionBottom>
      <SubCategoryNameInput name={[name, 'name']} subCategoryId={subId} />
      <Dropdown menu={{ items: dropdownItems }} trigger={['click']}>
        <ModalAdditionSectionBottom>
          <ModalAdditionSectionMore onClick={(e) => e.preventDefault()}>
            More
          </ModalAdditionSectionMore>
        </ModalAdditionSectionBottom>
      </Dropdown>
    </ModalAdditionalSection>
  )
};

export default SubCategory;

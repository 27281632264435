import { useForm } from '../../../../../lib/refine';

const FormController = ({ children, userId, closeModal, initialValues }) => {
  const { formProps } = useForm({
    resource: 'users/email-changes',
    action: 'create',
    id: userId,
    meta: {
      userId,
    },
    successNotification: 'Email has been updated',
    onMutationSuccess: (user) => {
      closeModal();
    },
  });
  return children({
    formProps: {
      ...formProps,
      initialValues,
    },
  });
};

export default FormController;

import { Outlet } from "react-router-dom";

const Welcome = ({ footer }) => {
  return (
    <>
      <div className="flex flex-col min-h-dvh justify-between">
        <div>
          <div className="container max-w-6xl mx-auto px-4">
            <div className="flex justify-between items-center py-4">
              <div className="text-3xl font-extrabold relative after:w-[5px] after:h-[5px] after:bg-yumyGreen after:absolute after:bottom-[3px] after:-right-[6px] select-none">
                y
              </div>
            </div>
          </div>
        </div>
        <Outlet />
        {footer}
        <div className="py-5 flex justify-center items-center text-center border-l-8 border-yumyGreen">
          <div className="container max-w-6xl mx-auto px-4">
            <div className="text-grayScale900-56 text-sm leading-180">
              © 2024 Yumy. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;

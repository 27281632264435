import { Form } from 'antd';
import useHasChanges from "./useHasChanges";
import useRequiredFieldsFilled from "./useRequiredFieldsFilled";


export default function ButtonSectionController({
  initialValues,
  children,
  closeModal,
}) {
  const values = {
    name: Form.useWatch(['name']),
    country_id: Form.useWatch(['country_id']),
    city: Form.useWatch(['city']),
    address: Form.useWatch(['address']),
    zip: Form.useWatch(['zip']),
  };
  const hasChanges = useHasChanges({ initialValues, values });
  const requiredFieldsFilled = useRequiredFieldsFilled(values);
  return children({
    props: {
      save: {
        disabled: !hasChanges || !requiredFieldsFilled,
      },
      popConfirm: {
        onConfirm: closeModal,
      },
      close: {
        onClick: closeModal,
      },
    },
    hasChanges,
  });
}
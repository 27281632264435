import { useCreate as useCreateParent } from '@refinedev/core';
import { App } from 'antd';

const useCreate = () => {
  const { mutate: parentMutate, ...rest } = useCreateParent();
  const { message } = App.useApp();
  const mutate = ({
    successNotification,
    errorNotification,
    onMutationError,
    onMutationSuccess,
    ...props
  }) => {
    return parentMutate({
      successNotification: false,
      errorNotification: false,
      ...props,
    },
    {
      onSuccess: (data, variables, context) => {
        if (successNotification) {
          message.open({
            type: 'success',
            content: successNotification,
          });
        }
        if (onMutationSuccess) {
          onMutationSuccess(data, variables, context);
        }
      },
      onError: (error, variables, context) => {
        if (errorNotification) {
          message.open({
            type: 'error',
            content: errorNotification,
          });
        } else if (errorNotification !== false) {
          message.open({
            type: 'error',
            content: error.message,
          });
        }
        if (onMutationError) {
          onMutationError(error, variables, context);
        }
      },
    })
  };
  return { mutate, ...rest };
};

export default useCreate;

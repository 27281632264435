import { useState } from 'react';
import { Input } from 'antd';
import PasswordWithRules from './PasswordWithRules';
import createInput from '../createInput';

const Password = (props) => {
  console.log('What is password props', props);
  return (
    <Input.Password {...props} onChange={(...args) => { console.log('Onchange run', args); props.onChange(...args)}} />
  );
};

const InputPassword = createInput(Password, { defaultProps: () => ({ noStyle: true }) });

const FinalPassword = ({
  topRight,
  ...props
}) => {
  const [visible, onVisibleChange] = useState(false);
  return (
    <InputPassword
      topRight={<>{topRight}</>}
      visibilityToggle={{ visible, onVisibleChange }}
      {...props}
    />
  );
}

FinalPassword.PasswordWithRules = PasswordWithRules;
FinalPassword.OriginalPassword = Password;
export default FinalPassword;

import { useForm } from "@refinedev/antd";
import { useGo } from "@refinedev/core";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import Form from "../../lib/Form";
import { Input } from "../../components/Inputs";
import { Body } from "../../components/Layout/typography";
import { FormContent, FormWrapper } from "../../components/Layout/forms";

export default function AddMenu() {
  const go = useGo();
  const { restaurantId } = useParams();
  const { formProps } = useForm({
    resource: "restaurants/menus",
    action: "create",
    id: restaurantId,
    onMutationSuccess: ({ data: { id } }) => {
      go({ to: `/restaurant/${restaurantId}/menu/${id}` });
    },
  });
  return (
    <FormWrapper>
      <FormContent>
        <div>
          <h1>Add menu</h1>
          <Body md gray48>
            Add a new menu
          </Body>
        </div>
        <Form {...formProps} initialValues={{ name: "" }}>
          <Input label="Menu name" name="name" />
          <Button htmlType="submit">Add menu</Button>
        </Form>
      </FormContent>
    </FormWrapper>
  );
}

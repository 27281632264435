
export default function useRequiredFieldsFilled(values) {
  if (!values) {
    return false;
  }
  if (!values.categories) {
    return false;
  }
  let retVal = true;
  values.categories.forEach(
    (category) => {
      if (!category.name) {
        retVal = false;
      }
      category.sub_categories.forEach(
        (subCategory) => {
          if (!subCategory.name) {
            retVal = false;
          }
        },
      );
    },
  );
  return retVal;
};

import { Form } from 'antd';

const TimeValueController = ({
  remove,
  name,
  key,
  dayName,
  children,
}) => {
  return children({
    key,
    onRemove: () => remove(name),
    name,
  });
};

export default TimeValueController;

import { CrudSorting } from "@refinedev/core";

export const generateSort = (sorters?: CrudSorting) => {
  if (sorters && sorters.length > 0) {
    const sortArray = [];
    for (let i = 0; i < sorters.length; i++) {
      const item = sorters[i];
      let sortString = item.field;
      if (item.order === 'desc') {
        sortString = `-${item.field}`;
      }
      sortArray.push(sortString);
    }

    return {
      sort: sortArray.join(','),
    };
  }

  return null;
};

import { Form } from 'antd';
import useHasChanges from "./useHasChanges";
import useRequiredFieldsFilled from "./useRequiredFieldsFilled";


export default function ButtonSectionController({
  initialValues,
  children,
  closeModal,
}) {
  const values = {
    first_name: Form.useWatch(['first_name']),
    last_name: Form.useWatch(['last_name']),
  };
  const valuesInitialValues = {
    first_name: initialValues.first_name,
    last_name: initialValues.last_name,
  };
  const hasChanges = useHasChanges({ initialValues: valuesInitialValues, values });
  const requiredFieldsFilled = useRequiredFieldsFilled(values);
  return children({
    props: {
      save: {
        disabled: !hasChanges || !requiredFieldsFilled,
      },
      popConfirm: {
        onConfirm: closeModal,
      },
      close: {
        onClick: closeModal,
      },
    },
    hasChanges,
  });
}
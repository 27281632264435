import { useOne as useOneParent } from '@refinedev/core';
import { useSuspense } from './Suspense';


export const useOne = ({ suspenseKey, ...rest }) => {
  const ret = useOneParent(rest);
  useSuspense(suspenseKey, ret.isLoading);
  return ret;
};

export default useOne;
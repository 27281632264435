import { type MetaQuery, type BaseKey } from "@refinedev/core";
import { stringify } from "query-string";
import { getResource } from "../../resources";

const getAfterParent = (
  resourceName: string,
  parent: string,
) => {
  return resourceName.substring(parent.length + 1);
}

const getTopLevelResourceUrl = (
  apiUrl: string,
  resourceName: string,
  callType: "getList" | "getMany" | "create" | "update" | "getOne" | "deleteOne",
  id?: BaseKey,
) => {
  switch (callType) {
    case 'getList':
    case 'create':
      return `${apiUrl}/${resourceName}`;
    case 'getMany':
      return `${apiUrl}/${resourceName}?${stringify({ id })}`;
    case 'update':
    case 'deleteOne':
    case 'getOne':
      return `${apiUrl}/${resourceName}/${id}`;
  }
};

const getSingletonResourceUrl = (
  apiUrl: string,
  resourceName: string,
  callType: "getList" | "getMany" | "create" | "update" | "getOne" | "deleteOne",
  parent: string,
  id?: BaseKey,
) => {
  switch (callType) {
    case 'update':
    case 'deleteOne':
    case 'getOne':
    case 'create':
      return `${apiUrl}/${parent}/${id}/${getAfterParent(resourceName, parent)}`;
    case 'getList':
    case 'getMany':
      throw new Error(`Invalid callType on singleton ${resourceName}: ${callType}`);
  }
};

const getCollectionUrl = (
  apiUrl: string,
  resourceName: string,
  callType: "getList" | "getMany" | "create" | "update" | "getOne" | "deleteOne",
  parent: string,
  id?: BaseKey,
) => {
  switch (callType) {
    case 'create':
    case 'getList':
      return `${apiUrl}/${parent}/${id}/${getAfterParent(resourceName, parent)}`;
    case 'update':
    case 'deleteOne':
    case 'getOne':
      return `${apiUrl}/${resourceName}/${id}`;
    case 'getMany':
      throw new Error(`Invalid callType on collection ${resourceName}: ${callType}`);
  }
};

const getResourceUrl = (
  apiUrl: string,
  resourceName: string,
  callType: "getList" | "getMany" | "create" | "update" | "getOne" | "deleteOne",
  meta?: MetaQuery,
  id?: BaseKey,
) => {
  const resource = getResource(resourceName);
  const {
    meta: {
      resourceType,
      parent,
      topLevel,
    },
  } = resource;
  let usedId = id;
  if (typeof usedId === 'undefined') {
    if (typeof meta?.id !== 'undefined') {
      usedId = meta?.id;
    }
  }
  if (!parent || topLevel) {
    return getTopLevelResourceUrl(apiUrl, resourceName, callType, usedId);
  }
  if (!resourceType) {
    throw new Error(`Invalid resourceType on ${resourceName}, all sub resources must have a resourceType`);
  }
  const {
    meta: {
      idParamName,
    },
  } = getResource(parent);
  if (meta?.[idParamName] && !usedId) {
    usedId = meta[idParamName];
  }
  switch (resourceType) {
    case 'singleton':
      return getSingletonResourceUrl(apiUrl, resourceName, callType, parent, usedId);
    case 'collection':
      return getCollectionUrl(apiUrl, resourceName, callType, parent, usedId);
    default:
      throw new Error(`Invalid resourceType on ${resourceName}: ${resourceType}`);
  }
};

export default getResourceUrl;

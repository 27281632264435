
import { useState, useMemo } from 'react';
import { useGetIdentity } from '@refinedev/core';
import hash from 'object-hash';

const createInitialValuesFromUser = ({
  first_name,
  last_name,
  email,
}) => ({
  first_name,
  last_name,
  email,
});


const ModalController = ({ children, closeModal }) => {
  const [formKeyIndex, setFormKeyIndex] = useState(0);
  const { data: identity, isLoading } = useGetIdentity();
  const initialValues = useMemo(
    () => identity ? createInitialValuesFromUser(identity) : {},
    [identity],
  );
  if (isLoading) {
    return null;
  }
  const onActualCloseModal = () => {
    closeModal();
    setFormKeyIndex((prev) => prev + 1);
  };
  return children({
    formKey: `${hash(initialValues)}_${formKeyIndex}`,
    props: {
      buttonSection: {
        initialValues,
        closeModal: onActualCloseModal,
      },
      formController: {
        initialValues,
        userId: identity?.id,
        closeModal: onActualCloseModal,
      },
    },
  })
};

export default ModalController;

import { useState } from 'react';
import { Flex } from 'antd';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Label } from '../../../../components/Layout/forms';
import { Switch } from '../../../../components/Inputs';
import Category from './Category';
import { ModalAdditionalOptions } from '../../../../components/Layout/modal';

const CategoriesList = ({
    form,
    fields,
    add,
    move,
    remove,
    uncategorizedId,
}) => {
    const [currentlyEditingIndex, setCurrentlyEditingIndex] = useState(null);
    const [showAllSubCategories, setShowAllSubCategories] = useState(false);
    const onDragEnd = (result) => {
      if (!result.destination) {
        return; // Dropped outside droppable
      }
      move(result.source.index, result.destination.index);
    };
    return (
        <>
          <ModalAdditionalOptions>
            <p>All Categories</p>
            <div>
            <Switch
              checkedChildren="Showing subcategories"
              unCheckedChildren="Hiding subcategories"
              onChange={setShowAllSubCategories}
              value={showAllSubCategories}
            />
            </div>
          </ModalAdditionalOptions>
          <Label>Category names</Label>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="categories">
              {({ droppableProps, innerRef, placeholder }) => (
                <Flex vertical {...droppableProps} ref={innerRef}>
                  {fields.map(
                    ({ name, key }, index) => (
                      <Draggable key={key} draggableId={`${key}`} index={name}>
                        {({ draggableProps, innerRef, dragHandleProps }, { isDragging }) => (
                          <Category
                            key={key}
                            name={name}
                            onRemove={() => remove(name)}
                            uncategorizedId={uncategorizedId}
                            form={form}
                            editing={currentlyEditingIndex === index}
                            onStopEditing={() => setCurrentlyEditingIndex(null)}
                            onStartEditing={() => setCurrentlyEditingIndex(index)}
                            showAllSubCategories={showAllSubCategories}
                            editingAny={currentlyEditingIndex !== null}
                            innerRef={innerRef}
                            draggableProps={draggableProps}
                            isDragging={isDragging}
                            dragHandleProps={dragHandleProps}
                            specialId={key}
                          />
                        )}
                      </Draggable>
                    ),
                  )}
                  {placeholder}
                </Flex>
              )}

            </Droppable>
          </DragDropContext>
        </>
    );
};

export default CategoriesList;

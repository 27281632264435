import styled from "styled-components";
import { colors } from "../../components/Layout/template";

export const Dropdown = styled.div`
  display: flex;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.2s;
  flex-direction: column;
  margin: 4px;
  padding: 0px 4px 0px 4px;
  border-radius: 8px;
  gap: 16px;
  background: ${colors.white4};

  ${({ isOpen }) =>
    isOpen &&
    `
    padding: 4px 4px 16px 4px;
    max-height: 100%;  // Adjust as necessary for the content size
    opacity: 1;
  `}

  & button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    justify-content: flex-start !important;
    padding: 8px 8px 8px 32px !important;
    transition: background 0.2s;
    border-radius: 8px !important;

    &.active {
      color: ${colors.yumyGreen};
    }

    &:hover {
      background: ${colors.white4};
    }

    &.add-new {
      justify-content: center !important;
      padding: 8px !important;
      border: 1px solid ${colors.white8};
      background: ${colors.white4};
      border-radius: 100px !important;

      &:hover {
        background: ${colors.green10};
      }
    }
  }
`;

export const DropdownElipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: 140px;
`;

export const DropdownButtonArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & svg path {
    transition: all 0.2s;
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    transform: rotate(180deg);

    & svg path {
      fill: ${colors.yumyGreen};
      fill-opacity: 1;
    }
  `}
`;

export const DropdownButton = styled.div`
  background: none;
  border: none;
  padding: 8px !important;
  margin: 4px;
  display: flex;
  justify-content: space-between !important;
  width: calc(100% - 8px);
  cursor: pointer;
  border-radius: 8px;
  transition: background 0.2s;
  user-select: none;

  &:hover {
    background: ${colors.green10};

    & ${DropdownButtonArrow} {
      & svg path {
        fill: ${colors.yumyGreen};
        fill-opacity: 1;
      }
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    background: ${colors.green10};
  `}
`;

export const DropdownButtonInner = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const DropdownButtonText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
`;

export const Separator = styled.div`
  background: rgba(255, 255, 255, 0.08);
  height: 1px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

import { useState, useEffect, useRef } from 'react';
import { useGo } from "@refinedev/core";
import { useModal } from '@refinedev/antd';
import { useLayout } from "./LayoutContext";
import { YumyIconReceipt, YumyIconRightArrowDown } from '../../components/YumyIcons';
import { DropdownButton, DropdownButtonArrow, DropdownButtonInner, DropdownButtonText, Dropdown, Separator } from "./DropDownStyles";
import MenuSettings from "../../pages/restaurant/menu-settings";
import Modal from '../../lib/Modal';

const MenuDropdown = () => {
  const go = useGo();
  const { menus, currentMenu, restaurant } = useLayout();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const onToggle = () => setIsOpen(!isOpen);
  const {
    modalProps,
    show,
    close,
  } = useModal();
  // Manage outside clicks
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div ref={dropdownRef}>
      <DropdownButton onClick={onToggle} isOpen={isOpen}>
        <DropdownButtonInner>
          <YumyIconReceipt />
          <DropdownButtonText>{currentMenu ? currentMenu.name : 'Choose menu'}</DropdownButtonText>
        </DropdownButtonInner>
        <DropdownButtonArrow isOpen={isOpen}><YumyIconRightArrowDown /></DropdownButtonArrow>
      </DropdownButton>
      <Dropdown isOpen={isOpen}>
        {menus.map(menu => (
          <button key={menu.id} onClick={() => {
            setIsOpen(false);
            go({ to: `/restaurant/${restaurant.id}/menu/${menu.id}` });
          }} className={menu.id === currentMenu?.id ? 'active' : ''}>
            {menu.name}
          </button>
        ))}
        <button className="add-new" onClick={(e) => {
          e.stopPropagation(); // Stop event propagation to prevent any parent handlers from being triggered
          setIsOpen(false); // Close the dropdown before navigating
          show();
        }}>Add new</button>
      </Dropdown>
      <Separator />
      <Modal {...modalProps} maskClosable={false} closeIcon={false} footer={null} width={940}>
        <MenuSettings create closeModal={close} />
      </Modal>
    </div>
  );
};

export default MenuDropdown;

import { useEffect } from 'react';
import { useGo } from '@refinedev/core';
import { useOne } from '../../lib/refine';
import { useParams } from 'react-router-dom';

const SubscriptionCompleted = () => {
  const { planId } = useParams();
  const { data: plan, isLoading } = useOne({
    resource: 'users/plans',
    id: planId,
    queryOptions: {
      refreshInterval: 1,
    },
  });
  const isActuallyLoading = isLoading || plan.state === 'PENDING';
  const go = useGo();
  useEffect(() => {
    if (!isActuallyLoading) {
      go({ to: '/onboarding/create-restaurant' });
    }
  }, [isActuallyLoading, go]);
  return (
    <div>
      <div>
        Processing payment...
      </div>
    </div>
  );
};

export default SubscriptionCompleted;

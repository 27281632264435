import { useLayout } from "../../../../layouts/Restaurant/LayoutContext";
import { useParams } from "react-router-dom";
import { useList, useSelect } from "../../../../lib/refine";
import QRCodesContext from './QRCodesContext';

const sorters = [{
  field: 'order',
  order: 'asc',
}];

const emptyArray = [];
const QRCodesPageController = ({
  children,
}) => {
  const { restaurant } = useLayout();
  const { restaurantId } = useParams();
  const { data, isLoading: qrCodesIsLoading } = useList({ resource: 'restaurants/qr-codes', id: restaurantId, hasPagination: false, suspenseKey: 'QR_CODES', sorters });
  const menuSelectProps = useSelect({
    resource: 'restaurants/menus',
    id: restaurantId,
    optionLabel: 'name',
    suspenseKey: 'QR_CODES_MENU_SELECT',
  });
  const qrCodes = data?.data || emptyArray;
  const fullyLoaded = !qrCodesIsLoading && !menuSelectProps.queryResult.isLoading;
  return (
    <QRCodesContext.Provider value={{ qrCodes, fullyLoaded, menuSelectProps }}>
      {children({ restaurant })}
    </QRCodesContext.Provider>
  );
};

export default QRCodesPageController;

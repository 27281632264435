import { Form } from 'antd';

const WhereController = ({ children }) => {
  const where = Form.useWatch(['where']);
  return (
    <Form.Item name={'where'} noStyle>
      {children({ where })}
    </Form.Item>
  );
};

export default WhereController;
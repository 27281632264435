import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import QRCodesPageController from "./Controllers/QRCodesPageController";
import QRCodesSectionController from "./Controllers/QRCodesSectionController";
import QRCodesController from "./Controllers/QRCodesController";
import QRCodeRowController from "./Controllers/QRCodeRowController";
import AllQRCodesDropdownController from "./Controllers/AllQRCodesDropdownController";
import QRCodeDropdownController from "./Controllers/QRCodeDropdownController";
import DownloadQRCodeController from "./Controllers/DownloadQRCodeController";
import { Button, Flex, Dropdown, Checkbox, Space, Tag } from "antd";
import { useLink } from "@refinedev/core";
import { YumyHeader, colors } from "../../../components/Layout/template";
import { YumyIconPlus } from "../../../components/YumyIcons";
import {
  DeleteOutlined,
  CopyOutlined,
  EyeInvisibleFilled,
  EyeFilled,
} from "@ant-design/icons";
import { Overline, Body, Caption } from "../../../components/Layout/typography";
import {
  YumyProductCategories,
  YumyProductCategoriesButton,
  YumyProductCategoriesWrap,
  YumyProducts,
  YumyProductsColumn,
  YumyProductsMore,
  YumyProductsRow,
} from "../../../components/Layout/products";
import { Suspense } from "../../../lib/refine";
import LoaderSkeleton from "./LoaderSkeleton";
// import Statistics from "../../../components/Statistics";

const QRCodes = ({ children: subMenuRoutes }) => {
  const Link = useLink();
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  const { events } = useDraggable(ref, {
    applyRubberBandEffect: true, // activate rubber band effect
  });
  return (
    <Suspense fallback={<LoaderSkeleton />}>
      <QRCodesPageController>
        {({ restaurant }) => (
          <>
            <YumyHeader>
              <div>
                <Overline>{restaurant?.name}</Overline>
                <h3>QR Codes</h3>
              </div>
              <Link to="add-qr-code">
                <Button type="primary" className="addNew">
                  Add new <YumyIconPlus />
                </Button>
              </Link>
            </YumyHeader>
            <Flex vertical gap={40}>
              {/*
              <Statistics
                title="Scans"
                statisticItems={[
                  { value: '-', title: "Total scans" },
                  { value: '-', title: "Scans today" },
                  { value: '-', title: "Most sold QR Wall" },
                  { value: '-', title: "???" }
                ]} 
              />
              */}
              <QRCodesSectionController>
                {({ menuChoices, activeMenuId, activeMenuName }) => (
                  <>
                    <YumyProductCategoriesWrap>
                      <Body sm>Menus</Body>
                      <YumyProductCategories
                        {...events}
                        ref={ref} // add reference and events to the wrapping div
                      >
                        {menuChoices.map(
                          ({ key, name, qrCodes, isActive, onClick }) => (
                            <YumyProductCategoriesButton
                              onClick={onClick}
                              key={key}
                              active={isActive}
                            >
                              {name} ({qrCodes})
                            </YumyProductCategoriesButton>
                          )
                        )}
                      </YumyProductCategories>
                    </YumyProductCategoriesWrap>
                    <QRCodesController
                      activeMenuId={activeMenuId}
                      key={activeMenuId}
                    >
                      {({ showMenu, selectedQRCodes, props }) => (
                        <YumyProducts>
                          <Body sm>
                            {activeMenuName === "All"
                              ? "All QR Codes"
                              : activeMenuName}
                          </Body>
                          <Flex vertical>
                            <YumyProductsRow>
                              <YumyProductsColumn checkbox>
                                <Checkbox {...props.allCheckbox} />
                              </YumyProductsColumn>
                              {selectedQRCodes.length > 0 && (
                                <YumyProductsColumn selected>
                                  <Caption>
                                    Selected {selectedQRCodes.length}
                                  </Caption>
                                </YumyProductsColumn>
                              )}
                              {selectedQRCodes.length === 0 && (
                                <>
                                  <YumyProductsColumn product>
                                    <Caption>QR Name</Caption>
                                  </YumyProductsColumn>
                                  {showMenu && (
                                    <YumyProductsColumn category>
                                      <Caption>Menu</Caption>
                                    </YumyProductsColumn>
                                  )}
                                  <YumyProductsColumn created>
                                    <Caption>Created</Caption>
                                  </YumyProductsColumn>
                                  <YumyProductsColumn status>
                                    <Caption>Status</Caption>
                                  </YumyProductsColumn>
                                </>
                              )}
                              {selectedQRCodes.length > 0 && (
                                <YumyProductsColumn more>
                                  <AllQRCodesDropdownController
                                    {...props.dropdown}
                                    dropdownLabel={`${selectedQRCodes.length} selected`}
                                    moveLabel="Move to another menu"
                                    deleteLabel={`Delete all ${selectedQRCodes.length} selected`}
                                    selectedProducts={selectedQRCodes}
                                    deleteNotification={`${selectedQRCodes.length} QR Codes have been deleted`}
                                    moveNotification={`${selectedQRCodes.length} QR Codes have been moved`}
                                  >
                                    {({ dropdownProps }) => (
                                      <Dropdown {...dropdownProps}>
                                        <div className="select-none">
                                          <YumyProductsMore>
                                            More
                                          </YumyProductsMore>
                                        </div>
                                      </Dropdown>
                                    )}
                                  </AllQRCodesDropdownController>
                                </YumyProductsColumn>
                              )}
                            </YumyProductsRow>
                            <QRCodeRowController
                              {...props.qrCodeRow}
                              orderChangedNotification="Order changed"
                            >
                              {({ key, qrCode, props }) => (
                                <YumyProductsRow key={key}>
                                  <YumyProductsColumn checkbox>
                                    <Checkbox {...props.checkbox} />
                                  </YumyProductsColumn>
                                  <YumyProductsColumn product>
                                    <span>{qrCode.name}</span>
                                  </YumyProductsColumn>
                                  {showMenu && (
                                    <YumyProductsColumn category>
                                      {qrCode.menu}
                                    </YumyProductsColumn>
                                  )}
                                  <YumyProductsColumn created>
                                    {qrCode.created}
                                  </YumyProductsColumn>
                                  <YumyProductsColumn status>
                                    <Tag
                                      className={
                                        qrCode.active ? "active" : "draft"
                                      }
                                    >
                                      {qrCode.active ? "Active" : "Draft"}
                                    </Tag>
                                  </YumyProductsColumn>
                                  <YumyProductsColumn more>
                                    <DownloadQRCodeController qrCode={qrCode}>
                                      {({ downloadProps }) => (
                                        <Button {...downloadProps}>
                                          Download
                                        </Button>
                                      )}
                                    </DownloadQRCodeController>
                                    <QRCodeDropdownController
                                      qrCode={qrCode}
                                      duplicateLabel={
                                        <Space>
                                          <CopyOutlined />
                                          Duplicate QR Code
                                        </Space>
                                      }
                                      disableLabel={
                                        <Space>
                                          <EyeInvisibleFilled
                                            style={{
                                              color: colors.grayScale900_56,
                                            }}
                                          />
                                          Disable
                                        </Space>
                                      }
                                      enableLabel={
                                        <Space>
                                          <EyeFilled
                                            style={{ color: colors.yumyGreen }}
                                          />
                                          Enable
                                        </Space>
                                      }
                                      deleteLabel={
                                        <Space>
                                          <DeleteOutlined />
                                          Delete
                                        </Space>
                                      }
                                      disableNotification="QR Code has been disabled"
                                      enableNotification="QR Code has been enabled"
                                      deleteNotification="QR Code has been deleted"
                                      duplicateNotification="QR Code has been duplicated"
                                      anySelected={selectedQRCodes.length > 0}
                                      disabledTooltip="You can't access the QR Code menu, when you have selected QR Codes"
                                    >
                                      {({ dropdownProps }) => (
                                        <Dropdown {...dropdownProps}>
                                          <div className="select-none">
                                            <YumyProductsMore
                                              disabled={dropdownProps.disabled}
                                            >
                                              More
                                            </YumyProductsMore>
                                          </div>
                                        </Dropdown>
                                      )}
                                    </QRCodeDropdownController>
                                  </YumyProductsColumn>
                                </YumyProductsRow>
                              )}
                            </QRCodeRowController>
                          </Flex>
                        </YumyProducts>
                      )}
                    </QRCodesController>
                  </>
                )}
              </QRCodesSectionController>
            </Flex>
            {subMenuRoutes}
          </>
        )}
      </QRCodesPageController>
    </Suspense>
  );
};

export default QRCodes;

import { TimePicker as ParentTimePicker } from 'antd';
import dayJs from 'dayjs';
import createInput from './createInput';

const format = 'HH:mm';
const TimePicker = ({
  value,
  onChange,
  ...props
}) => {
  const handleChange = (value, timeString) => {
    onChange(timeString);
  };
  console.log('So what is the value?', value);
  return (
    <ParentTimePicker
      format={format}
      value={value ? dayJs(value, format) : null}
      onChange={handleChange}
      {...props}
    />
  );
}

export default createInput(TimePicker);
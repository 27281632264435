import { Skeleton } from "antd";

const LoaderSkeleton = () => {
  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 bg-white-100 z-[10000]">
      <Skeleton active />
    </div>
  );
};

export default LoaderSkeleton;


export default function useRequiredFieldsFilled(values) {
  if (!values) {
    return false;
  }
  if (!values.password || !values.email) {
    return false;
  }
  return true;
};

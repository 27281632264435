import { useGo, useIsAuthenticated } from '@refinedev/core';
import { useEffect, useRef } from 'react';
import Login from '../pages/auth/login';
import Forgot from '../pages/auth/forgot';
import Register from '../pages/auth/register';
import Welcome from '../layouts/Welcome';
import Privacy from '../pages/misc/privacy';
import Terms from '../pages/misc/terms';
import { SubRouteModal, createEmptySubRoutes } from '../lib/helpers';

export const RedirectToLogin = () => {
  const go = useGo();
  useEffect(() => {
    go({ to: '/login' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}

const RedirectToDashboardWhenLoggedInInitially = () => {
  const go = useGo();
  const hasBeenShownAnswer = useRef(false);
  const { data: { authenticated: isAuthenticatedStatus } = {} } = useIsAuthenticated();
  useEffect(() => {
    if (hasBeenShownAnswer.current === false && isAuthenticatedStatus !== undefined) {
      hasBeenShownAnswer.current = true;
      if (isAuthenticatedStatus) {
        go({ to: '/' });
      }
    }
  }, [isAuthenticatedStatus, hasBeenShownAnswer, go]);
  return null;
};

const login = [
  {
    element: <><Welcome /><RedirectToDashboardWhenLoggedInInitially /></>,
    children: [
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/register',
        element: (
          <>
            <Register />
            <SubRouteModal path="terms"><Terms /></SubRouteModal>
            <SubRouteModal path="privacy"><Privacy /></SubRouteModal>
          </>
        ),
        children: createEmptySubRoutes(['terms','privacy']),
      },
      { 
        path: '/forgot',
        element: <Forgot />,
      }
    ],
  },
];

export default login;

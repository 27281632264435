
import { useMenu } from './MenuContext';
import { useUpdateMany, useDeleteMany } from '../../../../lib/refine';

const AllProductsDropdownController = ({
  children,
  selectedProducts,
  deleteLabel,
  dropdownLabel,
  noSubCategoryLabel,
  moveLabel,
  deleteNotification,
  moveNotification,
}) => {
  const { categories, subCategoriesByCategory } = useMenu();
  const items = [{
    key: '1',
    type: 'group',
    label: dropdownLabel,
    children: [
        {
          key: 'MOVE',
          label: moveLabel,
          children: categories.map((category) => ({
            key: category.id,
            label: category.name,
            children: [
              {
                key: `MOVE;${category.id};NO_SUB_CATEGORY`,
                label: noSubCategoryLabel,
              },
              ...(subCategoriesByCategory[category.id]?.map((subCategory) => ({
                key: `MOVE;${category.id};${subCategory.id}`,
                label: subCategory.name,
              })) || []),
            ],
          })),
        },
        { key: 'DIV', type: 'divider' },
        { key: 'DELETE', label: deleteLabel, danger: true}
    ]
}];
const { mutate: onUpdateMany } = useUpdateMany();
const { mutate: onDeleteMany } = useDeleteMany();
const onClick = ({ key }) => {
    const keyParts = key.split(';');
    switch (keyParts[0]) {
        case 'MOVE':
            const categoryId = keyParts[1];
            const subCategoryId = keyParts[2] === 'NO_SUB_CATEGORY' ? null : keyParts[2];
            onUpdateMany({
                resource: 'restaurants/menus/products',
                ids: selectedProducts,
                values: {
                    category_id: categoryId,
                    sub_category_id: subCategoryId,
                },
                successNotification: moveNotification,
            });
            break;
        case 'DELETE':
            onDeleteMany({
                resource: 'restaurants/menus/products',
                ids: selectedProducts,
                successNotification: deleteNotification,
            });
            break;
        default:
    }
};
return children({ dropdownProps: { menu: { items, onClick }, trigger: ['click'] } });
};

export default AllProductsDropdownController;

import { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQRCodes } from '../../Controllers/QRCodesContext';
import { useLayout } from '../../../../../layouts/Restaurant/LayoutContext';
import deepEqual from 'deep-equal';
import hash from 'object-hash';

const createInitialValuesFromNothing = (firstMenuId) => ({
  name: '',
  note: '',
  menu_id: firstMenuId,
  active: false,
  order: 0,
});
const createInitialValuesFromProduct = ({ name, note, menu_id, active }) => ({
  name,
  note,
  menu_id,
  active,
});

const ModalController = ({ children }) => {

  const { menus, fullyLoaded: layoutFullyLoaded } = useLayout();
  const { qrCodes, fullyLoaded, menuSelectProps } = useQRCodes();
  const { qrCodeId } = useParams();
  const [temporaryQRCode, setTemporaryQRCode] = useState(null);
  const qrCode = useMemo(
    () => temporaryQRCode || qrCodes.find((p) => `${p.id}` === `${qrCodeId}`),
    [qrCodes, qrCodeId, temporaryQRCode],
  );
  useEffect(() => {
    if (temporaryQRCode) {
      const corrospondingQRCode = qrCodes.find((p) => `${p.id}` === `${temporaryQRCode.id}`);
      if (deepEqual(corrospondingQRCode, temporaryQRCode)) {
        setTemporaryQRCode(null);
      }
    }
  }, [qrCodes, temporaryQRCode]);
  const firstMenu = menus[0];
  const initialValues = useMemo(
    () => qrCode ? createInitialValuesFromProduct(qrCode) : createInitialValuesFromNothing((firstMenu || {}).id),
    [qrCode, firstMenu],
  );
  if (!fullyLoaded || !layoutFullyLoaded) {
    return null;
  }
  return children({
    key: hash(initialValues),
    menuSelectProps,
    initialValues,
    qrCode,
    qrCodeId,
    setTemporaryQRCode,
  })
};

export default ModalController;

import styled from "styled-components";
import { Button, Col } from "antd";
import { colors } from "../../../components/Layout/template";

export const MenuSettingsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 575px) {
    flex-direction: column;
    gap: 0px;

    & > div {
      width: 100%;
    }
  }
`;

export const MenuSettingsButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  & #menuSettingsSave,
  & #menuSettingsCancel,
  & #menuSettingsClose {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    border-radius: 999px;
    border: none;
    height: 40px;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    box-shadow: none;
  }

  & #menuSettingsSave {
    width: 160px;
    background: ${colors.yumyGreens100};
    color: ${colors.yumyGreen};

    &:disabled {
      opacity: 0.3;
    }

    @media (max-width: 575px) {
      width: 50%;
    }
  }

  & #menuSettingsCancel,
  & #menuSettingsClose {
    width: 100px;
    background: ${colors.grayScale900_4};

    @media (max-width: 575px) {
      width: 50%;
    }
  }

  @media (max-width: 575px) {
    justify-content: center;
    width: 100%;
  }
`;

export const MenuSettingsTitle = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%;
`;

export const MenuSettingsTabs = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 24px;
`;

export const MenuSettingsTab = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border: none;
  border-radius: 999px;
  background: ${(props) =>
    props.active ? colors.yumyGreen : colors.grayScale900_4};
  color: ${(props) => (props.active ? colors.yumyWhite : colors.yumyBlack)};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  height: 32px !important;

  cursor: pointer;

  ${(props) =>
    props.active
      ? `
    &:hover {
      background: ${colors.yumyGreen} !important;
      color: ${colors.yumyWhite} !important;
    }
  `
      : `
    &:hover {
      background: ${colors.grayScale900_4} !important;
    }
  `}
`;

export const MenuSettingsSubTitle = styled.h2`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%;
  margin-bottom: 16px;
`;

export const MenuSettingsInfoChoices = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  @media (max-width: 575px) {
    grid-template-columns: 1fr;

    margin-bottom: 16px;
  }
`;

export const MenuSettingsTimeCol = styled(Col)`
  border-bottom: 1px solid ${colors.grayScale900_8};
  padding-bottom: 16px;
`;

export const MenuSettingsTimeSlot = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 100px;
  padding: 0px 8px 0px 16px;
  background: ${colors.grayScale900_4};
  cursor: pointer;
  border: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%;

  &:hover {
    background: ${colors.grayScale900_8};
  }
`;

export const MenuSettingsButtonWrap = styled.div`
  & button {
    background: ${colors.grayScale900_4};
    border-radius: 999px;
    border: 1px solid ${colors.grayScale900_4};
  }
`;

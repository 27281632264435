
interface Env {
    apiUrl: string,
    clientId: string,
    clientSecret: string,
    appEnv: string,
    stripeKey: string,
};

const getEnvValue = (key: string): string => {
    const value = process.env[`REACT_APP_${key}`];
    if (!value) {
        throw new Error(`Missing environment variable: ${key}`);
    }
    return value;
};

const env: Env = {
    apiUrl: getEnvValue('API_URL'),
    clientId: getEnvValue('CLIENT_ID'),
    clientSecret: getEnvValue('CLIENT_SECRET'),
    appEnv: getEnvValue('APP_ENV'),
    stripeKey: getEnvValue('STRIPE_KEY'),
};

export default env;

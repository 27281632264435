import styled from "styled-components";
import { colors } from "./template";
import { Caption } from "./typography";

export const YumyModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ModalInnerWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 575px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const ModalHeaderButtons = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 575px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const ModalMain = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 162px 1fr;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

export const ModalAdditions = styled.div`
  grid-column: span 2;
`;

export const ModalImage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  height: 100%;

  @media (max-width: 700px) {
    align-items: center;
  }

  & > div {
    height: 100%;
  }
`;

export const ModalImageInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 240px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ModalImageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const ModalDouble = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;

  @media (max-width: 575px) {
    flex-direction: column;
  }
`;

export const ModalAdditionalOptions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
  & p {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
    color: ${colors.yumyBlack};
    transition: color 300ms ease-in-out;
  }

  @media (max-width: 575px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const ModalAdditionalSection = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  width: 100%;

  ${({ mb0 }) =>
    mb0
      ? `
    margin-bottom: 0;
  `
      : "margin-bottom: 16px;"}
`;

export const ModalAdditionSectionBottom = styled.div`
  padding-bottom: 9px;
`;

export const ModalAdditionSectionMore = ({ children, add, ...props }) => {
  return (
    <Caption
      extraClass={`transition-colors duration-300 ease w-8 shrink-0 text-right cursor-pointer ${
        add
          ? "text-yumyGreen hover:text-yumyGreen600 cursor-not-allowed"
          : "hover:text-yumyGreen"
      }`}
      {...props}
    >
      {children}
    </Caption>
  );
};
export const ModalAdditionSectionMore2 = styled.p`
  line-height: 180%;
  font-size: 12px;
  font-weight: 600;
  color: ${colors.grayScale900_56};
  cursor: pointer;
  width: 32px;
  flex-shrink: 0;
  text-align: right;

  &:hover {
    color: ${colors.yumyGreen};
  }

  ${({ add }) =>
    add
      ? `
    color: ${colors.yumyGreen};

    &:hover {
      color: ${colors.yumyGreen600};
    }
  `
      : `
    &:hover {
      color: ${colors.yumyGreen};
    }
  `}
`;

export const ModalOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 22px;
  width: 100%;

  @media (max-width: 575px) {
    padding-left: 0;
  }
`;

export const ModalOptionsTitle = styled(Caption)`
  padding-left: 22px;
  padding-bottom: 8px;
`;

export const ModalCategoryAddButton = styled.div`
  display: flex;
  align-items: flex-end;
  width: 140px;
  padding-bottom: 2px;

  & button {
    width: 100%;
  }
`;

export const ModalCategoryTextInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  box-shadow: 0 0 0 1px rgba(18, 17, 39, 0.08);
  transition: box-shadow 300ms ease-in-out;
  height: 40px;
  padding: 6px 16px 5px 16px;
  border-radius: 8px;

  ${({ uncategorized }) =>
    !uncategorized &&
    `
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 0 2px ${colors.yumyGreen};
    }
  `}
`;

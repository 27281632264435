import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Flex } from 'antd';
import Option from './Option';
import AddOption from './AddOption';

const Options = ({
  remove,
  add,
  move,
  fields,
  specialId,
}) => {
  const onDragEnd = (result) => {
    if (result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };
  const onRemove = (index) => {
    remove(index);
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`options_${specialId}`}>
        {({ droppableProps, placeholder, innerRef }) => (
          <Flex vertical {...droppableProps} ref={innerRef}>
            {fields.map(({ key, name }) => (
              <Draggable key={key} draggableId={`${key}`} index={name}>
                {({ draggableProps, innerRef, dragHandleProps }) => (
                  <Option
                    key={key}
                    name={name}
                    specialId={key}
                    draggableProps={draggableProps}
                    innerRef={innerRef}
                    dragHandleProps={dragHandleProps}
                    onRemove={() => onRemove(name)}
                  />
                )}
              </Draggable>
            ))}
            {placeholder}
          </Flex>
        )}
      </Droppable>
      <AddOption
        add={add}
      />
    </DragDropContext>
  );
};

export default Options;
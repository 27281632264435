import { useState, useContext, useEffect, useRef } from "react";
import CategoriesContext from "./CategoriesContext";
import { Typography } from "antd";
import { Input } from "antd";
import DragHandler from "../../../../components/Functionality/DragHandler";
import {
  ModalAdditionSectionBottom,
  ModalAdditionSectionMore,
  ModalAdditionalSection,
  ModalCategoryTextInput,
} from "../../../../components/Layout/modal";
import { Body } from "../../../../components/Layout/typography";

const AddSubCategory = ({ categoryId, add }) => {
  const [name, setName] = useState("");
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const onPressEnter = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (name) {
      add({ name });
      setName("");
    }
  };
  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus({
        cursor: "end",
      });
    }
  }, [editing]);
  const { productsNotInSubCategory } = useContext(CategoriesContext);
  const products = productsNotInSubCategory[categoryId] || 0;
  return (
    <ModalAdditionalSection mb0>
      <ModalAdditionSectionBottom>
        <DragHandler disabled />
      </ModalAdditionSectionBottom>
      <Input
        placeholder="Add subcategory (and press enter)"
        onPressEnter={onPressEnter}
        value={name}
        onChange={(e) => setName(e.target.value)}
        ref={inputRef}
        onBlur={() => setEditing(false)}
        style={{ display: editing ? "block" : "none" }}
      />
      <ModalCategoryTextInput
        onClick={() => setEditing(true)}
        style={{ display: editing ? "none" : "flex" }}
      >
        {name ? (
          name
        ) : (
          <Body gray40 sm>
            Add subcategory (and press enter)
          </Body>
        )}
        {products > 0 && (
          <Typography.Text>
            {products} products not in subcategory
          </Typography.Text>
        )}
      </ModalCategoryTextInput>
      <ModalAdditionSectionMore />
    </ModalAdditionalSection>
  );
};

export default AddSubCategory;

import { useState } from 'react';
import { Flex, Button } from 'antd';
import { Input } from '../../../../components/Inputs';
import { ModalCategoryAddButton } from '../../../../components/Layout/modal';

const AddCategory = ({
    add,
}) => {
    const [name, setName] = useState('');
    const onClick = (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (name) {
        add({ name, sub_categories: [] }, 0);
        setName('');
      }
    };
    return (
        <Flex gap={16}>
            <Input label="Category name" onChange={(e) => setName(e.target.value)} value={name} onPressEnter={onClick} placeholder="e.g. Snacks, Burgers, Dessert, Drinks..." />
            <ModalCategoryAddButton>
              <Button type="primary" disabled={!name} onClick={onClick}>
                  Add
              </Button>
            </ModalCategoryAddButton>
        </Flex>
    );
};

export default AddCategory;

export default function useRequiredFieldsFilled(values) {
  if (!values) {
    return false;
  }
  if (!values.name) {
    return false;
  }
  if (!values.price) {
    return false;
  }
  let retVal = true;
  values.sections.forEach(
    (section) => {
      if (!section.name) {
        retVal = false;
      }
      section.options.forEach(
        (item) => {
          if (!item.name) {
            retVal = false;
          }
        },
      );
    },
  );
  return retVal;
};

import { CrudFilters } from "@refinedev/core";

export const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: { [key: string]: string } = {};

  if (filters) {
    filters.forEach((filter) => {
      if (filter.operator === "or" || filter.operator === "and") {
        throw new Error(
          `[@refinedev/simple-rest]: \`operator: ${filter.operator}\` is not supported. You can create custom data provider. https://refine.dev/docs/api-reference/core/providers/data-provider/#creating-a-data-provider`
        );
      }

      if ("field" in filter) {
        const { field, value } = filter;
        if (field === "q") {
          queryFilters[field] = value;
          return;
        }
        
        queryFilters[`filter[${field}]${Array.isArray(value) ? '[]' : ''}`] = value;

        /* 
        const mappedOperator = mapOperator(operator);
        queryFilters[`${field}${mappedOperator}`] = value;
        */
      }
    });
  }

  return queryFilters;
};

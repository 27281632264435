import { useState, useRef } from 'react';
import { Input, CurrencyInput } from '../../../../components/Inputs';
import DragHandler from '../../../../components/Functionality/DragHandler';
import { ModalAdditionSectionBottom, ModalAdditionSectionMore, ModalAdditionalSection } from '../../../../components/Layout/modal';

const AddSubCategory = ({ add }) => {
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const nameRef = useRef();
    const onPressEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (name) {
        add({ name, price: price || 0 });
        setName('');
        setPrice('');
        if (nameRef.current) {
          nameRef.current.focus();
        }
      }
    };
    return (
      <ModalAdditionalSection>
        <ModalAdditionSectionBottom>
          <DragHandler disabled />
        </ModalAdditionSectionBottom>
        <Input ref={nameRef} value={name} onChange={(e) => setName(e.target.value)} onPressEnter={onPressEnter} />
        <CurrencyInput value={price} onChange={setPrice} onPressEnter={onPressEnter} />
        <ModalAdditionSectionBottom onClick={(e) => { onPressEnter(e); } }>
          <div>
            <ModalAdditionSectionMore add>
              Add
            </ModalAdditionSectionMore>
          </div>
        </ModalAdditionSectionBottom>
      </ModalAdditionalSection>
    );
};

export default AddSubCategory;

import { Input, Select } from '../../../../components/Inputs';
import { Dropdown, Form, Divider } from 'antd';
import { YumyIconRadioChecked } from '../../../../components/YumyIcons';
import { useHandleClickOutside } from '../../../../lib/hooks';
import Options from './Options';
import DragHandler from '../../../../components/Functionality/DragHandler';
import Collapser from '../../../../components/Collapser';
import { ModalAdditionSectionBottom, ModalAdditionSectionMore, ModalAdditionalSection, ModalOptions, ModalOptionsTitle } from '../../../../components/Layout/modal';

const Section = ({
  name,
  onRemove,
  draggableProps,
  dragHandleProps,
  innerRef,
  showingOptions,
  setShowing,
  unsetShowing,
  showingAllOptions,
  showingAnyOption,
}) => {
  const wrapperRef = useHandleClickOutside(unsetShowing, showingOptions);
  const sectionName = Form.useWatch(['sections', name, 'name']);
  const extraDropdownItems = [
    !showingOptions ? (
      {
        key: 'SHOW',
        label: 'Show options',
        onClick: () => { setShowing(); },
      }
    ) : (
      {
        key: 'UNSET_SHOW',
        label: 'Hide options',
        onClick: () => { unsetShowing(); },
      }
    )
  ];
  const dropdownItems = [{
    label: sectionName,
    key: '1',
    type: 'group',
    children: [
      ...(showingAllOptions ? [] : extraDropdownItems),
      {
        key: 'DELETE',
        label: 'Delete',
        onClick: () => { onRemove(); if (showingOptions) { unsetShowing(); } },
        danger: true,
      }
    ],
  }];
  return (
    <div {...draggableProps} ref={(n) => { innerRef(n); wrapperRef.current = n; }}>
      <ModalAdditionalSection>
        <ModalAdditionSectionBottom>
          <Input type="hidden" name={[name, 'id']} />
          <DragHandler disabled={showingOptions || showingAllOptions || showingAnyOption} {...dragHandleProps} disabledTooltip="Hide options to drag" />
        </ModalAdditionSectionBottom>
        <Input label={"Section " + (name + 1)} name={[name, 'name']} />
        <Select
          options={[
            {label: 'Single', value: 'SINGLE' },
            { label: 'Multi select', value: 'MULTIPLE' }
          ]}
          label="Type"
          name={[name, 'type']}
          menuItemSelectedIcon={<YumyIconRadioChecked style={{ fontSize: '16px' }} />}
        />
        <Dropdown menu={{ items: dropdownItems }} trigger={['click']}>
          <ModalAdditionSectionBottom>
            <ModalAdditionSectionMore onClick={(e) => e.preventDefault()}>
              More
            </ModalAdditionSectionMore>
          </ModalAdditionSectionBottom>
        </Dropdown>
      </ModalAdditionalSection>
      <Collapser open={showingOptions || showingAllOptions}>
        <ModalOptions>
          <ModalOptionsTitle>Options</ModalOptionsTitle>
          <Form.List name={[name, 'options']}>
            {(fields, { add, remove, move }) => (
              <Options fields={fields} add={add} remove={remove} move={move} />
            )}
          </Form.List>
          <Divider />
        </ModalOptions>
      </Collapser>
    </div>
  );
};

export default Section;
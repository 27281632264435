import { useRef, useEffect, useState, useContext } from 'react';
import { Input } from 'antd';
import { createInput } from '../../../../components/Inputs';
import CategoriesContext from './CategoriesContext';
import { ModalCategoryTextInput } from '../../../../components/Layout/modal';
import { Body } from '../../../../components/Layout/typography';

const CategoryNameInput = ({
    value,
    onChange,
    subCategoryId,
}) => {
  const [editing, setEditing] = useState(false);
  const onActualStopEdit = (e) => {
    e.stopPropagation();
    setEditing(false);
  };
  const inputRef = useRef();
  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus({
        cursor: 'end',
      });
    }
  }, [editing]);
  const { productsInSubCategory } = useContext(CategoriesContext);
  const products = productsInSubCategory[subCategoryId] || 0;
    return (
        <>
          <Input
            ref={inputRef}
            onChange={onChange}
            value={value}
            style={{ display: editing ? 'block' : 'none' }}
            onPressEnter={onActualStopEdit}
          />
          <ModalCategoryTextInput style={{ display: editing ? 'none' : 'flex' }} onClick={() => setEditing(true)}>
            {value}
            {products > 0 && (
              <Body>
                {products}
              </Body>
            )}
          </ModalCategoryTextInput>
        </>
    );
};

export default createInput(CategoryNameInput);

import { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMenu } from '../../Controllers/MenuContext';
import hash from 'object-hash';
import deepEqual from 'deep-equal';

const createInitialValues = {
  name: '',
  description: '',
  active: false,
  price: undefined,
  category_id: undefined,
  sub_category_id: undefined,
  sections: [],
  image: undefined,
};

const createInitialValuesFromNothing = (uncategorizedId) => ({
  name: '',
  description: '',
  active: false,
  price: undefined,
  category_id: uncategorizedId,
  sub_category_id: undefined,
  sections: [],
  image: undefined,
});
const createInitialValuesFromProduct = product => ({
  ...Object.keys(createInitialValues).reduce((acc, key) => ({
    ...acc,
    [key]: product[key],
  }), {}),
  image: product.image && {
    id: product.image.id,
    url: product.image.url,
    name: product.image.name,
  },
});

const ModalController = ({ children }) => {

  const { products, categories, fullyLoaded } = useMenu();
  const { productId } = useParams();
  const [temporaryProduct, setTemporaryProduct] = useState(null);
  const product = useMemo(
    () => temporaryProduct || products.find((p) => p.id == productId),
    [products, productId, temporaryProduct],
  );
  const uncategorizedId = useMemo(
    () => (categories.find(({ uncategorized }) => uncategorized) || {}).id,
    [categories],
  );
  const initialValues = useMemo(
    () => product ? createInitialValuesFromProduct(product) : createInitialValuesFromNothing(uncategorizedId),
    [product, uncategorizedId],
  );
  useEffect(() => {
    if (temporaryProduct) {
      const corrospondingProduct = products.find((p) => p.id == temporaryProduct.id);
      if (deepEqual(corrospondingProduct, temporaryProduct)) {
        setTemporaryProduct(null);
      }
    }
  }, [temporaryProduct, products]);
  if (!fullyLoaded) {
    return null;
  }
  return children({
    props: {
      formController: { initialValues, setTemporaryProduct, productId },
    },
    key: hash(initialValues),
  });
};

export default ModalController;

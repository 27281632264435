import { useEffect, useState } from 'react';
import { useGo } from '@refinedev/core';
import { useLocation } from 'react-router-dom'; // Importing useLocation from react-router-dom
import { YumyIconQR } from "../../components/YumyIcons";
import { DropdownButton, DropdownButtonInner, DropdownButtonText, Separator } from "./DropDownStyles";

const QrLink = () => {
  const go = useGo();
  const location = useLocation(); // Getting location object
  const [isOpen, setIsOpen] = useState(false); // State to control Dropdown

  // Effect to check if the last part of the URL is /kds and set isOpen accordingly
  useEffect(() => {
    const lastPathSegment = location.pathname.endsWith('/qr-codes');
    setIsOpen(lastPathSegment);
  }, [location]); // Dependency on location to re-evaluate when it changes

  return (
    <div>
      <DropdownButton isOpen={isOpen} onClick={(e) => { e.stopPropagation(); go({ to: 'qr-codes' })}}>
        <DropdownButtonInner>
          <YumyIconQR />
          <DropdownButtonText>QR</DropdownButtonText>
        </DropdownButtonInner>
      </DropdownButton>
      <Separator />
    </div>
  );
};

export default QrLink;

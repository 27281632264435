

export const formatMoney = (money) => {
    // Check if the input is a valid number
    if (typeof money !== 'number' || isNaN(money)) {
        return 'Invalid input';
    }

    // Use toLocaleString for formatting with commas and fixed to 2 decimal places
    const formattedMoney = money.toLocaleString('en-US', {
        style: 'currency',
        currency: 'DKK',
        currencyDisplay: 'name',
    }).replace('Danish kroner', 'kr');
    return formattedMoney;
};

import Collapser from "../../../../components/Collapser";
import DroppableController from "./DroppableController";
import { Draggable } from "react-beautiful-dnd";
import { useMenu } from './MenuContext';
import dayjs from "dayjs";
import { formatMoney } from "../../../../lib/helpers/format";


const ProductRowController = ({
  collapsable,
  orderable,
  opened,
  onToggleProduct,
  selectedProducts,
  products,
  orderChangedNotification,
  children,
}) => {
  const { categories } = useMenu();
  if (orderable) {
    const content = (
      <DroppableController
        onToggleProduct={onToggleProduct}
        selectedProducts={selectedProducts}
        products={products}
        orderChangedNotification={orderChangedNotification}
      >
        {({ droppableProps, placeholder, innerRef, products, ordering }) => (
          <div ref={innerRef} {...droppableProps}>
            {products.map((product, index) => (
              <Draggable draggableId={`${product.id}`} index={index} key={product.id}>
                {({ draggableProps, dragHandleProps, innerRef }) => (
                  <div ref={innerRef} {...draggableProps}>
                      {children({
                        key: product.id,
                        product: {
                          ...product,
                          created: dayjs(product.created_at).format('DD/MM/YY'),
                          formattedPrice: formatMoney(product.price),
                          category: categories.find((c) => c.id === product.category_id)?.name,
                        },
                        ordering,
                        props: {
                          dragHandle: {
                            ...dragHandleProps,
                            disabled: Object.keys(selectedProducts).length > 0 || ordering,
                          },
                          checkbox: {
                            checked: !!selectedProducts[product.id],
                            onChange: () => onToggleProduct(product.id),
                          },
                        },
                      })}
                  </div>
                )}
                </Draggable>
            ))}
            {placeholder}
          </div>
        )}
      </DroppableController>
    );
    if (!collapsable) {
      return content;
    }
    return (
      <Collapser open={opened}>
        {content}
      </Collapser>
    );
  }
  return products.map((product) => children({
    key: product.id,
    product: {
      ...product,
      created: dayjs(product.created_at).format('DD/MM/YY'),
      formattedPrice: formatMoney(product.price),
      category: categories.find((c) => c.id === product.category_id)?.name,
    },
    props: {
      checkbox: {
        checked: !!selectedProducts[product.id],
        onChange: () => onToggleProduct(product.id),
      },
    },
  }));
};

export default ProductRowController;

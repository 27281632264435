

const Terms = () => {
  return (
    <div>
      <h1>Terms</h1>
    </div>
  );
}

export default Terms;
import ImageController from "./Controllers/ImageController";
import ProductFormController from "./Controllers/ProductFormController";
import ButtonSectionController from "./Controllers/ButtonSectionController";
import ModalController from "./Controllers/ModalController";
import Form from "../../../../lib/Form";
import { Input, TextArea, CurrencyInput } from "../../../../components/Inputs";
import CategorySelect from "./CategorySelect";
import SubCategorySelect from "./SubCategorySelect";
import { Body } from "../../../../components/Layout/typography";
import { FormContent, Label } from "../../../../components/Layout/forms";
import {
  ModalMain,
  ModalHeader,
  ModalImage,
  ModalImageContent,
  YumyModal,
  ModalInnerWrap,
  ModalDouble,
  ModalContent,
  ModalAdditions,
  ModalHeaderButtons,
  ModalImageInner,
} from "../../../../components/Layout/modal";
import { Switch } from "../../../../components/Inputs";
import { Button, Popconfirm, Modal, Tooltip, Flex, Upload } from "antd";
import { Page, colors } from "../../../../components/Layout/template";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Sections from "./Sections";

export default function ProductForm() {
  return (
    <>
      <Page modal>
        <YumyModal>
          <ModalController>
            {({ props, key }) => (
              <ProductFormController key={key} {...props.formController}>
                {({
                  key,
                  props,
                  edit,
                  product,
                  showingAllOptions,
                  setShowingAllOptions,
                }) => (
                  <Form {...props.form} key={key}>
                    <ModalInnerWrap>
                      <ModalHeader>
                        <h4>{edit ? "Edit product" : "Add product"}</h4>
                        <ButtonSectionController {...props.buttonSection}>
                          {({ props, hasChanges, edit }) => (
                            <ModalHeaderButtons>
                              <Switch
                                name="active"
                                checkedChildren="Active"
                                unCheckedChildren="Draft"
                                labelLeft
                                noLabelHeight
                              />
                              <Flex gap={8}>
                                <Tooltip {...props.saveTooltip}>
                                  <Button
                                    className="action"
                                    type="primary"
                                    htmlType="submit"
                                    {...props.save}
                                  >
                                    {!edit && "Save"}
                                    {edit && (hasChanges ? "Save" : "Saved")}
                                  </Button>
                                </Tooltip>
                                {hasChanges && (
                                  <Popconfirm
                                    title="Cancel edit"
                                    description={
                                      <>
                                        <p>
                                          Possible edits will be lost.
                                          <br />
                                          Are you sure you want to cancel?
                                        </p>
                                      </>
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                    {...props.popConfirm}
                                  >
                                    <Button className="action">Cancel</Button>
                                  </Popconfirm>
                                )}
                                {!hasChanges && (
                                  <Button {...props.close} className="action">
                                    Close
                                  </Button>
                                )}
                              </Flex>
                            </ModalHeaderButtons>
                          )}
                        </ButtonSectionController>
                      </ModalHeader>
                      <FormContent modal>
                        <ModalMain>
                          <ModalImage>
                            <Label>Product Image</Label>
                            <ImageController name="image">
                              {({
                                uploadProps,
                                showUploader,
                                previewProps,
                                imageSrc,
                              }) => (
                                <ModalImageInner>
                                  <Upload
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    {...uploadProps}
                                  >
                                    {showUploader && (
                                      <ModalImageContent>
                                        <PlusOutlined />
                                        <Body>
                                          Click or drag
                                          <br />
                                          image to upload
                                        </Body>
                                      </ModalImageContent>
                                    )}
                                  </Upload>
                                  <Modal {...previewProps}>
                                    <img
                                      alt="example"
                                      style={{ width: "100%" }}
                                      src={imageSrc}
                                    />
                                  </Modal>
                                </ModalImageInner>
                              )}
                            </ImageController>
                          </ModalImage>
                          <ModalContent>
                            <ModalDouble>
                              <Input
                                label="Product Name"
                                name="name"
                                placeholder="Add name (required)"
                              />
                              <CurrencyInput
                                label="Product Price"
                                name="price"
                                topRight={
                                  <>
                                    <span>VAT</span>
                                    <Tooltip
                                      title="All prices include VAT."
                                      placement="top"
                                      color={colors.yumyGreen}
                                    >
                                      <Flex
                                        gap={8}
                                        justify="center"
                                        align="center"
                                      >
                                        <InfoCircleOutlined
                                          style={{ fontSize: "14px" }}
                                        />
                                      </Flex>
                                    </Tooltip>
                                  </>
                                }
                              />
                            </ModalDouble>
                            <ModalDouble>
                              <CategorySelect
                                label="Category"
                                name="category_id"
                              />
                              <SubCategorySelect
                                label="Subcategory"
                                name="sub_category_id"
                                form={props.form.form}
                                topRight={
                                  <Tooltip
                                    title="Use subcategories to organize items within a category, such as dividing 'Drinks' into 'Soft Drinks', 'Coffees', and 'Wine'."
                                    placement="top"
                                    color={colors.yumyGreen}
                                  >
                                    <InfoCircleOutlined
                                      style={{ fontSize: "14px" }}
                                    />
                                  </Tooltip>
                                }
                              />
                            </ModalDouble>
                            <TextArea
                              label="Description"
                              name="description"
                              placeholder="Tell your customers about your delicious product"
                            />
                          </ModalContent>
                        </ModalMain>
                        <ModalMain>
                          <ModalAdditions>
                            <Form.List name="sections">
                              {(fields, { add, remove, move }) => (
                                <Sections
                                  add={add}
                                  remove={remove}
                                  move={move}
                                  fields={fields}
                                  showingAllOptions={showingAllOptions}
                                  setShowingAllOptions={setShowingAllOptions}
                                />
                              )}
                            </Form.List>
                          </ModalAdditions>
                        </ModalMain>
                      </FormContent>
                    </ModalInnerWrap>
                  </Form>
                )}
              </ProductFormController>
            )}
          </ModalController>
        </YumyModal>
      </Page>
    </>
  );
}

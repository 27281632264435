import { useForm } from '../../../../..//lib/refine';
import { useGo, useInvalidate } from '@refinedev/core';

export default function QRFormController({
  children,
  createNotification,
  updateNotification,
  qrCodeId,
  initialValues,
  setTemporaryQRCode,
}) {
  const go = useGo();
  const invalidate = useInvalidate();
  const {
    formProps,
  } = useForm({
    resource: 'restaurants/qr-codes',
    action: qrCodeId ? 'edit' : 'create',
    successNotification: qrCodeId ? updateNotification : createNotification,
    id: qrCodeId,
    redirect: false,
    onMutationSuccess: (p) => {
      setTemporaryQRCode(p.data);
      if (!qrCodeId) {
        go({ to: `../edit-qr-code/${p.data.id}` });
      }
      invalidate({
        resource: 'restaurants/qr-codes',
        invalidates: ['list'],
      });
    },
  });
  return children({
    props: {
      form: {
        ...formProps,
        initialValues,
      },
      buttonSection: {
        initialValues,
        edit: !!qrCodeId,
      },
    },
    edit: Boolean(qrCodeId),
  });
};

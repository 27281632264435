import { HttpError } from "@refinedev/core";
import axios, { AxiosRequestConfig } from "axios";
import { getToken } from '../../providers/useAuthProvider';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };
    // If error is not logged in anymore, remove our token

    return Promise.reject(customError);
  }
);

axiosInstance.interceptors.request.use(
  (request: AxiosRequestConfig) => {
    const token = getToken();
    if (token) {
      const { access_token } = token;
      if (!request.headers) {
        request.headers = {};
      }
      request.headers.Authorization = `Bearer ${access_token}`;
    }
    return request;
  },
  (error) => Promise.reject(error),
)

export { axiosInstance };

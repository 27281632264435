
import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useLayout } from '../../../../layouts/Restaurant/LayoutContext';
import hash from 'object-hash';

const createInitialValuesFromRestaurant = ({
  time_settings,
  print_orders,
  auto_accept,
}) => ({
  time_settings,
  print_orders,
  auto_accept,
});


const ModalController = ({ children, create, closeModal }) => {
  const { restaurantId } = useParams();
  const { fullyLoaded, restaurant } = useLayout();
  const [formKeyIndex, setFormKeyIndex] = useState(0);
  const initialValues = useMemo(
    () => restaurant ? createInitialValuesFromRestaurant(restaurant) : {},
    [restaurant],
  );
  if (!fullyLoaded) {
    return null;
  }
  // 
  const onActualCloseModal = () => {
    closeModal();
    setFormKeyIndex((prev) => prev + 1);
  };
  return children({
    formKey: `${hash(initialValues)}_${formKeyIndex}`,
    props: {
      timeSettings: {
        options: [
           { value: 'DONT_SHOW', label: 'Don\'t show' },
           { value: 'FIVE_MINUTES', label: '5 min.' },
           { value: 'TEN_MINUTES', label: '10 min.' },
           { value: 'FIFTEEN_MINUTES', label: '15 min.' },
           { value: 'TWENTY_MINUTES', label: '20 min.' },
           { value: 'TWENTY_FIVE_MINUTES', label: '25 min.' },
           { value: 'THIRTY_MINUTES', label: '30 min.' },
           { value: 'MORE_THAN_THIRTY_MINUTES', label: '30+ min.' },
        ]
      },
      buttonSection: {
        initialValues,
        create,
        closeModal: onActualCloseModal,
      },
      formController: {
        restaurantId,
        initialValues,
        create,
        closeModal: onActualCloseModal,
      },
    },
  })
};

export default ModalController;

import { createContext, useContext } from 'react';


const LayoutContext = createContext(null);

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout must be used within a LayoutContextProvider');
  }
  return context;
}

export default LayoutContext;
import { Form } from 'antd';
import useHasChanges from "./useHasChanges";
import useRequiredFieldsFilled from "./useRequiredFieldsFilled";


export default function ButtonSectionController({
  initialValues,
  children,
  closeModal,
}) {
  const values = {
    time_settings: Form.useWatch(['time_settings']),
    auto_accept: Form.useWatch(['auto_accept']),
    print_orders: Form.useWatch(['print_orders']),
  };
  const hasChanges = useHasChanges({ initialValues, values });
  const requiredFieldsFilled = useRequiredFieldsFilled(values);
  return children({
    props: {
      save: {
        disabled: !hasChanges || !requiredFieldsFilled,
      },
      popConfirm: {
        onConfirm: closeModal,
      },
      close: {
        onClick: closeModal,
      },
    },
    hasChanges,
  });
}
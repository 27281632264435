import FormController from "./Controllers/FormController";
import ModalController from "./Controllers/ModalController";
import {
  Input,
  Select,
  Switch,
  TimePicker,
  CurrencyInput,
} from "../../../components/Inputs";
import ValueSpan from "./Controllers/ValueSpan";
import {
  Button,
  Flex,
  Popconfirm,
  Form,
  TimePicker as AntdTimepicker,
  Row,
} from "antd";
import {
  YumyIconClose,
  YumyIconRadioChecked,
} from "../../../components/YumyIcons";
import ButtonSectionController from "./Controllers/ButtonSectionController";
import OrderingController from "./Controllers/OrderingController";
import WhereController from "./Controllers/WhereController";
import TimeValueController from "./Controllers/TimeValueController";
import AddTimeValueController from "./Controllers/AddTimeValueController";
import {
  MenuSettingsButtonsWrapper,
  MenuSettingsHeader,
  MenuSettingsInfoChoices,
  MenuSettingsSubTitle,
  MenuSettingsTab,
  MenuSettingsTabs,
  MenuSettingsTimeCol,
  MenuSettingsTimeSlot,
  MenuSettingsTitle,
} from "./menuSettingsStyles";
import { FormContent, Label } from "../../../components/Layout/forms";
import { Body } from "../../../components/Layout/typography";

const MenuSettings = ({ create = false, closeModal }) => (
  <ModalController create={create} closeModal={closeModal}>
    {({ props, formKey }) => (
      <FormController key={formKey} {...props.formController}>
        {({ formProps }) => (
          <Form {...formProps}>
            <FormContent modal>
              <MenuSettingsHeader>
                <MenuSettingsTitle>
                  {create ? "Add Menu" : "Menu settings"}
                </MenuSettingsTitle>
                <ButtonSectionController {...props.buttonSection}>
                  {({ props, hasChanges }) => (
                    <MenuSettingsButtonsWrapper>
                      <Button
                        id="menuSettingsSave"
                        className="action"
                        type="primary"
                        htmlType="submit"
                        {...props.save}
                      >
                        {create || hasChanges ? "Save" : "Saved"}
                      </Button>
                      {hasChanges && (
                        <Popconfirm
                          title="Cancel edit"
                          description={
                            <>
                              <p>
                                Possible edits will be lost.
                                <br />
                                Are you sure you want to cancel?
                              </p>
                            </>
                          }
                          okText="Yes"
                          cancelText="No"
                          {...props.popConfirm}
                        >
                          <Button id="menuSettingsCancel" className="action">
                            Cancel
                          </Button>
                        </Popconfirm>
                      )}
                      {!hasChanges && (
                        <Button
                          id="menuSettingsClose"
                          {...props.close}
                          className="action"
                        >
                          Close
                        </Button>
                      )}
                    </MenuSettingsButtonsWrapper>
                  )}
                </ButtonSectionController>
              </MenuSettingsHeader>
              <MenuSettingsTabs>
                <MenuSettingsTab {...props.info.button}>Info</MenuSettingsTab>
                <MenuSettingsTab {...props.ordering.button}>
                  Ordering
                </MenuSettingsTab>
              </MenuSettingsTabs>
              <div {...props.info.div}>
                <MenuSettingsSubTitle>Menu Information</MenuSettingsSubTitle>
                <MenuSettingsInfoChoices>
                  <Flex vertical gap={8}>
                    <Input label="Menu name" name="name" />
                    <Body xs>Not visible to customers</Body>
                    <CurrencyInput label="Bag price" name="bag_price" />
                    <Body xs>
                      Enter the cost for an additional bag. This amount will be
                      added to the total at checkout.
                    </Body>
                  </Flex>
                  <Flex vertical gap={8}>
                    <Select
                      name="where"
                      label="Eat-in / Takeaway"
                      options={[
                        { label: "Allow for both", value: "BOTH" },
                        { label: "Eat-in", value: "EAT_IN" },
                        { label: "Takeaway", value: "TAKEAWAY" },
                      ]}
                      menuItemSelectedIcon={
                        <YumyIconRadioChecked style={{ fontSize: "14px" }} />
                      }
                    />
                    <Body xs>
                      <WhereController>
                        {({ where }) => (
                          <>
                            {where === "BOTH" && (
                              <>
                                Your customers can choose between Eat-in or
                                Takeaway
                              </>
                            )}
                            {where === "TAKEAWAY" && (
                              <>All orders will be shown as Takeaway</>
                            )}
                            {where === "EAT_IN" && (
                              <>All orders will be shown as Eat-in</>
                            )}
                          </>
                        )}
                      </WhereController>
                    </Body>
                  </Flex>
                </MenuSettingsInfoChoices>
                <hr className="w-full border-0 border-t border-grayScale900-8 my-4" />

                <Popconfirm
                  title="Delete menu"
                  description={
                    <>
                      <p>All products will be lost</p>
                    </>
                  }
                  okText="Yes"
                  cancelText="No"
                  onConfirm={props.deleteMenu.onClick}
                >
                  <Button className="text-yumyRed bg-grayScale900-4 !py-2 !h-auto !px-5 !rounded-full !border-none hover:!bg-yumyRed hover:!text-white-100">
                    Delete menu
                  </Button>
                </Popconfirm>
              </div>
              <div {...props.ordering.div}>
                <OrderingController form={formProps.form}>
                  {({ settingsType, dayArray, enabled }) => (
                    <div>
                      <MenuSettingsSubTitle>
                        Online ordering
                      </MenuSettingsSubTitle>
                      {settingsType !== "DAILY" && (
                        <>
                          <div>
                            <Switch
                              label="Online ordering"
                              name={["enabled"]}
                              labelLeft
                              noLabelHeight
                            />
                            {enabled && (
                              <p className="mt-4 text-grayScale900-56 text-xs leading-180">
                                Your customers can order through QR Menu
                              </p>
                            )}
                            {!enabled && (
                              <p className="mt-4 text-grayScale900-56 text-xs leading-180">
                                When disabled your customers can only see your
                                menu, but not order.
                              </p>
                            )}
                            {/* <InputNumber label="Ready minutes after order" name={['order_extra_minutes']} labelLeft noLabelHeight /> */}
                          </div>
                          <div style={{ marginTop: "16px", display: enabled ? undefined : 'none' }}>
                            <Select
                              label="From"
                              options={[
                                { label: "Everyday", value: "EVERYDAY" },
                                { label: "Custom", value: "DAILY" },
                              ]}
                              name={["settings", "type"]}
                            />
                            <Flex gap={16} style={{ marginTop: "16px" }}>
                              <TimePicker
                                defaultValue="10:00"
                                label="From"
                                name={["settings", "from"]}
                                allowClear={false}
                                showNow={false}
                              />
                              <TimePicker
                                defaultValue="22:00"
                                label="To"
                                name={["settings", "to"]}
                                allowClear={false}
                                showNow={false}
                              />
                            </Flex>
                          </div>
                        </>
                      )}
                      {settingsType === "DAILY" && (
                        <>
                          <div>
                            <Switch
                              label="Online ordering"
                              name={["enabled"]}
                              labelLeft
                              noLabelHeight
                            />
                            {enabled && (
                              <p className="mt-4 text-grayScale900-56 text-xs leading-180">
                                Your customers can order through QR Menu
                              </p>
                            )}
                            {!enabled && (
                              <p className="mt-4 text-grayScale900-56 text-xs leading-180">
                                When disabled your customers can only see your
                                menu, but not order.
                              </p>
                            )}
                            {/* <InputNumber label="Ready minutes after order" name={['order_extra_minutes']} labelLeft noLabelHeight /> */}
                          </div>
                            <>
                              <div style={{ marginTop: "16px", dispaly: enabled ? undefined : 'none' }}>
                                <Select
                                  label="From"
                                  options={[
                                    { label: "Everyday", value: "EVERYDAY" },
                                    { label: "Custom", value: "DAILY" },
                                  ]}
                                  name={["settings", "type"]}
                                />
                              </div>
                              <div style={{ marginTop: "16px" }}>
                                <Form.Item name={["settings", "times"]}>
                                  <Row gutter={[16, 16]}>
                                    {dayArray.map(({ dayName, text }) => (
                                      <>
                                        <MenuSettingsTimeCol span={12}>
                                          <Form.List
                                            name={[
                                              "settings",
                                              "times",
                                              dayName,
                                            ]}
                                            noStyle
                                          >
                                            {(
                                              fields,
                                              { add, remove, move }
                                            ) => {
                                              console.log(
                                                "What is the fields?",
                                                fields
                                              );
                                              return (
                                                <Flex gap={8} vertical>
                                                  <b>{text}</b>
                                                  <div className="flex flex-wrap gap-1 min-h-6 items-center max-w-full">
                                                    {fields.map(
                                                      ({ name, key }) => (
                                                        <TimeValueController
                                                          key={key}
                                                          name={name}
                                                          dayName={dayName}
                                                          remove={remove}
                                                        >
                                                          {({
                                                            onRemove,
                                                            name,
                                                          }) => (
                                                            <MenuSettingsTimeSlot
                                                              onClick={onRemove}
                                                            >
                                                              <span>
                                                                <Form.Item
                                                                  name={[
                                                                    name,
                                                                    "from",
                                                                  ]}
                                                                  noStyle
                                                                >
                                                                  <ValueSpan />
                                                                </Form.Item>
                                                                &nbsp;-&nbsp;
                                                                <Form.Item
                                                                  name={[
                                                                    name,
                                                                    "to",
                                                                  ]}
                                                                  noStyle
                                                                >
                                                                  <ValueSpan />
                                                                </Form.Item>
                                                              </span>{" "}
                                                              <YumyIconClose />
                                                            </MenuSettingsTimeSlot>
                                                          )}
                                                        </TimeValueController>
                                                      )
                                                    )}
                                                  </div>
                                                  <AddTimeValueController
                                                    add={add}
                                                  >
                                                    {({ props }) => (
                                                      <Flex
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        justify="space-between"
                                                        gap={8}
                                                      >
                                                        <Flex
                                                          vertical
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <Label>From:</Label>
                                                          <AntdTimepicker
                                                            {...props.from}
                                                            showNow={false}
                                                          />
                                                        </Flex>
                                                        <Flex
                                                          vertical
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <Label>To:</Label>
                                                          <AntdTimepicker
                                                            {...props.to}
                                                            showNow={false}
                                                          />
                                                        </Flex>
                                                      </Flex>
                                                    )}
                                                  </AddTimeValueController>
                                                </Flex>
                                              );
                                            }}
                                          </Form.List>
                                        </MenuSettingsTimeCol>
                                      </>
                                    ))}
                                  </Row>
                                </Form.Item>
                              </div>
                            </>
                        </>
                      )}
                    </div>
                  )}
                </OrderingController>
              </div>
            </FormContent>
          </Form>
        )}
      </FormController>
    )}
  </ModalController>
);

export default MenuSettings;

import { useState, useEffect } from "react";
import { useMenu } from "../Controllers/MenuContext";
import { useCreate, useInvalidate } from "@refinedev/core";
import createInput from "../../../../components/Inputs/createInput";
import { useParams } from "react-router-dom";
import { Divider, Space, Button, Select, Empty } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Body } from "../../../../components/Layout/typography";
import { YumyIconRadioChecked } from "../../../../components/YumyIcons";

const AddBar = ({ menuId, addItem, name }) => {
  if (!name) {
    return (
      <>
        <Divider style={{ margin: "8px 0" }} />
        <Body extraClass="pt-0 px-3 pb-1">
          Begin typing to create a new category
        </Body>
      </>
    );
  }
  return (
    <>
      <Divider style={{ margin: "8px 0" }} />
      <Space style={{ padding: "0 8px 4px" }}>
        <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
          Add {name}
        </Button>
      </Space>
    </>
  );
};

const CategorySelect = ({ onChange, value, ...props }) => {
  const { categorySelectProps } = useMenu();
  const [previousName, setPreviousName] = useState("");
  const [name, setName] = useState("");
  const onActualSetName = (v) => {
    setName(v);
    setPreviousName(name);
  };
  const { menuId } = useParams();
  const { mutate: onCreate } = useCreate();
  const invalidate = useInvalidate();
  const [isAdding, setIsAdding] = useState(false);
  const [newlyCreatedCategory, setNewlyCreatedCategory] = useState(null);
  const actualOnChange = (v) => {
    onActualSetName("");
    onChange?.(v);
  };
  const addItem = () => {
    setIsAdding(true);
    onCreate(
      {
        resource: "restaurants/menus/categories",
        values: { name },
        meta: { id: menuId },
      },
      {
        onSuccess: (r) => {
          invalidate({
            resource: "restaurants/menus/categories",
            invalidates: ["list"],
          });
          invalidate({
            resource: "restaurants/menus/sub-categories",
            invalidates: ["list"],
          });
          console.log("Setting newly added category", r.data.id);
          setNewlyCreatedCategory(r.data.id);
        },
        onError: () => {
          setIsAdding(false);
        },
      }
    );
  };
  useEffect(() => {
    if (
      newlyCreatedCategory &&
      categorySelectProps.options.some((o) => o.value === newlyCreatedCategory)
    ) {
      setNewlyCreatedCategory(null);
      actualOnChange(newlyCreatedCategory);
      setIsAdding(false);
    }
  }, [newlyCreatedCategory, categorySelectProps.options]);
  const { options } = categorySelectProps;
  const remainingOptions = options.filter((o) =>
    o.label.toLowerCase().includes(name.toLowerCase())
  );
  const sortedRemainingOptions = remainingOptions.sort((a, b) =>
    a.label.toLowerCase().localeCompare(b.label.toLowerCase())
  );
  const containsExactOption = options.some(
    (o) => o.label.toLowerCase() === name.toLowerCase()
  );
  return (
    <Select
      {...props}
      {...categorySelectProps}
      onSearch={(v) => {
        onActualSetName(v);
      }}
      searchValue={name}
      onChange={actualOnChange}
      notFoundContent={<Empty description="No categories" />}
      menuItemSelectedIcon={
        <YumyIconRadioChecked style={{ fontSize: "16px" }} />
      }
      onInputKeyDown={(e) => {
        /* If enter */
        if (e.keyCode === 13 && name) {
          if (sortedRemainingOptions.length === 0) {
            addItem();
          }
        }
      }}
      value={isAdding ? null : value}
      placeholder={isAdding ? previousName : "Choose category"}
      showSearch
      loading={isAdding}
      disabled={props.disabled || categorySelectProps.disabled || isAdding}
      filterOption={(input, option) =>
        (option?.label ?? "")
          .toLowerCase()
          .includes((input || "").toLowerCase())
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase())
      }
      dropdownRender={(menu) => (
        <>
          {menu}
          {!containsExactOption && (
            <AddBar
              menuId={menuId}
              name={name}
              addItem={addItem}
              options={categorySelectProps.options}
            />
          )}
        </>
      )}
    />
  );
};
export default createInput(CategorySelect);

import login, { RedirectToLogin } from './login';
import { Authenticated } from '@refinedev/core';
// Import statements for icons and other components can remain unchanged, as they do not include TypeScript specific syntax.
import { ErrorComponent } from '@refinedev/antd';
import LoggedInPageContainer from '../pages/LogicContainers/LoggedInPageContainer';
import IndexRedirecter from '../pages/LogicContainers/IndexRedirecter';
import MenuRedirecter from '../pages/LogicContainers/MenuRedirecter';
import Onboarding from '../layouts/Onboarding';
import RestaurantLayout from '../layouts/Restaurant';
import RestaurantProducts from '../pages/restaurant/products';
import RestaurantQRCodes from '../pages/restaurant/qr-codes';
import CreateRestaurant from '../pages/creation/CreateRestaurant';
import CreateMenu from '../pages/creation/CreateMenu';
import CreatePlan from '../pages/creation/CreatePlan';
import AddSubscription from '../pages/creation/AddSubscription';
import SubscriptionCompleted from '../pages/creation/SubscriptionCompleted';
import AddMenu from '../pages/restaurant/AddMenu';
import QRCodeForm from '../pages/restaurant/qr-codes/QRForm';
import ProductForm from '../pages/restaurant/products/ProductForm';
import Categories from '../pages/restaurant/products/Categories';
import Account from '../pages/restaurant/account';
import Plan from '../pages/restaurant/plan';
import KDS from '../pages/kds';
import MenuSettings from '../pages/restaurant/menu-settings';
import Admin from '../pages/admin';
import { SubRouteModal } from '../lib/helpers';

const routes = [
  {
    element: (
      <Authenticated fallback={<RedirectToLogin />}>
        <LoggedInPageContainer />
      </Authenticated>
    ),
    children: [
      {
        index: true,
        element: <IndexRedirecter />,
      },
      {
        path: 'admin',
        element: <Admin />,
      },
      {
        path: 'restaurant/:restaurantId',
        element: <RestaurantLayout />,
        children: [
          {
            index: true,
            element: <MenuRedirecter />,
          },
          {
            path: 'menu/:menuId',
            element: (
              <RestaurantProducts>
                <SubRouteModal path={["add-product", 'edit-product/:productId', 'categories', 'settings']} />
              </RestaurantProducts>
            ),
            children: [
              {
                path: 'add-product',
                element: <ProductForm />,
              },
              {
                path: 'edit-product/:productId',
                element: <ProductForm />,
              },
              {
                path: 'categories',
                element: <Categories />,
              },
              {
                path: 'settings',
                element: <MenuSettings />,
              }
            ],
          },
          {
            path: 'add-menu',
            element: <AddMenu />,
          },
          {
            path: 'kds',
            element: <KDS />,
          },
          {
            path: 'qr-codes',
            element: (
              <RestaurantQRCodes>
                <SubRouteModal path={["add-qr-code", 'edit-qr-code/:qrCodeId']} />
              </RestaurantQRCodes>
            ),
            children: [
              {
                path: 'add-qr-code',
                element: <QRCodeForm />,
              },
              {
                path: 'edit-qr-code/:qrCodeId',
                element: <QRCodeForm />,
              },
            ],
          },
          {
            path: 'restaurant-create',
            element: <CreateRestaurant />,
          },
          {
            path: 'account',
            element: <Account />,
          },
          {
            path: 'plan',
            element: <Plan />,
          },
          {
            path: 'settings',
            element: <MenuSettings />,
          },
        ],
      },
      {
        path: '*',
        element: <ErrorComponent />,
      },
    ],
  },
  {
    path: '/onboarding',
    children: [
      {
        element: <Onboarding step="PLAN" />,
        children: [
          {
            path: 'create-plan',
            element: <CreatePlan />,
          },
          {
            path: 'add-subscription/:planId',
            element: <AddSubscription />,
          },
          {
            path: 'add-subscription-completed/:planId',
            element: <SubscriptionCompleted />,
          }
        ],
      },
      {
        element: <Onboarding step="RESTAURANT" />,
        children: [
          {
            path: 'create-restaurant',
            element: <CreateRestaurant />,
          },
          {
            path: 'restaurant/:restaurantId/menu',
            element: <MenuRedirecter />,
          },
        ],
      },
      {
        element: <Onboarding step="MENU" />,
        children: [
          {
            path: 'restaurant/:restaurantId/menu/create',
            element: <CreateMenu />,
          },
        ],
      },
    ],
  },
  ...login,
  {
    path: '*',
    element: <ErrorComponent />,
  },
];

export default routes;

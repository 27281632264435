import { useSelect as useSelectParent } from "@refinedev/core";
import { useSuspense } from './Suspense';


export const useSelect = ({ suspenseKey, ...rest }) => {
  const ret = useSelectParent(rest);
  useSuspense(suspenseKey, ret.isLoading);
  return ret;
};

export default useSelect;
import { useState, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useUpdate } from '../../../../lib/refine';
import deepEqual from 'deep-equal';

const move = (from, to, arr) => {
  const newArr = [...arr];
  const target = newArr[from];
  newArr.splice(from, 1);
  newArr.splice(to, 0, target);
  return newArr;
};

const DroppableController = ({ children, categoryId, subCategoryId, orderChangedNotification, products }) => {
  const { mutate: onUpdate } = useUpdate();
  const [tempProducts, setTempProducts] = useState(null);
  useEffect(() => {
    if (tempProducts && deepEqual(tempProducts.map(({ id }) => id), products.map(({ id }) => id))) {
      setTempProducts(null);
    }
  }, [products, tempProducts]);
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    setTempProducts(move(result.source.index, result.destination.index, products));
    onUpdate({
      resource: 'restaurants/menus/products',
      id: result.draggableId,
      values: {
        order: result.destination.index,
      },
      successNotification: orderChangedNotification,
    }, {
      onMutationError: () => {
        setTempProducts(null);
      },
    });
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`products_${categoryId}_${subCategoryId}`}>
        {({ droppableProps, placeholder, innerRef }) => children({ droppableProps, placeholder, innerRef, products: tempProducts || products, ordering: Boolean(tempProducts) })}
      </Droppable>
    </DragDropContext>
  );
};

export default DroppableController;

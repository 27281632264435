import { createContext, useContext } from 'react';

const MenuContext = createContext(null);

export default MenuContext;

export const useMenu = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error('useMenu must be used within a MenuContextProvider');
  }
  return context;
};

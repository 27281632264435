import { useMemo, useState } from "react";
import hash from "object-hash";
import { Flex, Form, Divider, Button, Popconfirm } from "antd";
import { useMenu } from "../Controllers/MenuContext";
import ButtonSectionController from "./Controllers/ButtonSectionController";
import FormController from "./Controllers/FormController";
import AddCategory from "./AddCategory";
import CategoriesList from "./CategoriesList";
import CategoriesContext from "./CategoriesContext";
import { Page } from "../../../../components/Layout/template";
import {
  ModalHeader,
  ModalInnerWrap,
  YumyModal,
} from "../../../../components/Layout/modal";
import { FormContent } from "../../../../components/Layout/forms";

const Categories = ({}) => {
  const { categories, subCategories, products, fullyLoaded } = useMenu();
  const { initialValues, formKey } = useMemo(() => {
    const initialValues = {
      categories: categories.map(({ id, name }) => ({
        id,
        name,
        sub_categories: subCategories
          .filter(({ category_id }) => category_id == id)
          .map(({ id: subId, name: subName }) => ({
            id: subId,
            name: subName,
          })),
      })),
    };
    return {
      formKey: hash(initialValues),
      initialValues,
    };
  }, [categories, subCategories]);
  const uncategorizedId = useMemo(
    () => (categories.find(({ uncategorized }) => uncategorized) || {}).id,
    [categories]
  );
  const [orphanedProducts, setOrphanedProducts] = useState(0);
  const [deletedSubCategories, setDeletedSubCategories] = useState({});
  const contextValue = useMemo(
    () => ({
      orphanProducts: (amount) =>
        setOrphanedProducts(orphanedProducts + amount),
      deleteSubCategory: (sub_category_id) =>
        setDeletedSubCategories({
          ...deletedSubCategories,
          [sub_category_id]: true,
        }),
      orphanedProducts,
      productsInCategory: products.reduce(
        (acc, { category_id, uncategorized }) => {
          if (!category_id) {
            return acc;
          }
          return {
            ...acc,
            [category_id]: (acc[category_id] || 0) + 1,
          };
        },
        {}
      ),
      productsInSubCategory: products.reduce((acc, { sub_category_id }) => {
        if (sub_category_id) {
          const s = sub_category_id;
          return {
            ...acc,
            [s]: (acc[s] || 0) + 1,
          };
        }
        return acc;
      }, {}),
      productsNotInSubCategory: products.reduce(
        (acc, { category_id, sub_category_id }) => {
          if (
            category_id &&
            (!sub_category_id || deletedSubCategories[sub_category_id])
          ) {
            return {
              ...acc,
              [category_id]: (acc[category_id] || 0) + 1,
            };
          }
          return acc;
        },
        {}
      ),
    }),
    [
      orphanedProducts,
      deletedSubCategories,
      setDeletedSubCategories,
      setOrphanedProducts,
      products,
    ]
  );
  if (!fullyLoaded) {
    return null;
  }
  return (
    <CategoriesContext.Provider value={contextValue}>
      <Page modal>
        <YumyModal>
          <FormController key={formKey}>
            {({ formProps }) => (
              <Form {...formProps} initialValues={initialValues} key={formKey}>
                <ModalInnerWrap>
                  <ModalHeader>
                    <h4>Add/Edit Categories</h4>
                    <ButtonSectionController initialValues={initialValues}>
                      {({ props, hasChanges }) => (
                        <Flex gap={8}>
                          <Button
                            className="action"
                            type="primary"
                            htmlType="submit"
                            {...props.save}
                          >
                            {hasChanges ? "Save" : "Saved"}
                          </Button>
                          {hasChanges && (
                            <Popconfirm
                              title="Cancel edit"
                              description={
                                <>
                                  <p>
                                    Possible edits will be lost.
                                    <br />
                                    Are you sure you want to cancel?
                                  </p>
                                </>
                              }
                              okText="Yes"
                              cancelText="No"
                              {...props.popConfirm}
                            >
                              <Button className="action">Cancel</Button>
                            </Popconfirm>
                          )}
                          {!hasChanges && (
                            <Button {...props.close} className="action">
                              Close
                            </Button>
                          )}
                        </Flex>
                      )}
                    </ButtonSectionController>
                  </ModalHeader>
                  <FormContent modal>
                    <Form.List name={["categories"]}>
                      {(fields, { add, remove, move }) => (
                        <Flex vertical>
                          <AddCategory add={add} />
                          <Divider />
                          <CategoriesList
                            form={formProps.form}
                            uncategorizedId={uncategorizedId}
                            add={add}
                            remove={remove}
                            move={move}
                            fields={fields}
                          />
                        </Flex>
                      )}
                    </Form.List>
                  </FormContent>
                </ModalInnerWrap>
              </Form>
            )}
          </FormController>
        </YumyModal>
      </Page>
    </CategoriesContext.Provider>
  );
};

export default Categories;

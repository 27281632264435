import { useState } from 'react';
import { useMenu } from './MenuContext';

const useCategoryChoices = () => {
  const { categories, products } = useMenu();
  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const activeCategoryName = !activeCategoryId ? 'All' : categories.find((c) => c.id === activeCategoryId)?.name; 
  const categoryChoices = [
    {
      key: 'All',
      name: 'All',
      products: products.length,
      isActive: !activeCategoryId,
      onClick: () => setActiveCategoryId(null),
    },
    ...categories.map((category) => ({
      key: category.id,
      name: category.name,
      isActive: activeCategoryId === category.id,
      onClick: () => setActiveCategoryId(category.id),
      products: products.filter((p) => p.category_id === category.id).length,
    })),
  ];
  console.log('What is categoryChoices?', categoryChoices);
  return { categoryChoices, activeCategoryName, activeCategoryId };
};

export default useCategoryChoices;

import { useState, useEffect } from 'react';
import { App } from 'antd';
import { useMenu } from '../../Controllers/MenuContext';
import env from '../../../../../env';
import { createInput } from '../../../../../components/Inputs';

const ImageController = ({ children, value, onChange: onChangeValue }) => {
  const [fileList, setFileList] = useState([]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  useEffect(() => {
    if (value) {
      setFileList([{ uid: value.id, name: value.name, status: 'done', url: value.url }]);
    } else {
      setFileList([]);
    }
  }, []);
  useEffect(() => {
    const completedFile = fileList.find((file) => file.status === 'done');
    if (completedFile) {
      onChangeValue({
        id: completedFile.uid,
        name: completedFile.name,
        url: completedFile.url,
      });
    } else if (fileList.length === 0) {
      onChangeValue(null);
    }
  }, [fileList]);
  const { restaurantUser } = useMenu();
  const { message } = App.useApp();
  const props = {
    name: 'file',
    multiple: false,
    action: `${env.apiUrl}/api/restaurants/users/${restaurantUser.id}/media`,
    onChange(info) {
      console.log('What is info?', info);
      if (info.fileList.some((file) => file.status === 'uploading')) {
        setUploadingFile(true);
      }
      setFileList(info.fileList);
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        console.log('So what is the good stuff?', info.file.response);
        setFileList([{ url: info.file.response.data.url, uid: info.file.response.data.id, name: info.file.name, status: 'done' }]);
        setUploadingFile(false);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        setUploadingFile(false);
      }
    },
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
    },
    fileList,
    onPreview: (file) => {
      setPreviewOpen(true);
    },
  };
  return (
    children({
      uploadProps: props,
      showUploader: !value && !uploadingFile,
      previewProps: {
        open: previewOpen,
        footer: null,
        onCancel: () => setPreviewOpen(false),
      },
      imageSrc: value?.url,
    })
  );
};

export default createInput(ImageController);

import { useLogin, useLink } from "@refinedev/core";
import { Button } from "antd";
import Form from "../../lib/Form";
import { Input } from "../../components/Inputs";
import {
  FormContent,
  FormWrapper,
  SwitchText,
} from "../../components/Layout/forms";

export default function Forgot() {
  const { mutate: onLogin } = useLogin(); // temporary, mangler funktionalitet
  const Link = useLink();
  return (
    <FormWrapper>
      <FormContent>
        <h1>Forgot Password</h1>
        <Form
          initialValues={{ email: "" }}
          onFinish={(fields) => onLogin(fields)}
        >
          <Input label="Email" name="email" />
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="large"
          >
            Request password reset
          </Button>
        </Form>
        <SwitchText>
          Remembered again? <Link to="/login">Log in</Link>
        </SwitchText>
      </FormContent>
    </FormWrapper>
  );
}

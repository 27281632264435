import { useForm } from "@refinedev/antd";
import { useGo, useOne } from "@refinedev/core";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import Form from "../../lib/Form";
import { Input } from "../../components/Inputs";
import { Body, Overline } from "../../components/Layout/typography";
import { FormContent } from "../../components/Layout/forms";
import { OnboardingContent } from "../../components/Layout/template";

export default function CreateRestaurant() {
  const go = useGo();
  const { restaurantId } = useParams();
  const { data: restaurantData } = useOne({
    resource: "restaurants",
    id: restaurantId,
  });
  const { formProps } = useForm({
    resource: "restaurants/menus",
    action: "create",
    id: restaurantId,
    onMutationSuccess: ({ data: { id } }) => {
      go({ to: `/restaurant/${restaurantId}/menu/${id}` });
    },
  });
  return (
    <OnboardingContent>
      <div>
        <Overline lg>{restaurantData?.data.name} added! Now..</Overline>
        <h1>Create your first menu</h1>
      </div>
      <Body md gray48>
        A menu is needed to get your restaurant rollin'.
      </Body>
      <FormContent onboarding>
        <Form {...formProps} initialValues={{ name: "" }}>
          <Input label="Menu name" name="name" />
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="large"
          >
            Create menu
          </Button>
        </Form>
      </FormContent>
    </OnboardingContent>
  );
}


import { useLayout } from '../../../../layouts/Restaurant/LayoutContext';
import { useUpdateMany, useDeleteMany } from '../../../../lib/refine';

const AllQRCodesDropdownController = ({
  children,
  selectedQRCodes,
  deleteLabel,
  dropdownLabel,
  moveLabel,
  deleteNotification,
  moveNotification,
}) => {
  const { menus } = useLayout();
  const items = [{
    key: '1',
    type: 'group',
    label: dropdownLabel,
    children: [
        {
          key: 'MOVE',
          label: moveLabel,
          children: menus.map((menu) => ({
            key: `MOVE;${menu.id}`,
            label: menu.name,
          })),
        },
        { key: 'DIV', type: 'divider' },
        { key: 'DELETE', label: deleteLabel, danger: true}
    ]
}];
const { mutate: onUpdateMany } = useUpdateMany();
const { mutate: onDeleteMany } = useDeleteMany();
const onClick = ({ key }) => {
    const keyParts = key.split(';');
    switch (keyParts[0]) {
        case 'MOVE':
            const menuId = keyParts[1];
            onUpdateMany({
                resource: 'restaurants/qr-codes',
                ids: selectedQRCodes,
                values: {
                  menu_id: menuId,
                  order: 0,
                },
                successNotification: moveNotification,
            });
            break;
        case 'DELETE':
            onDeleteMany({
                resource: 'restaurants/qr-codes',
                ids: selectedQRCodes,
                successNotification: deleteNotification,
            });
            break;
        default:
    }
};
return children({ dropdownProps: { menu: { items, onClick }, trigger: ['click'] } });
};

export default AllQRCodesDropdownController;

import { useResolvedPath, useMatch, Outlet } from 'react-router-dom';
import Modal from '../../Modal';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

const RouteModal = ({
  open = true,
  children,
}) => {
  const navigate = useNavigate();
  const onClose = () => navigate('..');
  
  return (
    <Modal open={open} footer={null} className={cx('modal')} onCancel={onClose}>
      {children}
    </Modal>
  );
};


export const SubRouteModal = ({
  path,
  maskClosable = false,
  closeIcon = false,
}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const resolvedPaths = Array.isArray(path) ? path.map(useResolvedPath) : [useResolvedPath(path)];
  const onClose = () => navigate('.');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const open = resolvedPaths.map((rp) => Boolean(useMatch(rp.pathname))).some(b => b);
  return (
    <Modal open={open} footer={null} className={cx('modal')} onCancel={onClose} width={940} maskClosable={maskClosable} closeIcon={closeIcon}>
      <Outlet />
    </Modal>
  );
};

export const createEmptySubRoutes = (routes) => {
  return routes.map((route) => {
    return {
      path: route,
      element: null,
    };
  });
}
export default RouteModal;

import { createContext, useContext } from 'react';
import { Form as AntdForm } from 'antd';
import hoistNonReactStatics from 'hoist-non-react-statics';



export const FormContext = createContext(null);

export const useFormContext = () => {
  return useContext(FormContext);
}

const Form = ({ children, ...props }) => {
  return (
    <FormContext.Provider value={props.form}>
      <AntdForm {...props}>
        {children}
      </AntdForm>
    </FormContext.Provider>
  );
};

export default hoistNonReactStatics(Form, AntdForm);
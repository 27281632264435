import { useGo } from '@refinedev/core';

const ProductEditController = ({ children, product }) => {
  const go = useGo();
  const onClick = () => {
    go({ to: `edit-product/${product.id}` });
  };
  return children({ onClick });
};

export default ProductEditController;

import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useGo } from "@refinedev/core";
import { useList } from "@refinedev/core";
import { useLocation } from "react-router-dom"; // Importing useLocation from react-router-dom
import { YumyIconKDS } from "../../components/YumyIcons";
import { Badge } from "antd";
import useSound from "use-sound";
import yumySound from "./sounds/yumy1.mp3"; // Verify path

import { colors } from "../../components/Layout/template";

const KDSLink = () => {
  const go = useGo();
  const { restaurantId } = useParams();
  const location = useLocation(); // Getting location object
  const [isOpen, setIsOpen] = useState(false); // State to control Dropdown
  const { data: orders, isLoading: ordersIsLoading } = useList({
    resource: "restaurants/session-purchases",
    id: restaurantId,
    queryOptions: {
      refetchInterval: 5000,
      refetchIntervalInBackground: true,
    },
    suspenseKey: "SESSION_PURCHASES",
    pagination: false,
    hasPagination: false,
    filters: [{ field: "today", operator: "eq", value: true }],
  }); // Fetching orders
  const numberOfNotCompletedOrders = orders?.data?.filter(
    (order) => order.state !== "COMPLETED"
  ).length || 0; // Filtering out completed orders
  // Effect to check if the last part of the URL is /kds and set isOpen accordingly
  const [play] = useSound(yumySound);
  const prevOrderCount = useRef(null);

  // Sound playing logic
  useEffect(() => {
    if (prevOrderCount.current === null && !ordersIsLoading) {
      prevOrderCount.current = numberOfNotCompletedOrders;
      return;
    }
    if (numberOfNotCompletedOrders > prevOrderCount.current) {
      play(); // Play sound if new orders have been added
      prevOrderCount.current = numberOfNotCompletedOrders; // Update the ref after checking
    }
  }, [numberOfNotCompletedOrders, play, ordersIsLoading]);
  useEffect(() => {
    const lastPathSegment = location.pathname.endsWith("/kds");
    setIsOpen(lastPathSegment);
  }, [location]); // Dependency on location to re-evaluate when it changes

  return (
    <div>
      <div
        className={`p-2 cursor-pointer m-1 bg-none border-none justify-between rounded-lg transition-colors duration-200 select-none hover:bg-green10 ${
          isOpen && "bg-green10"
        }`}
        isOpen={isOpen}
        onClick={(e) => {
          e.stopPropagation();
          go({ to: "kds" });
        }}
      >
        <div className="flex gap-2 items-center w-full">
          <YumyIconKDS />
          <div className="flex justify-between w-full items-center">
            <span>KDS</span>
            {numberOfNotCompletedOrders > 0 && (
              <Badge count={numberOfNotCompletedOrders} color={colors.yumyGreen} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KDSLink;

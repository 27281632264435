import { Button, Form } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';


const AddSectionButton = ({ onClick }) => {
  const sections = Form.useWatch(['sections']);
  return (
    <Button
      className="small"
      onClick={onClick}
      icon={<PlusCircleOutlined style={{ fontSize: '14px'}} />}
    >
      Add section {(sections || []).length + 1}
    </Button>
  );
};

export default AddSectionButton;



const Account = () => {
  return (
    <div>
      Account
    </div>
  );
};

export default Account;

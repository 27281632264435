import { useForm } from '../../../../../lib/refine';
import { useInvalidate } from '@refinedev/core';

const FormController = ({ children }) => {
  const invalidate = useInvalidate();
  const { formProps } = useForm({
      resource: 'restaurants/menus/set-categories',
      action: 'create',
      redirect: false,
      successNotification: 'Categories updated',
      onMutationSuccess: () => {
          invalidate({
              resource: 'restaurants/menus/categories',
              invalidates: ['resourceAll'],
          });
          invalidate({
              resource: 'restaurants/menus/sub-categories',
              invalidates: ['resourceAll'],
          });
          invalidate({
              resource: 'restaurants/menus/products',
              invalidates: ['resourceAll'],
          });
      },
  });
  return children({ formProps });
};
export default FormController;
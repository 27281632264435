import React from 'react';
import { type ModalProps as ModalPropsParent, Modal as ModalParent } from 'antd';

export type ModalProps = ModalPropsParent & {
  
};


const Modal : React.FC<ModalProps> = props => { 
  return (
    <ModalParent
      {...props}
    />
  );
};

export default Modal;

import { useState } from 'react';
import { useLayout } from "../../../../layouts/Restaurant/LayoutContext";
import { useQRCodes } from "./QRCodesContext";

const QRCodesSectionController = ({
  children,
}) => {
  const { menus } = useLayout();
  const { qrCodes } = useQRCodes();
  const [activeMenuId, setActiveMenuId] = useState(null);
  const activeMenuName = !activeMenuId ? 'All' : menus.find((c) => c.id === activeMenuId)?.name; 
  const menuChoices = [
    {
      key: 'All',
      name: 'All',
      qrCodes: qrCodes.length,
      isActive: !activeMenuId,
      onClick: () => setActiveMenuId(null),
    },
    ...menus.map((menu) => ({
      key: menu.id,
      name: menu.name,
      isActive: activeMenuId === menu.id,
      onClick: () => setActiveMenuId(menu.id),
      qrCodes: qrCodes.filter((p) => p.menu_id === menu.id).length,
    })),
  ];
  return children({ menuChoices, activeMenuName, activeMenuId });
};

export default QRCodesSectionController;

import { forwardRef } from "react";
import { create as originalCreate } from "antx";
import {
  InputWrapper as FormInputWrapper,
  InputWrapperRow as FormInputWrapperRow,
  Label,
} from "../Layout/forms";
import { useFormContext } from "../../lib/Form";

const create = (
  OriginalComponent,
  { defaultProps = () => ({ noStyle: true }), wrap = true } = {}
) => {
  const Component = originalCreate(OriginalComponent);
  const InputWrapper = forwardRef((props, ref) => {
    const { label, labelLeft, topRight, noWrap, noLabelHeight, ...restProps } =
      props;
    const form = useFormContext();
    const error = form?.getFieldError(props.name) || [];
    const inner = (
      <Component
        {...defaultProps(props)}
        {...(error.length > 0 ? { status: "error" } : {})}
        {...restProps}
        status="error"
        ref={ref}
      />
    );
    if (!wrap || noWrap) return inner;
    const Wrapper = labelLeft ? FormInputWrapperRow : FormInputWrapper;
    return (
      <Wrapper>
        {label && (
          <Label noLabelHeight={noLabelHeight}>
            {topRight ? <div>{label}</div> : label}
            {topRight && (
              <div className="flex justify-end items-center gap-2">
                {topRight}
              </div>
            )}
          </Label>
        )}
        {inner}
      </Wrapper>
    );
  });
  return InputWrapper;
};

export default create;

import {
  useState,
  createContext,
  useRef,
  useMemo,
  useCallback,
  useContext,
  useLayoutEffect,
} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const FallbackContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const SuspenseContext = createContext({
  onRegister: () => {},
  onUnregister: () => {},
  onDone: () => { },
});

export const useSuspense = (key, isLoading) => {
  const { onRegister, onUnregister, onDone } = useContext(SuspenseContext);
  useLayoutEffect(() => {
    if (key) {
      onRegister(key);
      return () => {
        onUnregister(key);
      };
    }
  }, [key, onRegister, onUnregister]);
  useLayoutEffect(() => {
    if (key) {
      if (!isLoading) {
        onDone(key);
      }
    }
  }, [key, isLoading, onDone]);
};

const Suspense = ({
  fallback,
  children,
}) => {
  const [, setRenderCount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const isLoadedRef = useRef(isLoaded);
  const alreadyWaitingForUseEffect = useRef(true);
  alreadyWaitingForUseEffect.current = true;
  useLayoutEffect(() => {
    isLoadedRef.current = isLoaded;
  });
  const callStates = useRef({});
  const onSetRefreshIfNessesary = useCallback(() => {
    if (!isLoadedRef.current && !alreadyWaitingForUseEffect.current) {
      alreadyWaitingForUseEffect.current = true;
      setRenderCount((prev) => prev + 1);
    }
  }, []);
  const onRegister = useCallback((key) => {
    callStates.current[key] = false;
  }, [callStates, onSetRefreshIfNessesary]);
  const onUnregister = useCallback((key) => {
    delete callStates.current[key];
    onSetRefreshIfNessesary();
  }, [callStates, onSetRefreshIfNessesary]);
  const onDone = useCallback((key) => {
    callStates.current[key] = true;
    onSetRefreshIfNessesary();
  }, [callStates, onSetRefreshIfNessesary]);
  useLayoutEffect(() => {
    alreadyWaitingForUseEffect.current = false;
    if (!isLoaded && Object.keys(callStates.current).every(k => callStates.current[k])) {
      setIsLoaded(true);
    }
  });
  const contextValue = useMemo(() => ({ onRegister, onDone, onUnregister }), [onRegister, onDone, onUnregister]);
  return (
    <SuspenseContext.Provider value={contextValue}>
      <Container>
        {!isLoaded && <FallbackContainer>{fallback}</FallbackContainer>}
        {children}
      </Container>
    </SuspenseContext.Provider>
  );
};

export default Suspense;

import { Input, CurrencyInput } from '../../../../components/Inputs';
import { Dropdown, Form } from 'antd';
import DragHandler from '../../../../components/Functionality/DragHandler';
import { ModalAdditionSectionBottom, ModalAdditionSectionMore, ModalAdditionalSection } from '../../../../components/Layout/modal';

const Option = ({
  name,
  sectionName,
  onRemove,
  draggableProps,
  innerRef,
  dragHandleProps,
}) => {
  const n = Form.useWatch(['sections', sectionName, 'options', name, 'name']);
  const dropdownItems = [{
    label: n,
    key: '1',
    type: 'group',
    children: [
      {
        key: 'DELETE',
        label: 'Delete',
        onClick: () => { onRemove(); },
        danger: true,
      }
    ],
  }];
  return (
    <ModalAdditionalSection {...draggableProps} ref={innerRef}>
      <ModalAdditionSectionBottom>
        <Input type="hidden" name={[name, 'id']} />
        <DragHandler {...dragHandleProps} />
      </ModalAdditionSectionBottom>
      <Input name={[name, 'name']} />
      <CurrencyInput name={[name, 'price']} />
      <Dropdown menu={{ items: dropdownItems }} trigger={['click']}>
        <ModalAdditionSectionBottom>
          <ModalAdditionSectionMore onClick={(e) => e.preventDefault()}>
            More
          </ModalAdditionSectionMore>
        </ModalAdditionSectionBottom>
      </Dropdown>
    </ModalAdditionalSection>
  );
};

export default Option;
import { useForm } from '../../../../../lib/refine';
import { setUser } from '../../../../../providers/useAuthProvider';

const FormController = ({ children, userId, closeModal, initialValues }) => {
  const { formProps } = useForm({
    resource: 'users',
    action: 'edit',
    id: userId,
    successNotification: 'Account has been updated',
    onMutationSuccess: (user) => {
      setUser(user.data);
      closeModal();
    },
  });
  return children({
    formProps: {
      ...formProps,
      initialValues,
    },
  });
};

export default FormController;

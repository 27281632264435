import { useMenu } from './MenuContext';

const ProductsContentController = ({
  onToggleSubCategoryOpen,
  openedSubCategories,
  onToggleSubCategory,
  getSubCategoryCheckboxState,
  onToggleProduct,
  selectedProducts,
  activeCategoryId,
  hasNoCategoryChildren,
  showsMultiple,
  children,
}) => {
  const { productsBySubCategory, subCategories: allSubCategories, products } = useMenu();
  const subCategories = allSubCategories.filter(({ id }) => Boolean(activeCategoryId) && productsBySubCategory[activeCategoryId] && (productsBySubCategory[activeCategoryId][id] || []).length > 0);
  if (!showsMultiple) {
    console.log('Shows multiple');
    return children({
      key: 'main',
      props: {
        productRow: {
          opened: true,
          onToggleProduct,
          selectedProducts,
          products: activeCategoryId ? productsBySubCategory[activeCategoryId][-1] : products,
          orderable: Boolean(activeCategoryId),
          collapsable: false,
        },
      },
    });
  }
  console.log('Shows not multiple');
  return [
    ...(hasNoCategoryChildren ? [children({
      key: '-1',
      subCategory: null,
      props: {
        categoryHeader: {
          checkbox: {
            checked: getSubCategoryCheckboxState(-1) === 'CHECKED',
            indeterminate: getSubCategoryCheckboxState(-1) === 'INDETERMINATE',
            onChange: () => onToggleSubCategory(-1),
          },
          dropdown: {
            categoryId: activeCategoryId,
            subCategoryId: -1,
            selectedProducts,
          },
          container: {
            onClick: () => onToggleSubCategoryOpen(-1),
            active: openedSubCategories.includes(-1),
          },
        },
        productRow: {
          opened: openedSubCategories.includes(-1),
          onToggleProduct,
          selectedProducts,
          products: productsBySubCategory[activeCategoryId][-1],
          collapsable: true,
          orderable: true,
        },
      },
    })] : []),
    ...subCategories.map((subCategory) => children({
        key: subCategory.id,
        subCategory,
        props: {
          categoryHeader: {
            checkbox: {
              checked: getSubCategoryCheckboxState(subCategory.id) === 'CHECKED',
              indeterminate: getSubCategoryCheckboxState(subCategory.id) === 'INDETERMINATE',
              onChange: () => onToggleSubCategory(subCategory.id),
            },
            dropdown: {
              categoryId: activeCategoryId,
              subCategoryId: subCategory.id,
              selectedProducts,
            },
            container: {
              onClick: () => onToggleSubCategoryOpen(subCategory.id),
              active: openedSubCategories.includes(subCategory.id),
            },
          },
          productRow: {
            opened: openedSubCategories.includes(subCategory.id),
            onToggleProduct,
            selectedProducts,
            products: productsBySubCategory[activeCategoryId][subCategory.id],
            collapsable: true,
            orderable: true,
          },
        },
      })
    ),
  ];
};
export default ProductsContentController;

import { IoMdAdd  } from "react-icons/io";
import { AiOutlineEdit } from "react-icons/ai";
import restaurants from './restaurants';

const resources = [
    restaurants,
    { 
      name: 'users',
      meta: {
        resourceType: 'top-level',
        hide: false,
        dbName: 'USER',
        idParamName: 'userId',
      },
      resources: [
        {
          name: 'plans',
          meta: {
            resourceType: 'collection',
            idParamName: 'planId',
          },
          resources: [
            {
              name: 'checkout-sessions',
              meta: {
                resourceType: 'collection',
                idParamName: 'checkoutSessionId',
              },
            },
            {
              name: 'portal-sessions',
              meta: {
                resourceType: 'collection',
                idParamName: 'portalSessionId',
              },
            }
          ]
        },
        {
          name: 'restaurant-users',
          meta: {
            resourceType: 'collection',
            idParamName: 'restaurantUserId',
          },
        },
        {
          name: 'password-changes',
          meta: {
            resourceType: 'collection',
            idParamName: 'passwordChangeId',
          },
        },
        {
          name: 'email-changes',
          meta: {
            resourceType: 'collection',
            idParamName: 'emailChangeId',
          },
        }
      ],
    },
    {
      name: 'countries',
      meta: {
        resourceType: 'top-level',
        hide: false,
        dbName: 'COUNTRY',
        idParamName: 'countryId',
      },
    }
];


const convertResources = () => {
  let ret = [];
  resources.forEach((resource) => {
    ret = [
      ...ret,
      ...convertResource('', null, resource), 
    ];
  });
  return ret;
};

const convertSubResources = (prefix, parent, resources) => {
  let ret = [];
  resources.forEach(r => {
    ret = [
      ...ret,
      ...convertResource(prefix, parent, r),
    ];
  });
  return ret;
};

const convertResource = (prefix, parent, resource) => {
  const {
    name,
    meta = {},
    resources,
    ...rest
  } = resource;
  const afterMeta = {
    ...meta,
  };
  if (parent) {
    afterMeta.parent = parent;
  }
  if (typeof afterMeta.hide === 'undefined') {
      afterMeta.hide = true;   // Hide all sub resources
  }
  let n = `${prefix}${name}`;
  if (meta.topLevel) {
    n = `${name}`;
  }
  const ret = [
    {
      name: n,
      meta: {
        ...afterMeta,
      },
      ...rest,
    },
    ...convertSubResources(`${n}/`, n, (resources || [])),
  ];
  return ret;
};


const refineResources = convertResources();


export const getResource = (resourceName) => {
  for (let i = 0; i < refineResources.length; i++) {
    const r = refineResources[i];
    if (r.name === resourceName) {
      return r;
    }
  }
  throw new Error(`Resource ${resourceName} not found`);
};

export const getResourceIcon = (resourceName) => {
  for (let i = 0; i < refineResources.length; i++) {
      if (refineResources[i].name === resourceName) {
          return refineResources[i].meta?.icon;
      }
  }
  return null;
}

export const getGenericIcon = (name) => {
  switch (name) {
    case 'create':
      return <IoMdAdd />;
    case 'edit':
      return <AiOutlineEdit />;
    default:
      return null;
  }
};
export default refineResources;
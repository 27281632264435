import { useLogin, useLink } from "@refinedev/core";
import { Button } from "antd";
import { Input, Checkbox, Password } from "../../components/Inputs";
import Form from "../../lib/Form";
import {
  FormContent,
  FormWrapper,
  SwitchText,
} from "../../components/Layout/forms";

export default function Login() {
  const { mutate: onLogin } = useLogin();
  const Link = useLink();
  return (
    <FormWrapper>
      <FormContent>
        <h1>Log in</h1>
        <Form
          initialValues={{ email: "", password: "", rememberMe: true }}
          onFinish={(fields) => onLogin(fields)}
        >
          <Input label="Email" name="email" />
          <Password
            size="large"
            name="password"
            label="Password"
            topRight={<Link to="/forgot">Forgot?</Link>}
          />
          <Checkbox name="rememberMe">Keep me logged in</Checkbox>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="large"
          >
            Log in
          </Button>
        </Form>
        <SwitchText>
          Need a Yumy Account? <Link to="/register">Create an account</Link>
        </SwitchText>
      </FormContent>
    </FormWrapper>
  );
}

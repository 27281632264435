import { useUpdate } from "../../lib/refine";
import { useInvalidate } from '@refinedev/core';
import { useState } from 'react';

const ProductDoneToggleController = ({
  id,
  done,
  children,
}) => {
  const { mutate: onUpdate } = useUpdate();
  const invalidate = useInvalidate();
  const [isLoading, setIsLoading] = useState(false);
  return children({
    onToggle: () => {
      if (!isLoading) {
        setIsLoading(true);
        onUpdate({
          resource: 'restaurants/qr-codes/sessions/products',
          id: id,
          values: {
            done: !done,
          },
          onMutationSuccess: () => {
            setIsLoading(false);
            invalidate({
                resource: 'restaurants/session-purchases',
                invalidates: ['resourceAll'],
            });
          },
          onMutationError: () => {
            setIsLoading(false);
          },
        });
      }
    },
    isToggling: isLoading,
  });
};

export default ProductDoneToggleController;

import { useState } from "react";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { useGetIdentity, useGo } from "@refinedev/core";
import { useCreate } from "../../lib/refine";
import { useModal } from "@refinedev/antd";
import { Avatar, Divider } from "antd";
import { colors } from "./template";
import { useLogout } from "@refinedev/core";
import UserSettings from "./user-settings";
import { useHandleClickOutside } from "../../lib/hooks";
import { YumyIconAccount, YumyIconLogout, YumyIconMyPlan } from "../YumyIcons";
import Modal from "../../lib/Modal";

// Update ButtonStyled to conditionally display
const DivStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 45px;
  left: 22.5px;
  z-index: 1;
  opacity: 0; // Start fully transparent
  visibility: hidden; // Start hidden to avoid interaction
  transform: translateY(20px); // Start slightly below the final position
  transition: opacity 0.3s, transform 0.3s, visibility 0s linear 0.3s; // Animate opacity and transform, delay hiding
  background-color: ${colors.yumyWhite};

  padding: 8px;
  border-radius: 12px;
  box-shadow: 0px 20px 50px 0px rgba(18, 17, 39, 0.25);
  width: 200px;

  ${(props) =>
    props.visible &&
    css`
      opacity: 1; // Fully visible
      visibility: visible; // Make clickable
      transform: translateY(0); // Move to final position
      transition-delay: 0s; // Apply animation immediately
    `}

  @media (max-width: 767px) {
    width: 180px;
    left: 10px;
  }
`;

const Name = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%;
  color: ${colors.grayScale900_56};

  display: flex;
  padding: 6px 8px;
  align-items: center;
  align-self: stretch;
`;

const UserButton = styled.button`
  cursor: pointer;
  border: none;
  width: 100%;

  display: flex !important;
  padding: 4px 8px !important;
  align-items: center !important;
  justify-content: flex-start !important;
  gap: 4px !important;
  border-radius: 8px !important;

  color: ${colors.yumyBlack};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;

  background: ${colors.yumyWhite};

  &:hover {
    background: ${colors.yumyLight};
    color: ${colors.yumyBlack};
  }
`;

const UserMenu = ({
  visible,
  onClose,
  name,
  onClickAccount,
  hideAccountAndPlan,
}) => {
  const { mutate: onLogout } = useLogout();
  const [isCreatingEnteringPlan, setIsEnteringPlan] = useState(false);
  const { restaurantId } = useParams();
  const { mutate } = useCreate();
  const { data: identity } = useGetIdentity();
  const go = useGo();
  return (
    <DivStyled visible={visible}>
      <Name>{name}</Name>
      {!hideAccountAndPlan && (
        <>
          {identity?.activePlan === "PREMIUM" && (
            <UserButton
              onClick={(e) => {
                const currentUrl = window.location.href;
                setIsEnteringPlan(true);
                mutate({
                  resource: "users/plans/portal-sessions",
                  id: identity?.active_plan_id,
                  values: {
                    return_url: currentUrl,
                  },
                  meta: {
                    planId: identity?.active_plan_id,
                  },
                  onMutationSuccess: (data) => {
                    window.location.href = data.data.url;
                  },
                });
                onClose();
              }}
            >
              <YumyIconMyPlan />
              My Plan
            </UserButton>
          )}
          <UserButton
            onClick={(e) => {
              // e.stopPropagation(); // Prevent click from propagating to window
              onClickAccount();
              onClose();
            }}
          >
            <YumyIconAccount />
            Account
          </UserButton>
        </>
      )}
      <Divider style={{ margin: "4px 0" }} />
      <UserButton
        onClick={(e) => {
          e.stopPropagation(); // Prevent click from propagating to window
          onLogout();
        }}
      >
        <YumyIconLogout />
        Logout
      </UserButton>
    </DivStyled>
  );
};

const UserWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding: 0 8px;
`;

const AvatarStyled = styled(Avatar)`
  background-color: ${colors.yumyWhite};
  color: ${colors.yumyBlack};
  font-size: 14px !important;
  font-weight: 500;
  vertical-align: middle;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  user-select: none;

  ${({ isOpen }) =>
    isOpen &&
    css`
      background-color: ${colors.white56};
    `}

  &:hover {
    background-color: ${colors.white56};
  }
`;

const getUserInitials = (user) => {
  if (!user) {
    return null;
  }
  const { first_name, last_name } = user;
  const firstInitial = first_name.charAt(0);
  const lastInitial = last_name.charAt(0);

  const initials = `${firstInitial}${lastInitial}`;

  return initials.toUpperCase();
};
const getUserName = (user) => {
  if (!user) {
    return null;
  }
  const { first_name, last_name } = user;
  return `${first_name} ${last_name}`;
};

const User = ({ hideAccountAndPlan }) => {
  const [showLogout, setShowLogout] = useState(false);
  const wrapperRef = useHandleClickOutside(() =>
    showLogout ? setShowLogout(false) : undefined
  );
  const { data: identity } = useGetIdentity();
  const { modalProps, show, close } = useModal();
  return (
    <UserWrap ref={wrapperRef}>
      <AvatarStyled
        size={40}
        isOpen={showLogout}
        onClick={(e) => {
          e.stopPropagation(); // Prevent click from propagating to window
          setShowLogout(!showLogout);
        }}
      >
        {getUserInitials(identity)}
      </AvatarStyled>
      <UserMenu
        visible={showLogout}
        onClickAccount={show}
        onClose={() => setShowLogout(false)}
        name={getUserName(identity)}
        hideAccountAndPlan={hideAccountAndPlan}
      />
      <Modal
        {...modalProps}
        maskClosable={false}
        closeIcon={false}
        footer={null}
        width={940}
      >
        <UserSettings closeModal={close} />
      </Modal>
    </UserWrap>
  );
};

export default User;

import { Flex } from "antd";
import { YumyIconCheckGreen, YumyIconSquareDot } from "../../components/YumyIcons";
import { YumySiderOnboardingText } from "../../components/Layout/template";

const CheckContainer = ({
  children,
  checked,
  active,
}) => {
  return (
    <Flex gap={16} justify="space-between" align="center">
      {active && (
        <Flex gap={8} justify="space-between" align="center"><YumyIconSquareDot /><YumySiderOnboardingText>{children}</YumySiderOnboardingText></Flex>
      )}
      {checked && (
        <>
          <YumySiderOnboardingText>{children}</YumySiderOnboardingText><YumyIconCheckGreen />
        </>
      )}
      {!checked && !active && <YumySiderOnboardingText>{children}</YumySiderOnboardingText>}
    </Flex>
  );
};

export default CheckContainer;